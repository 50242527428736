import { clamp } from '~/assets/ts/utils/math'

export class HSLAOptions {
  hue?: number
  saturation?: number
  lightness?: number
  alpha?: number
  /**
   * This returns an HSLA color that has compensated lightness for saturation. This allows us to keep colors high contrast even if the saturation has been reduced.
   *
   * If the saturation is 100, lightness will be 50. If the saturation is 0, lightness will be 0/100 based on whether you want it to return a darker color or lighter color.
   */
  autoContrast?: boolean
  /**
   * Only used when autoContrast is enabled. Colors in autoContrastDarkMode will get lighter, in regular mode autoContrast colors will get darker.
   */
  autoContrastDarkMode?: boolean
}

export class HSLA {
  hue: number
  saturation: number
  lightness: number
  alpha: number
  constructor(options: HSLAOptions) {
    // Either use the provided hue or select a random one
    this.hue =
      options.hue !== undefined
        ? clamp(options.hue, 0, 360)
        : Math.floor(Math.random() * 360)
    // Either use the provided saturation or default to 100
    this.saturation = clamp(options.saturation ?? 100, 0, 100)
    if (options.autoContrast) {
      const direction = options.autoContrastDarkMode ? -1 : 1
      const compensation = ((this.saturation / 100) * 50 - 50) * direction
      this.lightness = 50 + compensation
    } else {
      this.lightness = clamp(options.lightness ?? 50, 0, 100)
    }
    this.alpha = clamp(options.alpha ?? 1, 0, 1)
  }

  get css(): string {
    return `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, ${this.alpha})`
  }
}

export const saBlueHsla = new HSLA({
  hue: 210,
})

export function UserColor(id: number): string {
  const hue = id % 360
  const saturation = 100 - (id % 50)
  const lightness = 50
  const alpha = 1
  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`
}

export const SiteGraphColorA = '#0082ff'
export const SiteGraphColorB = '#d4e1ee'

function mix(start: number, end: number, percent: number): number {
  return start + percent * (end - start)
}

function mixColors(
  color1: string,
  color2: string,
  percent: number
): Record<'red' | 'green' | 'blue', number> {
  const red1 = parseInt(color1[1] + color1[2], 16)
  const green1 = parseInt(color1[3] + color1[4], 16)
  const blue1 = parseInt(color1[5] + color1[6], 16)

  const red2 = parseInt(color2[1] + color2[2], 16)
  const green2 = parseInt(color2[3] + color2[4], 16)
  const blue2 = parseInt(color2[5] + color2[6], 16)

  const red = Math.round(mix(red1, red2, percent))
  const green = Math.round(mix(green1, green2, percent))
  const blue = Math.round(mix(blue1, blue2, percent))

  return { red, green, blue }
}

export function mixColorsRGB(color1: string, color2: string, percent: number) {
  const { red, green, blue } = mixColors(color1, color2, percent)
  return `rgb(${red}, ${green}, ${blue})`
}

export function mixColorsHex(color1: string, color2: string, percent: number) {
  const { red, green, blue } = mixColors(color1, color2, percent)
  let r = red.toString(16)
  let g = green.toString(16)
  let b = blue.toString(16)

  while (r.length < 2) {
    r = '0' + r
  }
  while (g.length < 2) {
    g = '0' + g
  }
  while (b.length < 2) {
    b = '0' + b
  }

  return `#${red}${green}${blue}`
}
