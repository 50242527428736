
import Vue from 'vue'
import SiteButton, { BaseButtonProps } from '~/components/site/Button.vue'
import Spinner from '~/components/_general/Spinner.vue'

export default Vue.extend({
  name: 'SiteButtonSpinner',
  components: { Spinner, SiteButton },
  props: {
    ...BaseButtonProps,
    waiting: {
      type: Boolean,
      default: false,
    },
  },
})
