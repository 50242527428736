import { Context } from '@nuxt/types'
import { Store } from 'vuex'
import { DashboardState } from '~/store/dashboard'
import { Broadcaster } from '~/models/broadcaster'
import {
  BroadcasterApi,
  BroadcasterPrivateDataApi,
} from '~/apiclient/apibroadcasters'
import { PromiseAll } from '~/assets/ts/utils/misc'

export class Dashboard {
  $store: Store<DashboardState>
  $context: Context

  constructor(context: Context) {
    this.$context = context
    this.$store = context.store as Store<DashboardState>
  }

  get broadcasterApi(): BroadcasterApi {
    return this.$store.getters['dashboard/broadcaster']
  }

  get broadcasterPrivateData(): BroadcasterPrivateDataApi | undefined {
    return this.$store.getters['dashboard/broadcasterPrivateData']
  }

  get broadcaster(): Broadcaster {
    return new Broadcaster(this.broadcasterApi)
  }

  get broadcasterID(): string {
    return this.broadcasterApi.broadcasterID
  }

  async updateBroadcaster(includePrivateData = false) {
    const params = { broadcasterID: this.broadcasterID }
    const fetchBroadcaster = this.$store.dispatch(
      'dashboard/fetchBroadcaster',
      params
    )
    const promises = [fetchBroadcaster]
    if (includePrivateData) {
      promises.push(
        this.$store.dispatch('dashboard/fetchBroadcasterPrivateData', params)
      )
    }
    await PromiseAll(promises)
  }

  async formSaved(
    updateBroadcaster = true,
    updatePrivateData = true,
    autosaved = false
  ) {
    await this.$store.dispatch('site/formSaved')
    if (autosaved) {
      await this.$store.dispatch('site/autoSaved')
    }
    if (!updateBroadcaster) return
    await this.updateBroadcaster(updatePrivateData)
  }
}
