import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'

export enum RadioStreamID {
  SA = 1,
  VCY = 2,
}

export interface RadioStreamApi {
  broadcaster?: Record<string, any>
  defaultChurch?: string
  defaultSpeakerName?: string
  defaultTitle?: string
  displayName: string
  /** Resizable Image URL */
  imageURL?: string
  radioStreamID: RadioStreamID
  shortDisplayName: string
  streamURL: string
}

export async function getRadioStreams(
  this: ApiClient
): Promise<ApiPaginationResult<RadioStreamApi>> {
  const { data } = await this.$axios.get('node/discover/radio/streams')
  return data
}

export interface RadioStreamHistoryOptions {
  sermonsOnly?: boolean
  page?: number
  pageSize?: number
}

export interface RadioStreamHistoryApi {
  broadcaster?: Record<string, any>
  playCategory: string
  radioPlayID: number
  sermon?: Record<string, any>
  speakerName: string
  timestamp: number
  title: string
}

export async function getRadioStreamHistory(
  this: ApiClient,
  params: RadioStreamHistoryOptions
): Promise<ApiPaginationResult<RadioStreamHistoryApi>> {
  const { data } = await this.$axios.get('node/discover/radio/history', {
    params,
  })
  return data
}
