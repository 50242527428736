
import Vue, { PropType } from 'vue'
import { BarColors } from '~/components/player/Waveform.vue'
import { clamp } from '~/assets/ts/utils/math'

export default Vue.extend({
  name: 'PlayerSimpleWaveformBar',
  props: {
    barColors: {
      type: Object as PropType<BarColors>,
      required: true,
    },
    scrub: {
      type: Number,
      required: true,
    },
    percent: {
      type: Number,
      required: true,
    },
  },
  computed: {
    scrubPercent(): number {
      return clamp((this.scrub / this.percent) * 100, 0, 100)
    },
    waveStyle(): string {
      return `background: ${this.barColors.wave};width: ${this.percent}%`
    },
    scrubStyle(): string {
      return `background: ${this.barColors.scrub};width: ${this.scrubPercent}%`
    },
  },
})
