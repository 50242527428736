import { TranslateResult } from 'vue-i18n'

export interface AlertButtonParams {
  text: TranslateResult
  order?: number
  event?:
    | ((event: Event) => boolean | void)
    | ((event: Event) => Promise<void> | Promise<boolean>)
}
export class AlertButton {
  text: string
  event:
    | ((event: Event) => boolean | void)
    | ((event: Event) => Promise<void> | Promise<boolean>)
    | undefined

  order?: number
  constructor(button: AlertButtonParams) {
    this.text = button.text.toString()
    this.event = button.event
    this.order = button.order
  }

  async click(e: Event): Promise<boolean> {
    if (this.event) {
      const close = await this.event(e)
      if (close === false) {
        return false
      }
    }
    return true
  }
}
