
import Vue from 'vue'
import Spinner from '~/components/_general/Spinner.vue'

export default Vue.extend({
  name: 'SiteLoadingIndicator',
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
