import { Context } from '@nuxt/types'
import { LanguageApi } from '~/apiclient/apifilters'

export default async function ({ params, store, error }: Context) {
  const languageCode = params.language
  let valid = true
  if (languageCode) {
    await store.dispatch('filters/getLanguages')
    const language = store.getters['filters/languages'].find(
      (lang: LanguageApi) => lang.languageCode === languageCode
    )
    valid = !!language
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: 'Language not found',
    })
  }
}
