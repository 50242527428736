
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { getName } from 'country-list'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import { getStateName } from '~/assets/ts/utils/states.js'
import NavigationTab from '~/components/_general/NavigationTab.vue'

export interface LocTotal {
  location: TranslateResult
  total: number
}

export default Vue.extend({
  name: 'SitePromotionalLocationList',
  components: { NavigationTab, HorizontalRule },
  props: {
    data: {
      type: Object as PropType<Record<string, Record<string, number>>>,
      required: true,
    },
    totalImpressions: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isUS: true,
    }
  },
  computed: {
    total(): LocTotal | undefined {
      if (!this.data) return undefined

      if (this.isUS) {
        return {
          location: this.$t('United States (total)'),
          total: this.data.US.total,
        }
      } else {
        return {
          location: this.$t('International (total)'),
          total: this.totalImpressions,
        }
      }
    },
    locs(): LocTotal[] {
      if (!this.data) return []

      let keys = [] as string[]
      let totals = [] as any[]

      if (this.isUS) {
        keys = Object.keys(this.data.US)
        totals = Object.values(this.data.US)
      } else {
        keys = Object.keys(this.data)
        for (let i = 0; i < keys.length; i++) {
          totals.push(this.data[keys[i]].total)
        }
      }

      const locs = [] as LocTotal[]
      let location
      for (let i = 0; i < keys.length - 1; i++) {
        if (this.isUS) {
          location = getStateName(keys[i]) ?? this.$t('USA (General)')
        } else {
          location = getName(keys[i]) ?? this.$t('International (General)')
        }

        locs.push({
          location,
          total: totals[i],
        } as LocTotal)
      }

      return locs.sort((a, b) => b.total - a.total)
    },
  },
})
