import {
  ExpiredAuthSessionError,
  RefreshScheme,
} from '@nuxtjs/auth-next/dist/runtime'
import { AxiosError } from 'axios'
import { HTTPResponse, SchemeCheck } from '@nuxtjs/auth-next'
import { Context } from '@nuxt/types'
import { wait } from '~/assets/ts/utils/misc'

function cleanObj(obj: Record<any, any>) {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

function getProp(holder: any, propName: any) {
  if (!propName || !holder || typeof holder !== 'object') {
    return holder
  }
  if (propName in holder) {
    return holder[propName]
  }
  const propParts = Array.isArray(propName)
    ? propName
    : (propName + '').split('.')
  let result = holder
  while (propParts.length && result) {
    result = result[propParts.shift()]
  }
  return result
}

// https://github.com/nuxt-community/auth-module/blob/dev/src/schemes/refresh.ts
export default class CustomRefresh extends RefreshScheme {
  public refreshRequest: Promise<HTTPResponse> | null = null
  get Context(): Context {
    return this.$auth.ctx
  }

  check(checkStatus = false): SchemeCheck {
    const response = {
      valid: false,
      tokenExpired: false,
      refreshTokenExpired: false,
      isRefreshable: true,
    }

    // Sync tokens
    const token = this.token.sync()

    // we don't want this updated, but rather just want the value as is
    let refreshToken = this.refreshToken.get()
    if (!refreshToken) {
      refreshToken = this.refreshToken.sync()
    }

    // Token and refresh token are required but not available. Force reset
    if (!token || !refreshToken) {
      if (this.$auth.loggedIn) {
        console.log('Missing token or refresh token; forcing reset.')
      }
      this.$auth.reset()
      return response
    }

    // Check status wasn't enabled, let it pass
    if (!checkStatus) {
      response.valid = true
      return response
    }

    // Get status
    const tokenStatus = this.token.status()
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token has expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      response.refreshTokenExpired = true
      return response
    }

    // Token has expired, Force reset.
    if (tokenStatus.expired()) {
      response.tokenExpired = true
      return response
    }

    response.valid = true
    return response
  }

  refreshTokens(): Promise<HTTPResponse | void> {
    // Refresh endpoint is disabled
    if (!this.options.endpoints.refresh) {
      console.error('Refresh endpoint is disabled')
      return Promise.resolve()
    }
    // Token and refresh token are required but not available
    if (!this.check().valid) {
      console.error('Token and refresh token are required but not available')
      return Promise.resolve()
    }

    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      console.warn(
        'Refresh token is expired. There is no way to refresh. Force reset.'
      )
      this.$auth.reset()
      throw new ExpiredAuthSessionError()
    }

    // Delete current token from the request header before refreshing, if `tokenRequired` is disabled
    if (!this.options.refreshToken.tokenRequired) {
      this.requestHandler.clearHeader()
    }
    const endpoint = {
      data: {
        client_id: undefined,
        grant_type: undefined,
      },
    } as Record<any, any>

    // Add refresh token to payload if required
    if (this.options.refreshToken.required && this.options.refreshToken.data) {
      endpoint.data[this.options.refreshToken.data] = this.refreshToken.get()
    }

    // Add client id to payload if defined
    if (this.options.clientId) {
      endpoint.data.client_id = this.options.clientId
    }

    // Add grant type to payload if defined
    if (this.options.grantType) {
      endpoint.data.grant_type = 'refresh_token'
    }

    cleanObj(endpoint.data)

    return this.requestRefreshWithRetries(endpoint)
  }

  async requestRefreshWithRetries(
    endpoint: Record<any, any>,
    timestamp?: number
  ): Promise<HTTPResponse | void> {
    // When calling refreshTokens() multiple times (parallel axios request)
    // Use the same promise as the first refresh request
    // or making a refresh request if it does not exist
    // instead of making parallel refresh request
    if (!this.refreshRequest) {
      this.refreshRequest = this.$auth.request(
        endpoint,
        this.options.endpoints.refresh
      )
    }

    try {
      const response = await this.refreshRequest
      this.refreshRequest = null
      this.updateTokens(response, { isRefreshing: true })
      return response
    } catch (e: any) {
      const error = e as AxiosError
      this.refreshRequest = null
      console.error('REFRESH ERROR', error)

      // https://axios-http.com/docs/handling_errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status >= 500) {
          // the refresh request failed due to an unexpected network or server error.
          // pretend that it succeeded to avoid undesired logout; hopefully the next refresh will succeed.
          return Promise.resolve()
        } else {
          console.warn('Refresh failed. Force reset.')
          this.$auth.reset()
          this.$auth.callOnError(error, { method: 'refreshToken' })
          return Promise.reject(error)
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        const currentTimestamp = new Date().getTime()
        if (!timestamp) timestamp = currentTimestamp
        const elapsed = currentTimestamp - timestamp
        const retrySeconds = this.Context.$isClient ? 31 : 16
        if (elapsed <= retrySeconds * 1000) {
          await wait(500)
          return await this.requestRefreshWithRetries(endpoint, timestamp)
        } else {
          console.error('REFRESH RETRIES EXPIRED.')
          return Promise.resolve()
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.resolve()
      }
    }
  }

  setUserToken(
    token: string | boolean,
    refreshToken?: string | boolean
  ): Promise<HTTPResponse | void> {
    this.token.set(token)

    if (refreshToken) {
      this.refreshToken.set(refreshToken)
    }

    // Fetch user
    return this.fetchUser()
  }

  reset({ resetInterceptor = true } = {}): void {
    this.$auth.setUser(false)
    this.token.reset()
    this.refreshToken.reset()

    if (resetInterceptor) {
      this.requestHandler.reset()
    }
  }

  protected updateTokens(
    response: HTTPResponse,
    { isRefreshing = false, updateOnRefresh = true } = {}
  ): void {
    const token = this.options.token.required
      ? (getProp(response.data, this.options.token.property) as string)
      : true
    const refreshToken = this.options.refreshToken.required
      ? (getProp(response.data, this.options.refreshToken.property) as string)
      : true

    this.token.set(token)

    // Update refresh token if defined and if `isRefreshing` is `false`
    // If `isRefreshing` is `true`, then only update if `updateOnRefresh` is also `true`
    if (refreshToken && (!isRefreshing || (isRefreshing && updateOnRefresh))) {
      this.refreshToken.set(refreshToken)
    }
  }
}
