import { ActionTree, GetterTree, MutationTree } from 'vuex'
import axios from 'axios'
import { logError } from '~/assets/ts/utils/misc'
import {
  AdConfigParams,
  AdGlobalConfigApi,
  AdStatsAggregateApi,
  AdStatsOptions,
  AdType,
  CouponCountsApi,
  UserAdApi,
} from '~/apiclient/apiads'

export interface ValidSermonObject {
  valid: boolean
  error: boolean
}

export const state = () => ({
  currentAd: undefined as UserAdApi | undefined,
  aggregateStats: undefined as AdStatsAggregateApi | undefined,
  adConfig: undefined as AdGlobalConfigApi | undefined,
  sermonAdConfig: undefined as AdGlobalConfigApi | undefined,
  broadcasterCoupons: undefined as CouponCountsApi | undefined,
  checkout: false,
})

export type PromoState = ReturnType<typeof state>

export const mutations: MutationTree<PromoState> = {
  SET_CURRENT_AD: (state, editAd: UserAdApi | undefined) => {
    state.currentAd = editAd
  },
  SET_AGGREGATE_STATS: (state, stats: AdStatsAggregateApi) => {
    state.aggregateStats = stats
  },
  CLEAR_AD_CONFIGS: (state) => {
    state.adConfig = undefined
    state.sermonAdConfig = undefined
  },
  UPDATE_AD_CONFIG: (state, config: AdGlobalConfigApi | undefined) => {
    state.adConfig = config
  },
  UPDATE_SERMON_AD_CONFIG: (state, config: AdGlobalConfigApi | undefined) => {
    state.sermonAdConfig = config
  },
  SET_BROADCASTER_COUPONS: (state, coupons: CouponCountsApi | undefined) => {
    state.broadcasterCoupons = coupons
  },
  SET_CHECKOUT: (state, checkout: boolean) => {
    state.checkout = checkout
  },
}

export const getters: GetterTree<PromoState, PromoState> = {
  currentAd: (state) => state.currentAd,
  checkout: (state) => state.checkout,
  aggregateStats: (state) => state.aggregateStats,
  adConfig:
    (state) =>
    (sermonID: string | undefined = undefined) => {
      return sermonID ? state.sermonAdConfig : state.adConfig
    },
  broadcasterCoupons: (state) => (adType: AdType, sermonID: boolean) => {
    if (!sermonID) return 0
    switch (adType) {
      case AdType.FeaturedSermon:
        return state.broadcasterCoupons?.sermon ?? 0
      case AdType.Text:
        return state.broadcasterCoupons?.text ?? 0
      case AdType.Graphic:
      default:
        return 0
    }
  },
}

export const actions: ActionTree<PromoState, PromoState> = {
  async fetchAd({ commit }, adID: string) {
    try {
      const data = await this.$apiClient.getAd(adID)
      commit('SET_CURRENT_AD', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchCoupons({ commit }, broadcasterID: string | undefined) {
    if (!broadcasterID) {
      commit('SET_BROADCASTER_COUPONS', undefined)
      return
    }
    try {
      const data = await this.$apiClient.getCouponsAvailable(broadcasterID)
      commit('SET_BROADCASTER_COUPONS', data)
    } catch (e) {
      logError(e)
      commit('SET_BROADCASTER_COUPONS', undefined)
    }
  },
  async fetchAdStatsAggregate({ commit }, options: AdStatsOptions) {
    try {
      const data = await this.$apiClient.getAdStatsAggregate(options)
      commit('SET_AGGREGATE_STATS', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchAdConfig(
    { commit, state },
    params: AdConfigParams = {}
  ): Promise<ValidSermonObject> {
    try {
      if (!params.sermonID && state.adConfig) {
        return { valid: true, error: false }
      }
      const data = await this.$apiClient.getAdConfig(params)
      commit(
        params.sermonID ? 'UPDATE_SERMON_AD_CONFIG' : 'UPDATE_AD_CONFIG',
        data
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.code === '409' || e.response?.status === 409) {
          return { valid: false, error: false }
        } else {
          return { valid: false, error: true }
        }
      } else {
        logError(e)
      }
    }

    return { valid: true, error: false }
  },
}
