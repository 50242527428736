
import Vue from 'vue'

export default Vue.extend({
  name: 'Spinner',
  props: {
    waiting: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 4,
    },
    matchEm: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    sizeStyles(): Record<string, string> {
      const size = this.matchEm ? '1em' : `${this.size * 0.25}rem`
      return {
        width: size,
        height: size,
        'border-width': `${this.border}px`,
      }
    },
  },
})
