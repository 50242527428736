import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'
import { BroadcasterApi, BroadcasterInclude } from '~/apiclient/apibroadcasters'

export const state = () => ({
  promoBroadcasterID: undefined as string | undefined,
  broadcasters: {} as Record<string, BroadcasterApi>, // broadcasterID: BroadcasterApi
  primarySpeakers: {} as any, // broadcasterID: speaker
})

export type BroadcastersState = ReturnType<typeof state>

export const mutations: MutationTree<BroadcastersState> = {
  SET_PROMO_BROADCASTER: (state, broadcasterID: string | undefined) => {
    state.promoBroadcasterID = broadcasterID
  },
  ADD_BROADCASTER: (state, broadcaster: BroadcasterApi) => {
    Vue.set(state.broadcasters, broadcaster.broadcasterID, broadcaster)
  },
  ADD_BROADCASTERS: (state, broadcasters: BroadcasterApi[]) => {
    broadcasters.forEach((broadcaster) => {
      Vue.set(state.broadcasters, broadcaster.broadcasterID, broadcaster)
    })
  },
  ADD_PRIMARY_SPEAKER: (state, { broadcasterID, speaker }) => {
    Vue.set(state.primarySpeakers, broadcasterID, speaker)
  },
}

export const getters: GetterTree<BroadcastersState, BroadcastersState> = {
  broadcaster: (state) => (broadcasterID: string) =>
    state.broadcasters[broadcasterID],
  promoBroadcaster: (state) => {
    return state.broadcasters[state.promoBroadcasterID ?? '']
  },
  primarySpeaker: (state) => (broadcasterID: string) =>
    state.primarySpeakers[broadcasterID],
}

export const actions: ActionTree<BroadcastersState, BroadcastersState> = {
  async fetchBroadcaster({ commit, getters }, { broadcasterID, lite = true }) {
    if (getters.broadcaster(broadcasterID)) return
    try {
      const data = await this.$apiClient.getBroadcaster(broadcasterID, {
        lite,
        include: BroadcasterInclude.All,
      })
      commit('ADD_BROADCASTER', data)
    } catch (e) {
      logError(e)
    }
  },
}
