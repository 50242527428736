
import Vue, { PropType } from 'vue'
import { AdType, DisplayAdApi } from '~/apiclient/apiads'
import SiteTextFeatureElement from '~/components/site/TextFeatureElement.vue'
import SermonElement from '~/components/_general/SermonElement.vue'
import { SermonDisplay, SermonMetadataExtendedInfo } from '~/assets/ts/enums'
import { GetAdType } from '~/assets/ts/utils/promotions'
import { SermonApi } from '~/apiclient/apisermons'
import { pojoToSermon, Sermon } from '~/models/sermon'

export default Vue.extend({
  name: 'SitePromotionalAdListPreview',
  components: { SermonElement, SiteTextFeatureElement },
  props: {
    ad: {
      type: Object as PropType<DisplayAdApi>,
      default: undefined,
    },
    placeholderAdType: {
      type: String as PropType<AdType>,
      default: AdType.Text,
    },
  },
  computed: {
    SermonDisplay() {
      return SermonDisplay
    },
    SermonMetadataExtendedInfo() {
      return SermonMetadataExtendedInfo
    },
  },
  methods: {
    adType(ad: DisplayAdApi | undefined): AdType {
      if (!ad) return this.placeholderAdType
      return GetAdType(ad)
    },
    isFeaturedSermon(ad: DisplayAdApi | undefined) {
      return this.adType(ad) === AdType.FeaturedSermon
    },
    makeSermon(sermon: SermonApi | undefined): Sermon | undefined {
      return pojoToSermon(sermon)
    },
  },
})
