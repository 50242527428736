import {
  ApiClient,
  ApiPaginationParams,
  ApiPaginationResult,
} from '~/apiclient/apiclient'
import { DateString } from '~/assets/ts/types/date-string'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { jsDateToApiDateStr } from '~/assets/ts/utils/date'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'
import { UserFeedSources } from '~/apiclient/apiusers'

export const SpecialArticleBroadcasterID = 'misc'
export const NewsCategoryID = 4445407
export const ReleaseNotesCategoryID = 4445869

export interface DisplayArticleApi {
  articleID: number
  slug: string
  articleDate: DateString
  title: string
  preview: string | null
  bannerURL?: string | null
  categoryID?: number | null
  categoryDescription?: string | null
  broadcaster: BroadcasterApi
  speaker?: SpeakerApi | null
  pinned: boolean
  draft: boolean
  feedItemSource?: UserFeedSources[]
}

export enum ArticleCategoryTypeApi {
  Article = 'article',
  Blog = 'blog',
  News = 'news',
}

export enum ArticleSortOrderApi {
  Title = 'title',
  Newest = 'newest',
  Oldest = 'oldest',
}

export const DefaultArticleSort = ArticleSortOrderApi.Newest

export interface ArticleCategoryApi {
  categoryID: number
  articleCount: number
  broadcasterID: string
  description: string
  categoryType: ArticleCategoryTypeApi
}

export interface ArticleApi extends DisplayArticleApi {
  content: string
  // TODO: put this back whenever we decide to re-enable the feature
  // previousArticle: DisplayArticleApi | null
  // nextArticle: DisplayArticleApi | null
}

export interface ArticlesConfig {
  maxTitleLength: number
  maxContentLength: number
  maxSlugLength: number
}

export interface ArticleFilterParams extends ApiPaginationParams {
  broadcasterID?: string
  speakerID?: number
  categoryID?: number
  dateBegin?: DateString
  dateEnd?: DateString
  sortBy?: ArticleSortOrderApi
  pinnedOnly?: boolean
  includeDrafts?: boolean
  search?: string
}

export enum ArticleCategoriesSortApi {
  Description = 'description',
  Count = 'count',
  Updated = 'updated',
}

export interface ArticleCategoriesParams {
  broadcasterID?: string
  speakerID?: number
  sortBy?: ArticleCategoriesSortApi
  includeDrafts?: boolean
}

export interface ArticleCreateParams {
  broadcasterID: string
  title: string
  articleDate: DateString
  content: string
  pinned?: boolean
  draft?: boolean
  category?: string
  speakerID?: number | null
}

export interface ArticleEditParams {
  articleID: number
  title?: string
  content?: string
  articleDate?: DateString
  speakerID?: number | null
  pinned?: boolean
  draft?: boolean
  slug?: string
  category?: string | null
}

export async function getArticlesConfig(
  this: ApiClient
): Promise<ArticlesConfig> {
  const { data } = await this.$axios.get('node/articles/config')
  return data
}

export async function getArticles(
  this: ApiClient,
  params: ArticleFilterParams
): Promise<ApiPaginationResult<DisplayArticleApi>> {
  const { data } = await this.$axios.get('node/articles', {
    params,
  })
  return data
}

export interface GetArticleOptions {
  broadcasterID?: string
  slug?: string
  articleID?: number
}

export async function getArticle(
  this: ApiClient,
  options: GetArticleOptions
): Promise<ArticleApi> {
  let url = ''
  if (options.articleID) {
    url = options.articleID.toString()
  } else if (options.broadcasterID && options.slug) {
    url = `${options.broadcasterID}/${options.slug}`
  } else {
    console.error('The following article configuration is not valid', options)
  }
  const { data } = await this.$axios.get(`node/articles/${url}`)
  return data
}

export async function getArticleCategory(
  this: ApiClient,
  categoryID: number
): Promise<ArticleCategoryApi> {
  const { data } = await this.$axios.get(
    `node/articles/categories/${categoryID}`
  )
  return data
}

export async function getArticleCategories(
  this: ApiClient,
  params: ArticleCategoriesParams
): Promise<ApiPaginationResult<ArticleCategoryApi>> {
  const { data } = await this.$axios.get(`node/articles/categories`, {
    params,
  })
  return data
}

export const PlaceholderArticleTitle = 'Untitled Article'

export async function createPlaceholderArticle(
  this: ApiClient,
  broadcasterID: string
): Promise<ArticleApi> {
  const { data } = await this.$axios.post('node/articles', {
    broadcasterID,
    title: PlaceholderArticleTitle,
    content: '',
    articleDate: jsDateToApiDateStr(new Date()),
    draft: true,
  } as ArticleCreateParams)
  return data
}

export async function createArticle(
  this: ApiClient,
  params: ArticleCreateParams
): Promise<ArticleApi> {
  const { data } = await this.$axios.post('node/articles', params)
  return data
}

export async function editArticle(
  this: ApiClient,
  params: ArticleEditParams
): Promise<ArticleApi> {
  const { data } = await this.$axios.patch(
    `node/articles/${params.articleID}`,
    params
  )
  return data
}

export async function deleteArticle(
  this: ApiClient,
  articleID: number
): Promise<boolean> {
  const { data } = await this.$axios.delete(`node/articles/${articleID}`)
  return data
}

export async function getArticleBannerUploadUrl(
  this: ApiClient,
  articleID: number
): Promise<string> {
  const upload = await this.$axios.post(
    `/media/images/articles/${articleID}/banner`
  )
  return upload.data.uploadURL
}

export async function deleteArticleBanner(
  this: ApiClient,
  articleID: number
): Promise<void> {
  return await this.$axios.delete(`/media/images/articles/${articleID}/banner`)
}
