
import Vue, { PropType } from 'vue'
import {
  AdTrackerUrl,
  createSiteSermonUrl,
  siteBroadcasterUrl,
  siteSermonUrl,
  siteSpeakerUrl,
} from '~/assets/ts/utils/urls'
import { displayUrl, sendAdClick } from '~/assets/ts/utils/misc'
import SaLink from '~/components/_general/SaLink.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import BackgroundImage from '~/components/_general/BackgroundImage.vue'
import SiteAdTrackerImage from '~/components/site/AdTrackerImage.vue'
import SiteTextFeatureBorder from '~/components/site/TextFeatureBorder.vue'
import { AdType, DisplayAdApi } from '~/apiclient/apiads'
import { SermonApi } from '~/apiclient/apisermons'
import Spinner from '~/components/_general/Spinner.vue'
import { GetAdType } from '~/assets/ts/utils/promotions'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import Poller from '~/components/_general/Poller.vue'

export default Vue.extend({
  name: 'SiteTextFeatureElement',
  components: {
    Poller,
    LoadingElement,
    Spinner,
    SiteTextFeatureBorder,
    SiteAdTrackerImage,
    BackgroundImage,
    SaIcon,
    SaLink,
  },
  props: {
    ad: {
      type: Object as PropType<DisplayAdApi>,
      default: undefined,
    },
    trackStats: {
      type: Boolean,
      default: true,
    },
    managerListing: {
      type: Boolean,
    },
    placeholderAdType: {
      type: String as PropType<AdType | undefined>,
      default: undefined,
    },
    showImageSpinner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showTrackerImage: false,
      imageProcessing: false,
      polledImageUrl: '',
    }
  },
  computed: {
    SaLink() {
      return SaLink
    },
    url(): string {
      return this.ad?.url ?? ''
    },
    description(): string {
      if (this.sermon) return ''
      return this.ad?.description ?? ''
    },
    title(): string {
      return this.ad?.title ?? ''
    },
    domainUrl(): string {
      const url = this.url
      const display = url ? displayUrl(url, true) : ''
      return display || url
    },
    speakerUrl(): string | undefined {
      return siteSpeakerUrl(this.sermon?.speaker.speakerID)
    },
    sermon(): SermonApi | undefined {
      return this.ad?.sermon ?? undefined
    },
    broadcasterUrl(): string {
      if (!this.sermon) return ''
      return siteBroadcasterUrl(this.sermon.broadcaster.broadcasterID)
    },
    sermonUrl(): string {
      if (!this.sermon || !this.sermon.sermonID) return siteSermonUrl(undefined)
      return createSiteSermonUrl(this.sermon.sermonID)
    },
    adType(): AdType {
      if (this.ad) {
        return GetAdType(this.ad)
      }
      return this.placeholderAdType ?? AdType.Text
    },
    textAd(): boolean {
      return this.adType === AdType.Text
    },
    sponsored(): boolean {
      return this.adType === AdType.Graphic
    },
    imageUrl(): string {
      if (this.polledImageUrl) return this.polledImageUrl
      const images = this.ad?.images
      if (!images || !images.length) return ''
      return images[0].imageURLWebp
    },
  },
  watch: {
    ad() {
      if (this.ad) {
        this.checkImage()
      }
    },
  },
  mounted() {
    this.checkImage()
    if (!this.trackStats || this.managerListing) return
    this.showTrackerImage = true
  },
  methods: {
    async checkImage() {
      if (!this.ad || !this.sponsored || !this.showImageSpinner) return
      await this.fetchImage()
      this.imageProcessing = await this.image404()
    },
    async fetchImage() {
      if (this.imageUrl || !this.ad?.campaignID) return
      const ad = await this.$apiClient.getAd(this.ad?.campaignID)
      this.polledImageUrl = ad.images?.length ? ad.images[0].imageURLWebp : ''
    },
    async image404() {
      if (!this.imageUrl) return true
      try {
        await fetch(this.imageUrl)
        return false
      } catch (_e) {
        return true
      }
    },
    adClicked() {
      const id = this.ad?.campaignID
      if (!id) return
      sendAdClick(AdTrackerUrl(id, false))
    },
    adRightClicked() {
      this.adClicked()
    },
  },
})
