
import Vue, { PropType } from 'vue'
import { User } from '~/models/users/user'
import { DisplayUser } from '~/models/usercomment'
import { UserColor } from '~/assets/ts/utils/color'
import SaImage from '~/components/_general/SaImage.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export type FontSize =
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-xl'
  | 'text-2xl'
  | 'text-3xl'

export default Vue.extend({
  name: 'SiteUserProfileIcon',
  components: { SaIcon, SaImage },
  props: {
    size: {
      type: Number,
      default: 72,
    },
    user: {
      type: Object as PropType<User | DisplayUser>,
      default: undefined,
    },
    forceDark: {
      type: Boolean,
    },
    widthClass: {
      type: String,
      default: 'w-full',
    },
    fontSize: {
      type: String as PropType<FontSize>,
      default: 'text-sm',
    },
  },
  computed: {
    userColor(): string {
      if (!this.user) return ''
      return UserColor(this.user.id)
    },
    userLetter(): string {
      if (!this.user || !this.user.DisplayName) return ''
      return this.user.DisplayName.charAt(0)
    },
    profilePicture(): string | undefined {
      if (!this.user) return undefined
      return this.user.ProfilePicture(this.size)
    },
  },
})
