import { Context } from '@nuxt/types'

export default async function ({ params, error, store }: Context) {
  const slug = params.collectionSlug
  await store.dispatch('collections/fetchCollection', slug)
  const collection = store.getters['collections/collection'](slug)
  if (collection) {
    return
  }

  console.log('no collection with slug ' + slug + ' found')
  error({
    statusCode: 404,
    message: `Collection '${slug}' not found`,
  })
}
