import { ApiClient } from '~/apiclient/apiclient'

export interface VideoWallPlayApi {
  broadcasterID: string
  deviceType: string
  mediaType: string
  playTimestamp: number
  playType: string
  sermonID: string
  speakerName: string
}

export interface VideoWallLocationApi {
  googleMapsLink: string
  latlong: number[]
  playCountry: string
  playRegion: string
  plays: VideoWallPlayApi[]
}

export interface VideoWallWebcastLocationApi {
  broadcasterID: string
  locations: number[][]
}

export interface VideoWallLocationsApi {
  locations: VideoWallLocationApi[]
  totalPlayCount: number
}

export interface VideoWallWebcastLocationsApi {
  webcastsInProgressPlays: Record<string, VideoWallWebcastLocationApi>
}

export interface VideoWallApiParams {
  broadcasterIDs?: string
  group?: string
  latitude?: number
  longitude?: number
  radiusInMiles?: number
  countryLimit?: number
  startTimestamp?: number
}

export async function getVideoWallWebcastPlays(
  this: ApiClient,
  params: VideoWallApiParams = {}
): Promise<VideoWallWebcastLocationsApi> {
  const { data } = await this.$axios.get(`node/vault/maps/plays/webcasts`, {
    params,
  })
  return data
}

export interface VideoWallDoNotDisplaySermonApi {
  badBroadcaster: boolean
  badKeywordPattern: boolean
  badPattern: boolean
  badSermon: boolean
  badSpeaker: boolean
  doNotDisplay: boolean
}

export async function doNotDisplaySermon(
  this: ApiClient,
  sermonID: string,
  params: VideoWallApiParams = {}
): Promise<VideoWallDoNotDisplaySermonApi> {
  const { data } = await this.$axios.get(
    `node/vault/maps/sermon_display/${sermonID}`,
    {
      params,
    }
  )
  return data
}

export async function videoWallSermonPlays(
  this: ApiClient,
  params: VideoWallApiParams = {}
): Promise<VideoWallLocationsApi> {
  const { data } = await this.$axios.get(`node/vault/maps/plays/sermons`, {
    params,
  })
  return data
}
