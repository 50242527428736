import { Context } from '@nuxt/types'
import { redirectDisabledBroadcaster } from '~/assets/ts/utils/urls'

export default async function ({
  params,
  store,
  error,
  redirect,
  route,
}: Context) {
  const broadcasterID = params.broadcaster || params.broadcasterID
  let valid = !!broadcasterID
  if (broadcasterID) {
    await store.dispatch('broadcasters/fetchBroadcaster', {
      broadcasterID,
      lite: false,
    })
    const broadcaster = store.getters['broadcasters/broadcaster'](broadcasterID)
    valid = !!broadcaster
    if (valid) {
      redirectDisabledBroadcaster(redirect, route, broadcaster)
    }
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: 'Broadcaster not found',
    })
  }
}
