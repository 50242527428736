
import Vue, { PropType } from 'vue'
import { User } from '~/models/users/user'
import SiteUserProfileIcon from '~/components/site/user/ProfileIcon.vue'

export default Vue.extend({
  name: 'SiteUserLabel',
  components: { SiteUserProfileIcon },
  props: {
    iconOnly: {
      type: Boolean,
    },
    customUser: {
      type: Boolean,
    },
    user: {
      type: Object as PropType<User>,
      default: undefined,
    },
  },
  computed: {
    displayUser(): User | undefined {
      if (this.customUser) return this.user
      return this.$users.user
    },
  },
})
