import { Context } from '@nuxt/types'
import {
  GetServerCookie,
  PromoBroadcasterCookie,
  PromoCookieUserId,
} from '~/assets/ts/utils/cookies'

export default async function (context: Context) {
  const store = context.store
  const adminIds = context.$users.user?.broadcasterAdminIds
  if (!adminIds || adminIds.length === 0) return

  let broadcasters = await context.$apiClient.getUserBroadcasters()
  broadcasters = broadcasters.filter((b) => adminIds.includes(b.broadcasterID))
  if (broadcasters.length) {
    store.commit('broadcasters/ADD_BROADCASTERS', broadcasters)
  }

  let broadcasterID = GetServerCookie(PromoBroadcasterCookie, context)

  if (!broadcasterID && broadcasters.length === 1) {
    // No cookie has been manually set, and they are linked to only one broadcaster.
    // In these circumstances we want to set the default broadcaster
    broadcasterID = broadcasters[0].broadcasterID
  }
  if (
    broadcasterID !== PromoCookieUserId &&
    broadcasters.find((b) => b.broadcasterID === broadcasterID)
  ) {
    // This user has a broadcaster set, and it is in the above list
    store.commit('broadcasters/SET_PROMO_BROADCASTER', broadcasterID)
  }
}
