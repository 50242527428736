import { Context } from '@nuxt/types'
import { AdStatsOptions } from '~/apiclient/apiads'
import {
  GetServerCookie,
  PromoBroadcasterCookie,
  PromoCookieUserId,
} from '~/assets/ts/utils/cookies'
import { DefaultAggregateStatsDates } from '~/components/site/promotional/FullStatsPage.vue'
import { GetAdTypeApiList, GetPageAdType } from '~/assets/ts/utils/promotions'

export default async function (context: Context) {
  const campaignID = context.params.adId
  let options = {} as AdStatsOptions
  if (campaignID) {
    options = { campaignID } as AdStatsOptions
  } else {
    let broadcasterID = GetServerCookie(PromoBroadcasterCookie, context) as
      | string
      | undefined
    if (!broadcasterID || broadcasterID === PromoCookieUserId) {
      broadcasterID = undefined
    }
    options = {
      adType: GetAdTypeApiList(GetPageAdType(context)),
      broadcasterID,
      ...DefaultAggregateStatsDates(context),
    } as AdStatsOptions
  }
  await context.store.dispatch('promo/fetchAdStatsAggregate', options)
}
