// do a very simple check to see if our objects have ACTUALLY changed in any meaningful way

export function ValuesMatch(oldValue: any, newValue: any) {
  return JSON.stringify(oldValue) === JSON.stringify(newValue)
}

export function validEmailFormat(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function validHashFormat(hash: string) {
  return /^#[A-Za-z0-9\-_:.]+$/.test(hash)
}

export function validUrlFormat(url: string) {
  try {
    const valid = new URL(url)
    return !!valid
  } catch (_) {
    return false
  }
}

export function validImageUrlFormat(url: string) {
  if (!validUrlFormat(url)) return false
  return /\.(jpeg|jpg|gif|png|bmp|webp)$/i.test(new URL(url).pathname)
}
