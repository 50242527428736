export type Environments = 'development' | 'review' | 'staging' | 'production'
export function Environment(): Environments {
  return (process.env.BRANCH as Environments) || 'development'
}

/**
 * Easy way to select a string based on the current development environment. The
 * primary purpose for this atm is selecting external urls (like Django) automatically.
 *
 * Production and Review values both fall back to the Staging value if not provided.
 *
 * */
export function StringByEnv(
  local: string,
  staging: string,
  production: string | undefined = undefined,
  review: string | undefined = undefined
): string {
  const env = Environment()
  switch (env) {
    case 'production':
      return production ?? staging
    case 'development':
      return local
    case 'staging':
      return staging
    case 'review':
      return review ?? staging
    default:
      console.error(`UNKNOWN ENVIRONMENT: ${env}`)
      return staging
  }
}
