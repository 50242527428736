
import Vue from 'vue'
import {
  MarkdownClasses,
  MarkdownInstance,
  MarkdownProps,
  MarkdownStyles,
} from '~/assets/ts/markdown/renderer'
import { ElementStyle } from '~/assets/ts/types/misc'

export default Vue.extend({
  name: 'MarkdownElement',
  props: {
    ...MarkdownProps,
  },
  computed: {
    text(): string {
      return MarkdownInstance(this.$props).parse(this.body.toString()) as string
    },
    classes(): string {
      return MarkdownClasses(this.$props)
    },
    styles(): ElementStyle {
      return MarkdownStyles(this.$props)
    },
  },
})
