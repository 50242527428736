
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import {
  DateFormat,
  SermonMetadataDisplay,
  SermonMetadataExtendedInfo,
} from '~/assets/ts/enums'
import {
  siteBroadcasterUrl,
  siteSeriesUrl,
  siteShareUrl,
  siteSpeakerUrl,
} from '~/assets/ts/utils/urls'
import { localizeDateTime, timesince } from '~/assets/ts/utils/date'
import { Speaker } from '~/models/speaker'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'
import { Broadcaster } from '~/models/broadcaster'

export default Vue.extend({
  name: 'SermonMetadata',
  components: { SaLinkOrHref, SaIcon, InlineIcon, LoadingElement },
  props: {
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    display: {
      type: Number as PropType<SermonMetadataDisplay>,
      default: SermonMetadataDisplay.Standard,
    },
    small: {
      type: Boolean,
    },
    showDate: {
      type: Boolean,
    },
    showLastPlayTime: {
      type: Boolean,
    },
    maxExtendedInfo: {
      type: Number as PropType<SermonMetadataExtendedInfo>,
      default: SermonMetadataExtendedInfo.Broadcaster,
    },
    showBibleText: {
      type: Boolean,
    },
    showFourthLine: {
      type: Boolean,
      default: true,
    },
    showDuration: {
      type: Boolean,
    },
    absoluteUrls: {
      type: Boolean,
    },
    soloUrls: {
      type: Boolean,
    },
  },
  computed: {
    lastPlayTime(): string {
      if (!this.showLastPlayTime) return ''
      const playTime = this.sermon?.lastPlayTime
      if (!playTime) return ''
      return timesince(playTime, this, true, 0, true)
    },
    lastPlayTimeTitle(): string {
      if (!this.showLastPlayTime) return ''
      const playTime = this.sermon?.lastPlayTime
      if (!playTime) return ''
      return timesince(playTime, this)
    },
    showLanguage(): boolean {
      if (this.small || this.minimal) return false
      return !!this.languageCode && this.languageCode !== 'en'
    },
    languageCode(): string {
      return this.sermon?.language ?? ''
    },
    showSecondaryInfo(): boolean {
      return (
        this.maxExtendedInfo !== SermonMetadataExtendedInfo.None &&
        !this.video &&
        this.showFourthLine
      )
    },
    showBroadcaster(): boolean {
      return this.maxExtendedInfo === SermonMetadataExtendedInfo.Broadcaster
    },
    canShowSeries(): boolean {
      return this.maxExtendedInfo <= SermonMetadataExtendedInfo.Series
    },
    canShowVerse(): boolean {
      return this.maxExtendedInfo <= SermonMetadataExtendedInfo.Verse
    },
    secondaryInfo(): Record<'title' | 'url' | 'type', string> | undefined {
      if (!this.sermon) return undefined
      const series = this.sermon.series
      if (series && this.canShowSeries && !this.showBibleText) {
        const base = siteSeriesUrl(series)
        return {
          title: series.title,
          url: this.soloUrls
            ? series.SoloUrl
            : this.absoluteUrls
              ? siteShareUrl(base)
              : base,
          type: this.$t('Series').toString(),
        }
      } else if (this.sermon.bibleText && this.canShowVerse) {
        return {
          title: this.sermon.bibleText,
          url: '',
          type: this.$t('Bible Text').toString(),
        }
      } else {
        return {
          title: this.sermon.eventType,
          url: '',
          type: this.$t('Category').toString(),
        }
      }
    },
    date(): string {
      if (!this.sermon) return ''
      const date = this.sermon.preachDate
      let format = DateFormat.CompressedDate
      if (this.featured) {
        format = DateFormat.Date
      }
      return localizeDateTime(date, format)
    },
    speaker(): Speaker | undefined {
      return this.sermon?.speaker
    },
    speakerName(): string {
      return this.speaker?.displayName ?? ''
    },
    speakerUrl(): string {
      if (this.soloUrls) {
        return this.broadcaster
          ? (this.speaker?.getSoloUrl(this.broadcaster) ?? '')
          : ''
      }
      const base = siteSpeakerUrl(this.speaker)
      if (this.absoluteUrls) return siteShareUrl(base)
      return base
    },
    hasPlays(): boolean {
      return this.sermon && this.playCount !== ''
    },
    playCount(): string {
      return this.sermon ? this.sermon.roundedPlayCountDisplay : ''
    },
    broadcasterName(): string {
      return this.broadcaster?.shortName ?? this.broadcaster?.displayName ?? ''
    },
    broadcaster(): Broadcaster | undefined {
      return this.sermon?.broadcaster
    },
    broadcasterUrl(): string {
      const broadcaster = this.broadcaster
      if (this.soloUrls) {
        return broadcaster?.SoloUrl ?? ''
      }
      const base = siteBroadcasterUrl(broadcaster)
      if (this.absoluteUrls) return siteShareUrl(base)
      return base
    },
    hasSeries(): boolean {
      return !!this.sermon?.series
    },
    duration(): string {
      if (!this.sermon || !this.sermon.duration) return ''
      return this.sermon.durationString
    },
    commentCount(): number {
      return this.sermon ? this.sermon.commentCount : 0
    },
    featured(): boolean {
      return this.isDisplay([SermonMetadataDisplay.Featured])
    },
    video(): boolean {
      return this.isDisplay([
        SermonMetadataDisplay.VideoWrap,
        SermonMetadataDisplay.Video,
      ])
    },
    minimal(): boolean {
      return this.isDisplay([
        SermonMetadataDisplay.Minimal,
        SermonMetadataDisplay.MinimalExtended,
      ])
    },
    wrap(): boolean {
      return this.isDisplay([
        SermonMetadataDisplay.ExtendedWrap,
        SermonMetadataDisplay.StandardWrap,
        SermonMetadataDisplay.Featured,
        SermonMetadataDisplay.VideoWrap,
      ])
    },
    thirdLine(): boolean {
      return this.isDisplay([
        SermonMetadataDisplay.ExtendedWrap,
        SermonMetadataDisplay.Extended,
        SermonMetadataDisplay.MinimalExtended,
        SermonMetadataDisplay.Featured,
        SermonMetadataDisplay.VideoWrap,
        SermonMetadataDisplay.Video,
      ])
    },
  },
  methods: {
    isDisplay(list: SermonMetadataDisplay[]) {
      return list.includes(this.display)
    },
    adClicked() {
      this.$emit('sermonAdClicked')
    },
  },
})
