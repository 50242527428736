import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { AudioCodecType, VideoCodecType } from '~/models/media'
import { Users } from '~/models/users/users'

export interface WebcastApiOriginalStreamInfo {
  width: number
  height: number
  audioCodec: AudioCodecType
  videoCodec: VideoCodecType
  bitrate: number
}

export interface WebcastApi {
  archivedSermons?: Record<string, any>[]
  archivedSermonID?: string
  audioStreamURL: string
  boostedWebcast?: boolean
  broadcasterID: string
  broadcasterImageURL?: string
  broadcasterImageURLResizable?: string
  broadcasterLat: number
  broadcasterLocation: string
  broadcasterLong: number
  broadcasterMinister: string
  completeStreamJSON?: string
  displayName: string
  endTime?: number
  eventStreamURL: string
  audioEventStreamURL: string
  followedBroadcaster: boolean
  group?: string
  isLive?: boolean
  isPublic?: boolean
  isPending?: boolean
  miles?: number
  originalStreamInfo?: WebcastApiOriginalStreamInfo
  peakListenerCount: number
  previewImageURL: string
  priorityBoostedWebcast?: boolean
  reconnectCount?: number
  resizablePreviewImageURL?: string
  startTime: number
  totalListenLineCount?: number
  totalTuneInCount: number
  videoStreamURL: string
  webcastID: number
  webcastTitle?: string
  webcastDescription?: string
  webcastSpeakerName?: string
  webcastSpeaker?: SpeakerApi
}

export interface WebcastsInProgressOptions {
  broadcasterID?: string
  showHidden?: boolean
  showPending?: boolean
  boostedOnly?: boolean
  ignoreFollowed?: boolean
}

export interface WebcastOptions {
  broadcasterID: string
  webcastID: number
}

export async function getWebcastsInProgress(
  this: ApiClient,
  params = {} as WebcastsInProgressOptions
): Promise<WebcastApi[]> {
  if (params.showHidden === undefined && !params.broadcasterID) {
    const users = (this.$context as any).$users as Users
    if (users.user?.admin) {
      params.showHidden = true
    }
  }
  // https://git.sermonaudio.com/project-management/main/-/wikis/Discover-Tab-APIs#get-webcasts-in-progress
  // https://git.sermonaudio.com/project-management/main/-/wikis/APIs-for-SermonAudio-2.0#lite-model-objects
  const { data } = await this.$axios.get('node/webcasts/lite', {
    params,
  })
  return data.results
}

export async function getLiveWebcast(
  this: ApiClient,
  broadcasterID: string,
  showPending = false
): Promise<WebcastApi | undefined> {
  const { data } = await this.$axios.get('node/webcasts/lite', {
    params: {
      // only use this if you are logged in as an admin and needing to test webcasts with a hidden broadcaster account
      // showHidden: true,
      showPending,
    },
  })
  const liveWebcasts = data.results as WebcastApi[]
  return liveWebcasts.find((webcast) => webcast.broadcasterID === broadcasterID)
}

export async function getWebcast(
  this: ApiClient,
  params: WebcastOptions
): Promise<WebcastApi> {
  const { data } = await this.$axios.get(
    `broadcasters/${params.broadcasterID}/webcasts/${params.webcastID}`
  )
  return data
}

export interface WebcastHistoryOptions {
  broadcasterID?: string
  page?: number
  pageSize?: number
  excludeWebcastsInProgress?: boolean
  minimumDurationSeconds?: number
}

export async function getWebcastHistory(
  this: ApiClient,
  params: WebcastHistoryOptions = {}
): Promise<ApiPaginationResult<WebcastApi>> {
  const { data } = await this.$axios.get('node/webcasts/history', {
    params,
  })
  return data
}

export interface WebcastCountOptions {
  broadcasterID?: string
  showHidden?: boolean
}

export async function getWebcastCount(
  this: ApiClient,
  params: WebcastCountOptions = {}
): Promise<number> {
  if (params.showHidden === undefined) {
    if (params.broadcasterID) {
      params.showHidden = true
    } else {
      const users = (this.$context as any).$users as Users
      if (users.user?.admin) {
        params.showHidden = true
      }
    }
  }
  const { data } = await this.$axios.get('node/webcasts/count', {
    params,
  })
  return data
}
