/* eslint-disable prefer-spread */
import { Context } from '@nuxt/types'
import Vue from 'vue'
import { Location, Route } from 'vue-router/types/router'
import { Inject } from '@nuxt/types/app'
import { GetApiCacheMax } from '~/assets/ts/utils/date'
import { isExternalUrl } from '~/assets/ts/utils/misc'

declare module 'vue/types/vue' {
  interface Vue {
    $navigateTo: (to: string | Location) => Promise<Route>
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $navigateTo: (to: string | Location) => Promise<Route>
  }
  interface Context {
    $redirectTo: (to: string) => void
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $navigateTo: (to: string | Location) => Promise<Route>
  }
}

export default function (
  { app, redirect, req, i18n }: Context,
  inject: Inject
) {
  process.env.LOCALE = i18n.locale
  Vue.prototype.$t = function (...args: any) {
    const result = this.$i18n.t.apply(this.$i18n, args)
    return result === '' ? args[0] : result
  }
  Vue.prototype.$tc = function (...args: any) {
    const result = this.$i18n.tc.apply(this.$i18n, args)
    return result === '' ? args[0] : result
  }
  Vue.prototype.$navigateTo = function (to: string | Location) {
    return this.$router.push(this.localePath(to)).catch((e: Error) => {
      if (!e.message.includes('navigation guard')) {
        console.warn(e)
      }
    })
  }

  inject('redirectTo', (to: string) => {
    if (!isExternalUrl(to)) {
      to = app.localePath(to)
    }
    return redirect(to)
  })

  // onBeforeLanguageSwitch called right before setting a new locale
  // app.i18n.onBeforeLanguageSwitch = (
  //   oldLocale: string,
  //   newLocale: string,
  //   isInitialSetup: boolean
  // ) => {
  //   console.log(oldLocale, newLocale, isInitialSetup)
  // }

  // onLanguageSwitched called right after a new locale has been set
  // app.i18n.onLanguageSwitched = (_oldLocale: string, newLocale: string) => {
  //   console.log(_oldLocale, newLocale)
  //   context.$axios.onRequest((config) => {
  //     if (!context.$isClient) return
  //     config.headers.common['Accept-Language'] = newLocale
  //   })
  // }
  app.$axios.interceptors.request.use((config) => {
    const locale = app.i18n.locale
    app.$axios.setHeader('Accept-Language', locale)

    config.params = config.params || {}

    let hostname = ''
    // we need to set the domain to alleviate CORS caching issues
    if (req?.headers.host) {
      hostname = req.headers.host
      // Ensure that all server-side requests have an Origin header so that API
      // responses include CORS headers that can be cached by CDN
      app.$axios.setHeader('Origin', `https://${hostname}`)
    } else if (typeof window !== 'undefined') {
      hostname = window.location.hostname
      // The browser sets the Origin header; no need for us to do it
    } else if (req) {
      console.warn(
        'ORIGIN ERROR: No Host Headers or Window',
        config.url,
        req.headers
      )
    } else {
      console.warn('ORIGIN ERROR: No Request or Window', config.url)
    }

    if (!config.params.noCache) {
      // make sure our cached language matches the accept-language
      config.params.cacheLanguage = locale

      // set our cacheMax to today so that the cache is never more than 1 day old
      config.params.cacheMax = GetApiCacheMax()

      if (hostname) {
        config.params.cacheDomain = hostname.split(/[:.]/)[0]
      }
    }

    return config
  })
}
