import { TranslateResult } from 'vue-i18n'
import Vue from 'vue'
import AlertModal from '~/components/_general/AlertModal.vue'
import { AlertButton } from '~/models/generic/alert-button'
import { sendModalizeToIframeParent } from '~/assets/ts/utils/iframe'
import { siteLoginUrl } from '~/assets/ts/utils/urls'
import SiteUserLearnMore from '~/components/site/user/LearnMore.vue'
import DashboardBatchModal from '~/components/dashboard/BatchModal.vue'
import { PromiseAll, waitSeconds } from '~/assets/ts/utils/misc'

export interface SiteAlertModalParams {
  title?: TranslateResult
  text?: TranslateResult
  neutral?: AlertButton
  positive?: AlertButton
  negative?: AlertButton
  /** Name of component to show after text */
  component?: string | any
  componentProps?: Record<string, any>
  theme?: boolean
  maxWidth?: number
  noOverscroll?: boolean
  disableModalize?: boolean
  onClose?: () => void
}

/** https://euvl.github.io/vue-js-modal/Events.html#examples */
export function SiteAlertModal(context: Vue, params: SiteAlertModalParams) {
  const theme = params.theme ?? true
  const width = params.maxWidth ?? 640
  const componentProps = params.componentProps ?? {}
  context.$modal.show(
    AlertModal,
    {
      title: params.title,
      text: params.text,
      neutral: params.neutral,
      positive: params.positive,
      negative: params.negative,
      component: params.component,
      componentProps,
      theme,
    },
    {
      classes: theme ? 'modal-theme text-theme' : '',
      adaptive: true,
      height: 'auto',
      width,
    },
    {
      'before-open': () => {
        if (!params.disableModalize) {
          sendModalizeToIframeParent(true)
        }
        if (!params.noOverscroll) return
        context.$store.commit('site/SET_MODAL_OPEN', true)
      },
      'before-close': () => {
        if (!params.noOverscroll) return
        context.$store.commit('site/SET_MODAL_OPEN', false)
      },
      closed: () => {
        if (!params.disableModalize) {
          sendModalizeToIframeParent(false)
        }
        if (params.onClose) {
          params.onClose()
        }
      },
    }
  )
}

export function SiteUserRequiredModal(context: Vue, title: TranslateResult) {
  SiteAlertModal(context, {
    title,
    text: context.$t('user.limited'),
    neutral: new AlertButton({
      text: context.$t('Cancel'),
    }),
    positive: new AlertButton({
      text: context.$t('Sign In'),
      event: () => {
        context.$navigateTo(siteLoginUrl(context)).then()
      },
    }),
    component: SiteUserLearnMore,
  })
}

export interface BatchActionListItem {
  title: string
  action: () => Promise<boolean>
}

export interface BatchActionModalParams {
  title: TranslateResult
  text: TranslateResult
  /** Show a blue button instead of a red one */
  positive?: boolean
  /** The list of items to action on */
  list: BatchActionListItem[]
  after?: () => Promise<any>
}

export function BatchActionModal(context: Vue, params: BatchActionModalParams) {
  const props = {
    running: false,
    statuses: params.list.map((_) => 0),
    list: params.list,
  }
  const updatedParams = {
    title: params.title,
    text: params.text,
    neutral: new AlertButton({
      text: context.$t('Cancel'),
    }),
    component: DashboardBatchModal,
    componentProps: props,
  } as SiteAlertModalParams
  updatedParams[params.positive ? 'positive' : 'negative'] = new AlertButton({
    text: params.title,
    event: async () => {
      props.running = true
      await PromiseAll(
        params.list.map(async (item, index) => {
          const result = await item.action()
          context.$set(props.statuses, index, result ? 2 : 1)
        })
      )
      if (params.after) {
        await params.after()
      }
      await waitSeconds(1)
    },
  })
  SiteAlertModal(context, updatedParams)
}
