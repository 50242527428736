
import Vue from 'vue'

export default Vue.extend({
  name: 'HorizontalRule',
  props: {
    type: {
      type: String,
      default: 'div',
    },
    darker: {
      type: Boolean,
    },
  },
})
