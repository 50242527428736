import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { CustomSiteDomain, CustomSiteText } from '~/apiclient/apicustomsites'

export const state = () => ({
  customSiteText: {} as Record<string, CustomSiteText>,
  rewriteParams: {} as Record<string, string>,
  hostname: '',
  apiKey: '',
})

export type State = ReturnType<typeof state>

export const getters: GetterTree<State, State> = {
  rewriteParams: (state) => state.rewriteParams,
  customSiteText: (state) => state.customSiteText,
  hostname: (state) => state.hostname,
  apiKey: (state) => state.apiKey,
}

export const mutations: MutationTree<State> = {
  SET_HOSTNAME: (state, hostname: string) => {
    state.hostname = hostname
  },
  SET_CUSTOM_SITE_TEXT: (state, textRecord: Record<string, CustomSiteText>) => {
    state.customSiteText = { ...state.customSiteText, ...textRecord }
  },
  REWRITE_PARAM: (state, params: Record<string, any>) => {
    const paramKeys = Object.keys(params)
    for (let i = 0; i < paramKeys.length; i++) {
      Vue.set(state.rewriteParams, paramKeys[i], params[paramKeys[i]])
    }
  },
  SET_API_KEY: (state, key: string) => {
    state.apiKey = key
  },
}

export const actions: ActionTree<State, State> = {
  async nuxtServerInit({ commit }, { app, req }) {
    commit('SET_HOSTNAME', req.headers.host)
    // we don't need to get the public key on local dev
    // if we want to emulate production, just disable this check
    if (app.context.isDev) {
      console.warn('USING INTERNAL API KEY')
      commit('SET_API_KEY', process.env.SAWEB_API_KEY)
      return
    }
    try {
      const { data } = await this.$axios.get('current_api_key')
      commit('SET_API_KEY', data)
    } catch (_e) {
      console.error('API Key could not be retrieved.')
    }
  },
  async getVaultCustomText({ dispatch }) {
    const domain = 'vault.sermonaudio.com' as CustomSiteDomain
    await dispatch('getCustomSiteText', domain)
  },
  async getFoundationsPsalterCustomText({ dispatch }) {
    const domain = 'foundationspsalter.com' as CustomSiteDomain
    await dispatch('getCustomSiteText', domain)
  },
  async getSermonAudio({ dispatch }) {
    const domain = 'www.sermonaudio.com' as CustomSiteDomain
    await dispatch('getCustomSiteText', domain)
  },
  async getCustomSiteText({ commit }, domain: CustomSiteDomain) {
    const text = await this.$apiClient.getCustomText(domain)
    commit('SET_CUSTOM_SITE_TEXT', text)
  },
}
