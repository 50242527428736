import { Context } from '@nuxt/types'
import { UserAdApi } from '~/apiclient/apiads'

export default async function ({ params, error, store }: Context) {
  const id = params.adId
  if (id) {
    await store.dispatch('promo/fetchAd', id)
    const ad = store.getters['promo/currentAd'] as UserAdApi | undefined
    if (ad) return
  }
  error({
    statusCode: 404,
    message: 'Promotional content not found',
  })
}
