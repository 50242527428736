import { TokenizerAndRendererExtension } from 'marked'
import { Editor } from '@tiptap/vue-2'
import { Node } from 'prosemirror-model'
import { ParseSaMarkdown } from '~/assets/ts/markdown/sermonaudio'

function ParsePlayer(src: string) {
  const parse = ParseSaMarkdown(src)
  if (!parse || !['audio', 'video'].includes(parse.type)) return undefined
  return parse
}

export function CountEditorPlayers(editor: Editor | undefined) {
  let count = 0
  if (!editor) return count
  editor.state.doc.descendants((n: Node) => {
    if (n.type.name === 'player') {
      count++
    }
  })
  return count
}

export function GetEditorPlayerNumber(
  editor: Editor | undefined,
  node: Node | undefined
) {
  let count = 0
  if (!editor || !node) return count
  let number = 0
  editor.state.doc.descendants((n: Node) => {
    if (n.type.name === 'player') {
      count++
      if (n === node) {
        number = count
      }
    }
  })
  return number
}

export const MaxMarkdownPlayers = 5

export const MarkedPlayerExtension = {
  name: 'player',
  level: 'block',
  start(src: string) {
    return ParsePlayer(src)?.index
  },
  tokenizer(src: string) {
    const params = ParseSaMarkdown(src)
    if (params) {
      return {
        type: 'player',
        raw: params.raw,
        params,
      }
    }
    return false
  },
  renderer(_token: any) {
    return ''
  },
} as TokenizerAndRendererExtension
