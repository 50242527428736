import { Context } from '@nuxt/types'
import { siteUserPageUrl } from '~/assets/ts/utils/urls'

export default function ({ $users, localePath, redirect, route }: Context) {
  const user = $users.user
  if (!user || !user.admin) {
    redirect(localePath(siteUserPageUrl('login')), {
      next: route.path,
      ...route.query,
    })
  }
}
