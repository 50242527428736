import Vue from 'vue'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'
import {
  SaEventRegistration,
  SaEventRegistrationResponse,
} from '~/models/events'

export const state = () => ({
  events: {} as any,
})

export type EventsState = ReturnType<typeof state>

export const mutations: MutationTree<EventsState> = {
  ADD_EVENT: (state, events: any) => {
    Vue.set(state.events, events.eventID, events)
  },
}

export const getters: GetterTree<EventsState, EventsState> = {
  event: (state) => (eventID: string) => state.events[eventID],
}

export const actions: ActionTree<EventsState, EventsState> = {
  async fetchEvent({ commit }, eventID) {
    try {
      const { data } = await this.$axios.get(`node/events/${eventID}`)
      commit('ADD_EVENT', data)
    } catch (e) {
      logError(e)
    }
  },
  async register(
    // eslint-disable-next-line no-empty-pattern
    {},
    { eventID, registration }
  ): Promise<SaEventRegistrationResponse | string> {
    const r = registration as SaEventRegistration
    try {
      const { data } = await this.$axios.$post(
        `node/events/${eventID}/register_to_attend`,
        r.submission
      )
      return data as SaEventRegistrationResponse
    } catch (e) {
      return e as string
    }
  },
}
