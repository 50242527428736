import { Context } from '@nuxt/types'

export default async function ({ params, store, error }: Context) {
  const topicID = params.topic
  let valid = !!topicID
  if (topicID) {
    await store.dispatch('topics/fetchTopic', { topicID })
    valid = !!store.getters['topics/topic'](topicID)
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: 'Topic not found',
    })
  }
}
