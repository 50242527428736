import { Context } from '@nuxt/types'
import { broadcasterSelectUrl } from '~/assets/ts/utils/urls'
import {
  GetServerCookie,
  LinkedBroadcasterCookie,
} from '~/assets/ts/utils/cookies'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'

export default async function (context: Context) {
  const broadcasterID = GetServerCookie(LinkedBroadcasterCookie, context)
  const broadcaster = context.store.getters['dashboard/broadcaster'] as
    | BroadcasterApi
    | undefined
  if (broadcaster?.broadcasterID === broadcasterID) return
  const user = context.$users.user
  const admin = user?.admin
  const roles = user?.broadcasterRoles ?? []
  if (admin || roles.length) {
    const role = roles.find((role) => role.broadcasterID === broadcasterID)
    if (role || admin) {
      await context.store.dispatch('dashboard/fetchBroadcaster', {
        broadcasterID,
      })
      return
    }
  }
  context.$redirectTo(context.localePath(broadcasterSelectUrl(context)))
}
