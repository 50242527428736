export function returnEmptyListOnError(_reason: any) {
  return []
}

/**
 * Checks if list is empty. If empty it returns placeholders amount of undefined, else return list
 * @param list
 * @param placeholders
 */
export function getListWithPlaceholders<T>(list: T[], placeholders = 5): T[] {
  if (list.length >= 1) return list
  return getUndefinedArray(placeholders)
}

export function getUndefinedArray<T>(count = 5): T[] {
  return Array(count).fill(undefined)
}

export function randomInArray<T>(array: T[]) {
  return array[Math.floor(Math.random() * array.length)]
}

export function firstInArray<T>(array: T[]): T | undefined {
  return array.length ? array[0] : undefined
}

/** Removes every *nth* element to condense an array down to a max length */
export function condenseArray<T>(array: T[], maximumLength: number) {
  if (array.length <= maximumLength) return array
  const step = array.length / maximumLength
  return array.filter((_v, i) => Math.floor(i % step) === 0)
}

export function alphabetizeObjectArray(
  array: Record<string, any>[],
  property: string
) {
  return array.sort((a, b) => (a[property] > b[property] ? 1 : -1))
}

export function clampArray<T>(arr: T[], maxLength: number) {
  return arr.slice(0, maxLength)
}
