import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import {
  GetLocalStorage,
  SetLocalStorage,
} from '~/assets/ts/utils/localstorage'
import { GetDefaultWebPreferences, WebPreferences } from '~/models/users/user'

const MyChurchID = 'my-church.broadcaster-id'
const WebPreferencesID = 'preferences'

export const state = () => ({
  myChurchBroadcasterID: '',
  webPreferences: {} as WebPreferences,
})

export type LocalState = ReturnType<typeof state>
export const mutations: MutationTree<LocalState> = {
  SET_MY_CHURCH_BROADCASTER_ID: (state, broadcaster: string) => {
    state.myChurchBroadcasterID = SetLocalStorage(MyChurchID, broadcaster)
  },
  SET_WEB_PREFERENCES: (state, preferences: WebPreferences) => {
    Vue.set(
      state,
      'webPreferences',
      SetLocalStorage(WebPreferencesID, preferences)
    )
  },
  INITIALIZE: (state) => {
    state.myChurchBroadcasterID = GetLocalStorage(MyChurchID, '')
    state.webPreferences = GetLocalStorage(
      WebPreferencesID,
      GetDefaultWebPreferences()
    )
  },
}

export const getters: GetterTree<LocalState, LocalState> = {
  myChurchBroadcasterID: (state) => state.myChurchBroadcasterID,
  webPreferences: (state) => state.webPreferences,
}

export const actions: ActionTree<LocalState, LocalState> = {
  nuxtClientInit({ commit }) {
    commit('INITIALIZE')
  },
  setMyChurchBroadcasterID({ commit }, broadcasterID: string) {
    commit('SET_MY_CHURCH_BROADCASTER_ID', broadcasterID)
  },
  setWebPreferences({ commit }, preferences: WebPreferences) {
    commit('SET_WEB_PREFERENCES', preferences)
  },
}
