import { Context } from '@nuxt/types'
import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $isClient: boolean
    $isDev: boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isClient: boolean
    $isDev: boolean
  }
  interface Context {
    $isClient: boolean
    $isDev: boolean
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $isClient: boolean
    $isDev: boolean
  }
}

export default function (context: Context) {
  Vue.prototype.$isClient = process.browser
  Vue.prototype.$isDev = context.isDev
}
