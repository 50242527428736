
import Vue, { PropType } from 'vue'

export type LoadingTypes = 'lazy' | 'eager'

export const SaImageProps = {
  src: {
    type: String as PropType<string | undefined>,
    default: undefined,
  },
  alt: {
    type: String,
    default: '',
  },
  loading: {
    type: String as PropType<LoadingTypes>,
    default: 'lazy',
  },
  width: {
    type: Number,
    default: undefined,
  },
  quality: {
    type: Number,
    default: 100,
  },
  height: {
    type: Number,
    default: undefined,
  },
  format: {
    type: String,
    default: 'webp',
  },
}

export default Vue.extend({
  name: 'SaImage',
  props: {
    ...SaImageProps,
  },
})
