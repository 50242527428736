import { Broadcaster } from '~/models/broadcaster'
import { boolOrUndefined, resizableUrl } from '~/assets/ts/utils/misc'

export class Speaker {
  id: number
  displayName: string
  sortName: string
  sermonCount: number
  portraitUrl: string
  albumArtUrl: string
  genericThumbnail: boolean
  roundedThumbnailImageURL?: string
  roundedThumbnailImageURLResizable?: string
  bio?: string
  /** mostRecentPreachDate is only set by specific apis */
  mostRecentPreachDate?: Date
  following?: boolean
  broadcasterLock: string
  lite: boolean
  rssFeedAtomURL?: string
  rssFeedURL?: string

  constructor(speaker: Record<string, any>) {
    this.id = speaker.speakerID
    if (!this.id) {
      const msg = `Speaker missing ID: ${speaker.type}`
      if (speaker.type !== 'lite_sermon_speaker') {
        console.error(msg)
        alert(msg)
      } else {
        console.warn(msg)
      }
    }
    this.lite = !!speaker.lite
    this.displayName = speaker.displayName
    this.sortName = speaker.sortName
    this.portraitUrl = speaker.portraitURL
    this.albumArtUrl = speaker.albumArtURL
    this.genericThumbnail = true
    if (speaker.roundedThumbnailImageURL) {
      this.genericThumbnail =
        speaker.roundedThumbnailImageURL.includes('generic')
    }
    this.roundedThumbnailImageURL = this.genericThumbnail
      ? undefined
      : speaker.roundedThumbnailImageURL
    this.roundedThumbnailImageURLResizable = this.genericThumbnail
      ? undefined
      : speaker.roundedThumbnailImageURLResizable
    this.bio = speaker.bio ? speaker.bio : undefined
    this.sermonCount = speaker.sermonCount
    this.mostRecentPreachDate = speaker.mostRecentPreachDate
      ? new Date(speaker.mostRecentPreachDate)
      : undefined
    this.following = boolOrUndefined(speaker.followed)
    this.broadcasterLock = speaker.broadcasterLock

    this.rssFeedAtomURL = speaker.rssFeedAtomURL
    this.rssFeedURL = speaker.rssFeedURL
  }

  get sermonCountDisplay(): string {
    return this.sermonCount.toLocaleString()
  }

  get SaUrl(): string {
    return `https://www.sermonaudio.com/speaker/${this.displayName.replaceAll(
      ' ',
      '_'
    )}`
  }

  get shareTitle(): string {
    return this.displayName
  }

  getSoloUrl(broadcaster: Broadcaster): string {
    return `${broadcaster.SoloUrl}sermons/speakers/${this.displayName}/`
  }

  getUrl(broadcaster: Broadcaster): string {
    return broadcaster.soloSiteDisabled
      ? this.SaUrl
      : this.getSoloUrl(broadcaster)
  }

  albumArt(size: number): string | undefined {
    if (!this.albumArtUrl) return undefined
    return this.albumArtUrl.replaceAll(/{size}/g, size.toString())
  }

  resizableImage(size: number): string | undefined {
    const url = this.roundedThumbnailImageURLResizable
    if (!url) return undefined
    return resizableUrl(url, size, size)
  }

  matchesSearchTerm(searchTerm: string): boolean {
    return this.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  }
}

export class AdminSpeaker extends Speaker {
  currentImageIndex?: number
  maxImageCount?: number
  constructor(speaker: any) {
    super(speaker)
    this.currentImageIndex = speaker.imageLiveGeneration
    this.maxImageCount = speaker.imageMaxGeneration
  }
}
