
import Vue, { PropType } from 'vue'
import SaImage, { LoadingTypes } from '~/components/_general/SaImage.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import VideoPreview from '~/components/_general/VideoPreview.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'

export default Vue.extend({
  name: 'VideoThumbnail',
  components: { VideoPreview, SaImage, LoadingElement },
  props: {
    object: {
      type: Object,
      default: undefined,
    },
    hoverPreview: {
      type: Boolean,
    },
    preview: {
      type: Boolean,
    },
    previewControls: {
      type: Boolean,
    },
    imageUrl: {
      type: String,
      default: '',
    },
    videoStreamUrl: {
      type: String,
      default: undefined,
    },
    to: {
      type: String,
      default: '',
    },
    rounded: {
      type: String,
      default: 'rounded',
      validator(value: string) {
        return value.includes('rounded')
      },
    },
    loadingClasses: {
      type: String,
      default: '',
    },
    loading: {
      type: String as PropType<LoadingTypes>,
      default: 'lazy',
    },
    volumeOverride: {
      type: Number,
      default: 0,
      validator(value: number) {
        return value >= 0 && value <= 100
      },
    },
    duration: {
      type: String,
      default: undefined,
    },
    absoluteUrls: {
      type: Boolean,
    },
  },
  computed: {
    SaLinkOrHref() {
      return SaLinkOrHref
    },
    mobile() {
      return this.$device.isMobile
    },
  },
})
