import locale9ec66352 from '../../locales/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"formatFallbackMessages":true,"fallbackRootWithEmptyString":true,"fallbackLocale":"en","silentTranslationWarn":true,"silentFallbackWarn":true},
  vueI18nLoader: false,
  locales: [{"name":"English (US)","code":"en","iso":"en-US","file":"en.json"},{"name":"English (UK)","code":"gb","iso":"en-GB","file":"en.json"},{"name":"Español","code":"es","iso":"es-ES","file":"es.json"},{"name":"Deutsch","code":"de","iso":"de","file":"de.json"},{"name":"​ខ្មែរ","code":"km","iso":"km","file":"km.json"},{"name":"한국어","code":"ko","iso":"ko","file":"ko.json"},{"name":"Português","code":"pt","iso":"pt","file":"pt.json"},{"name":"中文 (简体)","code":"zh","iso":"zh","file":"zh.json"},{"name":"Italiano","code":"it","iso":"it","file":"it.json"},{"name":"Français","code":"fr","iso":"fr","file":"fr.json"},{"name":"தமிழ்","code":"ta","iso":"ta","file":"ta.json"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://www.sermonaudio.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: false,
  normalizedLocales: [{"name":"English (US)","code":"en","iso":"en-US","file":"en.json"},{"name":"English (UK)","code":"gb","iso":"en-GB","file":"en.json"},{"name":"Español","code":"es","iso":"es-ES","file":"es.json"},{"name":"Deutsch","code":"de","iso":"de","file":"de.json"},{"name":"​ខ្មែរ","code":"km","iso":"km","file":"km.json"},{"name":"한국어","code":"ko","iso":"ko","file":"ko.json"},{"name":"Português","code":"pt","iso":"pt","file":"pt.json"},{"name":"中文 (简体)","code":"zh","iso":"zh","file":"zh.json"},{"name":"Italiano","code":"it","iso":"it","file":"it.json"},{"name":"Français","code":"fr","iso":"fr","file":"fr.json"},{"name":"தமிழ்","code":"ta","iso":"ta","file":"ta.json"}],
  localeCodes: ["en","gb","es","de","km","ko","pt","zh","it","fr","ta"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "e",
  34: "s",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "o",
  48: "c",
  49: "a",
  50: "l",
  51: "e",
  52: "s",
  53: "/",
  54: "e",
  55: "s",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: ",",
  63: "\"",
  64: "d",
  65: "e",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: ":",
  73: "\"",
  74: ".",
  75: ".",
  76: "/",
  77: "l",
  78: "o",
  79: "c",
  80: "a",
  81: "l",
  82: "e",
  83: "s",
  84: "/",
  85: "d",
  86: "e",
  87: ".",
  88: "j",
  89: "s",
  90: "o",
  91: "n",
  92: "\"",
  93: ",",
  94: "\"",
  95: "k",
  96: "m",
  97: ".",
  98: "j",
  99: "s",
  100: "o",
  101: "n",
  102: "\"",
  103: ":",
  104: "\"",
  105: ".",
  106: ".",
  107: "/",
  108: "l",
  109: "o",
  110: "c",
  111: "a",
  112: "l",
  113: "e",
  114: "s",
  115: "/",
  116: "k",
  117: "m",
  118: ".",
  119: "j",
  120: "s",
  121: "o",
  122: "n",
  123: "\"",
  124: ",",
  125: "\"",
  126: "k",
  127: "o",
  128: ".",
  129: "j",
  130: "s",
  131: "o",
  132: "n",
  133: "\"",
  134: ":",
  135: "\"",
  136: ".",
  137: ".",
  138: "/",
  139: "l",
  140: "o",
  141: "c",
  142: "a",
  143: "l",
  144: "e",
  145: "s",
  146: "/",
  147: "k",
  148: "o",
  149: ".",
  150: "j",
  151: "s",
  152: "o",
  153: "n",
  154: "\"",
  155: ",",
  156: "\"",
  157: "p",
  158: "t",
  159: ".",
  160: "j",
  161: "s",
  162: "o",
  163: "n",
  164: "\"",
  165: ":",
  166: "\"",
  167: ".",
  168: ".",
  169: "/",
  170: "l",
  171: "o",
  172: "c",
  173: "a",
  174: "l",
  175: "e",
  176: "s",
  177: "/",
  178: "p",
  179: "t",
  180: ".",
  181: "j",
  182: "s",
  183: "o",
  184: "n",
  185: "\"",
  186: ",",
  187: "\"",
  188: "z",
  189: "h",
  190: ".",
  191: "j",
  192: "s",
  193: "o",
  194: "n",
  195: "\"",
  196: ":",
  197: "\"",
  198: ".",
  199: ".",
  200: "/",
  201: "l",
  202: "o",
  203: "c",
  204: "a",
  205: "l",
  206: "e",
  207: "s",
  208: "/",
  209: "z",
  210: "h",
  211: ".",
  212: "j",
  213: "s",
  214: "o",
  215: "n",
  216: "\"",
  217: ",",
  218: "\"",
  219: "i",
  220: "t",
  221: ".",
  222: "j",
  223: "s",
  224: "o",
  225: "n",
  226: "\"",
  227: ":",
  228: "\"",
  229: ".",
  230: ".",
  231: "/",
  232: "l",
  233: "o",
  234: "c",
  235: "a",
  236: "l",
  237: "e",
  238: "s",
  239: "/",
  240: "i",
  241: "t",
  242: ".",
  243: "j",
  244: "s",
  245: "o",
  246: "n",
  247: "\"",
  248: ",",
  249: "\"",
  250: "f",
  251: "r",
  252: ".",
  253: "j",
  254: "s",
  255: "o",
  256: "n",
  257: "\"",
  258: ":",
  259: "\"",
  260: ".",
  261: ".",
  262: "/",
  263: "l",
  264: "o",
  265: "c",
  266: "a",
  267: "l",
  268: "e",
  269: "s",
  270: "/",
  271: "f",
  272: "r",
  273: ".",
  274: "j",
  275: "s",
  276: "o",
  277: "n",
  278: "\"",
  279: ",",
  280: "\"",
  281: "t",
  282: "a",
  283: ".",
  284: "j",
  285: "s",
  286: "o",
  287: "n",
  288: "\"",
  289: ":",
  290: "\"",
  291: ".",
  292: ".",
  293: "/",
  294: "l",
  295: "o",
  296: "c",
  297: "a",
  298: "l",
  299: "e",
  300: "s",
  301: "/",
  302: "t",
  303: "a",
  304: ".",
  305: "j",
  306: "s",
  307: "o",
  308: "n",
  309: "\"",
  310: "}",
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'es.json': () => import('../../locales/es.json' /* webpackChunkName: "lang-es.json" */),
  'de.json': () => import('../../locales/de.json' /* webpackChunkName: "lang-de.json" */),
  'km.json': () => import('../../locales/km.json' /* webpackChunkName: "lang-km.json" */),
  'ko.json': () => import('../../locales/ko.json' /* webpackChunkName: "lang-ko.json" */),
  'pt.json': () => import('../../locales/pt.json' /* webpackChunkName: "lang-pt.json" */),
  'zh.json': () => import('../../locales/zh.json' /* webpackChunkName: "lang-zh.json" */),
  'it.json': () => import('../../locales/it.json' /* webpackChunkName: "lang-it.json" */),
  'fr.json': () => import('../../locales/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'ta.json': () => import('../../locales/ta.json' /* webpackChunkName: "lang-ta.json" */),
}
