
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { AlertButton } from '~/models/generic/alert-button'
import CustomScroll from '~/components/_general/CustomScroll.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import SiteButton from '~/components/site/Button.vue'
import SiteButtonSpinner from '~/components/site/ButtonSpinner.vue'

export default Vue.extend({
  name: 'AlertModal',
  components: {
    SiteButtonSpinner,
    SiteButton,
    MarkdownElement,
    CustomScroll,
  },
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      default: undefined,
    },
    text: {
      type: String as PropType<TranslateResult>,
      default: undefined,
    },
    neutral: {
      type: AlertButton,
      default: undefined,
    },
    positive: {
      type: AlertButton,
      default: undefined,
    },
    negative: {
      type: AlertButton,
      default: undefined,
    },
    component: {
      type: [Function, String],
      default: undefined,
    },
    theme: {
      type: Boolean,
      default: true,
    },
    componentProps: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      waiting: false,
    }
  },
  beforeDestroy() {
    this.$store.commit('site/SET_MODAL_OPEN', false)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async click(event: Event, button: AlertButton) {
      this.waiting = true
      const close = await button.click(event)
      this.waiting = false
      if (close) this.close()
    },
    order(button: AlertButton): string {
      if (!button.order) return ''
      return `order: ${button.order}`
    },
  },
})
