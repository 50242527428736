import { Context } from '@nuxt/types'
import { UserAdApi } from '~/apiclient/apiads'

export default async function ({ params, store }: Context) {
  let sermonID = undefined as string | undefined
  if (params.adId) {
    const ad = store.getters['promo/currentAd'] as UserAdApi | undefined
    sermonID = ad?.sermon?.sermonID
  }
  await store.dispatch('promo/fetchAdConfig', sermonID)
}
