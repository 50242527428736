import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'
import { Collection, CollectionModule } from '~/models/collection'
import { SermonRequestOptions } from '~/apiclient/apisermons'
import { SeriesRequestOptions } from '~/apiclient/apiseries'
import { SpeakerRequestOptions } from '~/apiclient/apispeakers'

export const state = () => ({
  collections: {} as Record<string, Collection>, // broadcasterID: BroadcasterApi
})

export type CollectionsState = ReturnType<typeof state>

export const mutations: MutationTree<CollectionsState> = {
  ADD_COLLECTION: (state, collection: Collection) => {
    Vue.set(state.collections, collection.slug, collection)
  },
  ADD_COLLECTIONS: (state, collections: Collection[]) => {
    collections.forEach((collection) => {
      Vue.set(state.collections, collection.slug, collection)
    })
  },
}

export const getters: GetterTree<CollectionsState, CollectionsState> = {
  collection: (state) => (collectionSlug: string) =>
    state.collections[collectionSlug],
}

export const actions: ActionTree<CollectionsState, CollectionsState> = {
  async fetchCollection({ commit, getters }, collectionSlug: string) {
    if (getters.collection(collectionSlug)) return
    try {
      const collection = await this.$apiClient.getCollection(collectionSlug)
      collection.accentColor = '#6752a2'
      // TODO: possibly work on converting Featured fields to modules like below
      // See collections index for more explanation on above
      // TODO: figure out translating these titles (this.$i18n.t() didn't work smoothly)
      collection.modules = [
        {
          title: 'Featured Speakers',
          type: 'SpeakerListWithFeature',
          apiOptions: {
            pageSize: 11,
            randomize: true,
          } as SpeakerRequestOptions,
        },
        {
          title: 'Featured Series',
          type: 'SeriesList',
          apiOptions: {
            pageSize: 4,
            sort_by: 'random',
          } as SeriesRequestOptions,
        },
        {
          title: 'Newest Series',
          type: 'SeriesList',
          apiOptions: {
            pageSize: 4,
            sort_by: 'last_updated',
          } as SeriesRequestOptions,
        },
        {
          title: 'Newest Speakers',
          type: 'SpeakerList',
          apiOptions: {
            pageSize: 10,
            sortBy: 'newest',
          } as SpeakerRequestOptions,
        },
        {
          title: 'Last Updated Content',
          type: 'SermonList',
          apiOptions: {
            pageSize: 3,
            sortBy: 'newest-published',
          } as SermonRequestOptions,
        },
        {
          title: 'Quote of the Day',
          type: 'Quote',
          apiOptions: {},
        },
      ] as CollectionModule[]

      commit('ADD_COLLECTION', collection)
    } catch (e) {
      logError(e)
    }
  },
}
