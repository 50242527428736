
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import {
  AdStatsAggregateApi,
  AdType,
  DateParams,
  LocationStats,
} from '~/apiclient/apiads'
import SiteButton from '~/components/site/Button.vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import {
  apiDatesToDatePickerDate,
  apiDateToJsDate,
  datePickerEndDate,
  datePickerStartDate,
  jsDateToApiDateStr,
  localizeDatePickerDate,
  localizeDateTime,
} from '~/assets/ts/utils/date'
import { DateFormat } from '~/assets/ts/enums'
import {
  GetAdTypeApiList,
  GetAdTypeStatRangeTitle,
} from '~/assets/ts/utils/promotions'
import DateInput, { DatePickerDate } from '~/components/_general/DateInput.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import GraphBar, { CountData } from '~/components/graph/Bar.vue'
import SitePromotionalLocationList from '~/components/site/promotional/LocationList.vue'
import {
  AdPage,
  AdStatsDateBeginKey,
  AdStatsDateEndKey,
  promotionUrl,
} from '~/assets/ts/utils/urls'
import { qsValue, updateQs } from '~/assets/ts/utils/params'
import { DateString } from '~/assets/ts/types/date-string'
import { ValuesMatch } from '~/assets/ts/utils/validation'
import { Broadcaster } from '~/models/broadcaster'
import SitePromotionalAdList from '~/components/site/promotional/AdList.vue'

export const DefaultAggregateStatsDates = (
  context: Record<string, any> | undefined = undefined
) => {
  const startQs = context
    ? (qsValue(context, AdStatsDateBeginKey) as DateString | undefined)
    : undefined
  const endQs = context
    ? (qsValue(context, AdStatsDateEndKey) as DateString | undefined)
    : undefined

  const now = new Date()
  const dateBegin = startQs || jsDateToApiDateStr(new Date(2024, 3, 25))
  const dateEnd = endQs || jsDateToApiDateStr(now)

  return {
    dateBegin,
    dateEnd,
  } as DateParams
}

export default Vue.extend({
  name: 'SitePromotionalFullStatsPage',
  components: {
    SitePromotionalAdList,
    GraphBar,
    LoadingElement,
    SiteTitledSection,
    SiteButton,
    DateInput,
    SitePromotionalLocationList,
  },
  props: {
    adType: {
      type: String as PropType<AdType>,
      default: undefined,
    },
    adId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const dates = DefaultAggregateStatsDates(this)
    return {
      datesHaveChanged: false,
      datePickerDates: [
        apiDateToJsDate(dates.dateBegin),
        apiDateToJsDate(dates.dateEnd),
      ] as DatePickerDate,
      ...dates,
    }
  },
  computed: {
    lastStatsDate(): Date {
      return new Date()
    },
    firstStatsDate(): Date {
      return new Date('2000-01-01')
    },
    isDefaultDates(): boolean {
      return ValuesMatch(
        {
          dateBegin: this.dateBegin,
          dateEnd: this.dateEnd,
        } as DateParams,
        DefaultAggregateStatsDates()
      )
    },
    defaultDates(): DatePickerDate {
      const dates = DefaultAggregateStatsDates()
      return apiDatesToDatePickerDate(dates.dateBegin, dates.dateEnd)
    },
    locationStats(): LocationStats | undefined {
      return this.stats?.locationStats
    },
    showLocStats(): boolean {
      return this.locationStats
        ? !!Object.keys(this.locationStats).length
        : false
    },
    allAds(): boolean {
      return !this.individualAd
    },
    individualAd(): boolean {
      return !!this.adId
    },
    broadcasterID(): string | undefined {
      return this.broadcaster?.id
    },
    broadcaster(): Broadcaster | undefined {
      const broadcaster = this.$store.getters['broadcasters/promoBroadcaster']
      return broadcaster ? new Broadcaster(broadcaster) : undefined
    },
    stats(): AdStatsAggregateApi | undefined {
      return this.$store.getters['promo/aggregateStats']
    },
    startDate(): Date {
      return datePickerStartDate(this.datePickerDates) as Date
    },
    endDate(): Date {
      return datePickerEndDate(this.datePickerDates) as Date
    },
    datePickerDatesString(): string | undefined {
      if (this.individualAd) return undefined
      return localizeDatePickerDate(this.datePickerDates, DateFormat.Date)
    },
    totalImpressions(): string {
      if (!this.stats) return ''
      return this.stats.totalImpressions.toLocaleString()
    },
    totalClicks(): string {
      if (!this.stats) return ''
      return this.stats.totalClicks.toLocaleString()
    },
    showSources(): boolean {
      return (
        !!this.stats?.deviceTypes.desktop ||
        !!this.stats?.deviceTypes.mobile ||
        !!this.stats?.deviceTypes.tablet
      )
    },
    trafficSources(): CountData[] {
      if (!this.stats || !this.showSources) return []

      return [
        {
          count: this.stats.deviceTypes.desktop ?? 0,
          name: this.$t('Web'),
          subtitle: this.$t('SermonAudio 2.0, SermonAudio Legacy'),
        },
        {
          count: this.stats.deviceTypes.mobile ?? 0,
          name: this.$t('Mobile Apps'),
          subtitle: this.$t('Sermons 2 App on iOS & Android'),
        },
        {
          count: this.stats.deviceTypes.tablet ?? 0,
          name: this.$t('Tablet'),
          subtitle: this.$t('iPad and Android tablets'),
        },
      ]
    },
    allStatsLink(): string {
      return promotionUrl(
        this.adType,
        AdPage.AllStats,
        undefined,
        this.dateParams
      )
    },
    dateParams(): DateParams {
      return {
        dateBegin: this.dateBegin,
        dateEnd: this.dateEnd,
      }
    },
    recentActivityString(): string {
      return GetAdTypeStatRangeTitle(this, this.adType, this.dateParams)
    },
    showOverview(): boolean {
      return (
        !this.stats || !!this.stats.totalImpressions || !!this.stats.totalClicks
      )
    },
    adsBeforeMessage(): TranslateResult {
      return this.$t('Ad stats are only available beginning {date}', {
        date: localizeDateTime(new Date(2024, 3, 25), DateFormat.Date),
      })
    },
  },
  watch: {
    async broadcasterID() {
      await this.getStats()
    },
  },
  methods: {
    resetDates() {
      this.datePickerDates = this.defaultDates
      this.setDates(this.defaultDates as Date[])
    },
    applyDates() {
      this.setDates(this.datePickerDates as Date[])
    },
    setDates(dates: Date[]) {
      this.dateBegin = jsDateToApiDateStr(dates[0])
      this.dateEnd = jsDateToApiDateStr(dates[1])
      this.datesHaveChanged = false
      updateQs(this, [
        {
          key: AdStatsDateBeginKey,
          value: this.isDefaultDates ? undefined : this.dateBegin,
        },
        {
          key: AdStatsDateEndKey,
          value: this.isDefaultDates ? undefined : this.dateEnd,
        },
      ])
      this.getStats()
    },
    async getStats() {
      const options = this.allAds
        ? {
            broadcasterID: this.broadcasterID,
            dateBegin: this.dateBegin,
            dateEnd: this.dateEnd,
            adType: GetAdTypeApiList(this.adType),
          }
        : {
            campaignID: this.adId,
          }
      await this.$store.dispatch('promo/fetchAdStatsAggregate', options)
    },
  },
})
