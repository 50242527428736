import { Context } from '@nuxt/types'
import { PromiseAll } from '~/assets/ts/utils/misc'

export default async function ({ store, query, $apiClient }: Context) {
  const [signupInfo, couponInfo] = await PromiseAll([
    $apiClient.getSignupInfo(),
    $apiClient.getSignupCouponInfo(query.coupon as string | undefined),
  ])
  store.commit('broadcastersignup/ADD_BROADCASTER_SIGNUP_INFO', signupInfo)
  store.commit('broadcastersignup/ADD_SIGNUP_COUPON_INFO', couponInfo)
}
