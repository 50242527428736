import { Context } from '@nuxt/types'
import { redirectDisabledBroadcaster } from '~/assets/ts/utils/urls'
import { EventTypeApi } from '~/apiclient/apifilters'

export default async function ({ params, store, error }: Context) {
  const categoryID = params.category
  let valid = true
  if (categoryID) {
    await store.dispatch('filters/getEvents')
    const category = store.getters['filters/events'].find(
      (cat: EventTypeApi) => cat.description === categoryID
    )
    valid = !!category
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: 'Category not found',
    })
  }
}
