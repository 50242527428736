
import Vue from 'vue'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'SaLinkOrHref',
  props: {
    url: {
      type: String,
      default: undefined,
    },
    isTo: {
      type: Boolean,
    },
    noTab: {
      type: Boolean,
    },
    button: {
      type: Boolean,
    },
  },
  computed: {
    SaLink() {
      return SaLink
    },
    props(): Record<string, any> {
      if (this.isTo) {
        return {
          to: this.url,
          button: this.button,
        }
      }
      return {
        href: this.url,
        target: this.noTab ? undefined : '_blank',
      }
    },
  },
})
