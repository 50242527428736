import { ApiClient } from '~/apiclient/apiclient'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'

export interface apiSignupInfo {
  monthlyFee: number
  signupFee: number
  saleSignupFee: number
  listenLineFee: number
  saleInProgress: boolean
  /** API Timestamp */
  saleEndDate: number
}

export async function getSignupInfo(this: ApiClient): Promise<apiSignupInfo> {
  const { data } = await this.$axios.get('signup_params')
  return data
}

interface apiBroadcasterIDAvailable {
  broadcasterID: string
  isAvailable: boolean
}

export async function getBroadcasterIDAvailability(
  this: ApiClient,
  broadcasterID: string
): Promise<boolean> {
  const { data } = await this.$axios.get(
    `node/broadcasters/${broadcasterID}/available`
  )
  return (data as apiBroadcasterIDAvailable).isAvailable
}

export type ApiCardBackendType =
  | 'authorize_net'
  | 'check'
  | 'cornerstone'
  | 'stripe'

export type ApiPaymentType = 'cc' | 'ach'

export interface CreateBroadcasterMain {
  /** This is the broadcaster display name */
  description: string
  /**
   * This is not used in the create endpoint.
   * It is used in a patch following the create call.
   */
  shortName?: string
  acceptArticlesofFaith: boolean
  broadcasterID: string
  /** @deprecated */
  password?: string
}

export interface CreateBroadcasterInfo {
  emailPublic?: string
  homePage?: string
  isChurch: boolean
  isSchool: boolean
  isSeminary: boolean
  isRadioProgram: boolean
  /** @deprecated */
  minister?: string
  /**
   * This is not used in the create endpoint.
   * It is used in a patch following the create call.
   */
  primarySpeakers?: number[]
}

export interface CreateBroadcasterLocation {
  address: string
  countryCode: string
  location: string
  /** @deprecated */
  zipcode?: string
  /**
   * This is not used in the create endpoint.
   * It is used in a patch following the create call.
   */
  languageCode: string
}

export interface CreateBroadcasterContact {
  emailEncoding?: string
  /** This is the billing email */
  emailMain: string
  phone?: string
}

export interface CreateBroadcasterMore {
  /** This is used as a survey field */
  referralMethod?: string
}

export interface CreateBroadcasterBilling {
  cardBackend?: ApiCardBackendType
  cardCustomerID?: string
  cardFirstName?: string
  cardLastName?: string
  checkName?: string
  paymentID?: string
  paymentType: ApiPaymentType
}

export interface CreateBroadcasterParams
  extends CreateBroadcasterMain,
    CreateBroadcasterInfo,
    CreateBroadcasterLocation,
    CreateBroadcasterContact,
    CreateBroadcasterMore,
    CreateBroadcasterBilling {
  couponID?: string
  emailPastor?: string
  emailOrders?: string
  emailStore?: string
  groupID?: string
  salesRep?: string
}

export async function createBroadcaster(
  this: ApiClient,
  params: CreateBroadcasterParams
): Promise<BroadcasterApi> {
  const { data } = await this.$axios.post('node/broadcasters/', params)
  return data
}
