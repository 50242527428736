
import Vue from 'vue'
import CustomScroll from '~/components/_general/CustomScroll.vue'
import FocusVisibleElement from '~/components/_general/FocusVisibleElement.vue'

export const CustomDropdownProps = {
  clickable: {
    type: Boolean,
    default: true,
  },
  animate: {
    type: Boolean,
  },
  up: {
    type: Boolean,
  },
  contentClasses: {
    type: String,
    default: '',
  },
  targetClasses: {
    type: String,
    default: '',
  },
  useTheme: {
    type: Boolean,
    default: true,
  },
  checkFocus: {
    type: Boolean,
  },
  left: {
    type: Boolean,
  },
  hover: {
    type: Boolean,
  },
  clickaway: {
    type: Boolean,
    default: true,
  },
  scrollaway: {
    type: Boolean,
    default: true,
  },
}

export default Vue.extend({
  name: 'CustomDropdown',
  components: { CustomScroll },
  inheritAttrs: false,
  props: {
    ...CustomDropdownProps,
  },
  data() {
    return {
      isActive: false,
      opened: false,
      leftPos: 0,
      contentHover: false,
    }
  },
  computed: {
    leftSidebar(): number {
      return this.$store.getters['site/navOpen'] ? 240 : 0
    },
    componentType(): Record<any, any> | string {
      return this.checkFocus ? FocusVisibleElement : 'button'
    },
    dropdown(): HTMLElement | undefined {
      const d = this.$refs.dropdown as Vue | undefined
      return d?.$el as HTMLElement | undefined
    },
    container(): HTMLElement {
      return this.$refs.container as HTMLElement
    },
    leftAlign(): boolean {
      return this.left || this.leftPos < this.leftSidebar
    },
    clickableTargetClasses(): string {
      let classes = this.clickable ? 'cursor-pointer' : 'pointer-events-none'
      if (this.targetClasses) {
        classes += ' ' + this.targetClasses
      }
      return classes
    },
    useHover(): boolean {
      return this.hover && !this.$device.isMobileOrTablet
    },
  },
  destroyed() {
    this.$off('close', this.closeDropdown)
    this.$off('open', this.openDropdown)
    this.$off('scrollTop', this.scrollTop)
    this.$eventBus.$off('closeDropdowns', this.closeDropdown)
    if (!this.scrollaway) return
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted() {
    this.$on('close', this.closeDropdown)
    this.$on('open', this.openDropdown)
    this.$on('scrollTop', this.scrollTop)
    this.$eventBus.$on('closeDropdowns', this.closeDropdown)
    if (!this.scrollaway) return
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    scrollTop() {
      if (!this.dropdown) return
      this.dropdown.scrollTop = 0
    },
    onScroll(_event: Event) {
      if (this.$device.isMobileOrTablet) return
      if (!this.opened) return
      if (this.contentHover) return
      this.closeDropdown()
    },
    openDropdown() {
      if (this.isActive) return
      this.leftPos = 0
      this.opened = true
      // Timeout is to keep the clickaway event from interfering
      setTimeout(() => {
        this.$emit('opened')
        this.isActive = true
        if (this.dropdown) {
          const left = this.dropdown.getBoundingClientRect().left
          const offset = this.container.clientWidth
          this.leftPos = left - offset
        }
      }, 1)
    },
    click() {
      if (this.useHover) return
      this.openDropdown()
    },
    closeDropdown() {
      if (!this.isActive) return
      this.isActive = false
      this.$emit('closed')
    },
    closeEvent() {
      if (this.useHover) return
      this.closeDropdown()
    },
    clickawayEvent() {
      if (!this.clickaway) return
      this.closeEvent()
    },
    hoverEnter() {
      if (!this.useHover) return
      this.openDropdown()
    },
    hoverExit() {
      if (!this.useHover) return
      this.closeDropdown()
    },
  },
})
