import { DateFormat } from '~/assets/ts/enums'
import { HSLA } from '~/assets/ts/utils/color'
import { Broadcaster } from '~/models/broadcaster'
import { boolOrUndefined, resizableUrl } from '~/assets/ts/utils/misc'
import { localizeDateTime } from '~/assets/ts/utils/date'
import { FeedLinkApi } from '~/apiclient/apiseries'

export type PodcastType =
  | 'apple'
  | 'spotify'
  | 'google-play'
  | 'tunein'
  | 'other'
  | 'stitcher'

export class Series {
  id: number
  broadcaster: Broadcaster
  broadcasterID: string
  count: number
  description?: string
  earliest?: Date
  feedLinks: FeedLinkApi[]
  imageUrl?: string
  imageResizableUrl?: string
  latest?: Date
  isPodcast: boolean
  podcastSpeaker?: string
  rssAtomUrl: string
  rssUrl: string
  title: string
  updated: Date
  following?: boolean
  lite: boolean
  defaultSortBy?: string

  constructor(series: Record<string, any>) {
    this.id = series.seriesID
    this.lite = series.type && series.type === 'lite_sermon_series'
    this.broadcasterID = series.broadcasterID
    this.broadcaster = new Broadcaster(series.broadcaster)
    this.count = series.count
    this.description = series.description || undefined
    this.earliest = series.earliest ? new Date(series.earliest) : undefined
    this.latest = series.latest ? new Date(series.latest) : undefined
    this.imageUrl = series.image
    this.imageResizableUrl = series.imageResizable
    this.isPodcast = series.podcastEnabled
    this.podcastSpeaker = series.podcastSpeaker || undefined
    this.rssAtomUrl = series.rssAtomURL
    this.rssUrl = series.rssURL
    this.title = series.title
    this.updated = new Date(series.updated * 1000)
    this.following = boolOrUndefined(series.followed)
    this.defaultSortBy = series.defaultSortBy

    const feedLinks = []
    if (series.feedLinks) {
      for (let i = 0; i < series.feedLinks.length; i++) {
        feedLinks.push(series.feedLinks[i])
      }
    }
    this.feedLinks = feedLinks
  }

  get dateRange(): string | undefined {
    if (!this.earliest) return
    const earliest = localizeDateTime(this.earliest, DateFormat.ShortDate)
    if (!this.latest || this.earliest.getTime() === this.latest.getTime())
      return earliest
    const latest = localizeDateTime(this.latest, DateFormat.ShortDate)
    return `${earliest} - ${latest}`
  }

  albumArt(size: number): string | undefined {
    if (!this.imageResizableUrl) return undefined
    return resizableUrl(this.imageResizableUrl, size, size)
  }

  get bgColor(): string {
    return new HSLA({
      hue: (this.id * 30) % 360,
      alpha: 0.5,
      saturation: 40,
      lightness: 70,
    }).css
  }

  get shareTitle(): string {
    return this.title
  }

  matchesSearchTerm(searchTerm: string): boolean {
    const search = searchTerm.toLowerCase()
    return (
      this.title.toLowerCase().includes(search) ||
      this.broadcaster.matchesSearchTerm(search)
    )
  }

  get SoloUrl(): string {
    const dir: string = this.isPodcast ? 'podcasts/' : 'sermons/series/'
    return `${this.broadcaster.SoloUrl}${dir}${this.id}/`
  }
}
