import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { logError } from '~/assets/ts/utils/misc'
import { ArticleApi, ArticleCategoryApi } from '~/apiclient/apiarticles'

export const state = () => ({
  article: undefined as ArticleApi | undefined,
  articleCategories: undefined as ArticleCategoryApi[] | undefined,
  articleCategoryFilterId: '' as string | number,
  hasPinnedArticles: {} as Record<string, boolean>,
})

export type ArticlesState = ReturnType<typeof state>

export const mutations: MutationTree<ArticlesState> = {
  ADD_ARTICLE: (state, article: ArticleApi | undefined) => {
    state.article = article
  },
  SET_ARTICLE_CATEGORIES: (state, articleCategories: ArticleCategoryApi[]) => {
    state.articleCategories = articleCategories
  },
  SET_ARTICLE_CATEGORY_FILTER: (state, id: string | number) => {
    state.articleCategoryFilterId = id
  },
  ADD_HAS_PINNED_ARTICLES: (
    state,
    pinned: Record<'broadcasterID' | 'pinned', boolean | string>
  ) => {
    Vue.set(
      state.hasPinnedArticles,
      pinned.broadcasterID as string,
      pinned.pinned
    )
  },
}

export const getters: GetterTree<ArticlesState, ArticlesState> = {
  article: (state) => state.article,
  articleCategories: (state) => state.articleCategories,
  hasPinnedArticles: (state) => (broadcasterID: string) =>
    state.hasPinnedArticles[broadcasterID],
}

export const actions: ActionTree<ArticlesState, ArticlesState> = {
  async fetchArticle({ commit }, { articleID, broadcasterID, slug }) {
    try {
      const article = await this.$apiClient.getArticle({
        articleID,
        broadcasterID,
        slug,
      })
      commit('ADD_ARTICLE', article)
    } catch (e) {
      logError(e)
    }
  },
  async fetchArticleCategories(
    { commit, state },
    { broadcasterID, speakerID }
  ) {
    const id = state.articleCategoryFilterId
    if ([broadcasterID, speakerID].includes(id)) return
    commit('SET_ARTICLE_CATEGORIES', undefined)
    commit('SET_ARTICLE_CATEGORY_FILTER', '')
    try {
      const { results } = await this.$apiClient.getArticleCategories({
        broadcasterID,
        speakerID,
      })
      commit('SET_ARTICLE_CATEGORIES', results)
      commit('SET_ARTICLE_CATEGORY_FILTER', broadcasterID || speakerID)
    } catch (e) {
      logError(e)
    }
  },
}
