
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import {
  mixColorsRGB,
  SiteGraphColorA,
  SiteGraphColorB,
} from '~/assets/ts/utils/color'

export interface CountData {
  count: number
  name: TranslateResult
  subtitle?: TranslateResult
}

export interface PercentData extends CountData {
  percent: number
}

export default Vue.extend({
  name: 'GraphBar',
  props: {
    dataPoints: {
      type: [] as PropType<CountData[]>,
      default: [] as CountData[],
    },
  },
  computed: {
    dataTotalCount(): number {
      let total = 0
      for (const d in this.dataPoints) {
        total += this.dataPoints[d].count
      }
      return total
    },
    sortedData(): PercentData[] {
      // Get rounded numbers and remainders for total accuracy
      const rounded = [] as PercentData[]
      const remainders = []

      for (const d in this.dataPoints) {
        const p = (this.dataPoints[d].count / this.dataTotalCount) * 100
        const r = Math.floor(p)
        rounded.push({
          ...this.dataPoints[d],
          percent: r,
        })
        remainders.push(p - r)
      }

      // Sort list and add to rounded
      const remaindersUnsorted = [...remainders]
      remainders.sort((a, b) => {
        return b - a
      })

      for (let i = 0; i < remainders.length; i++) {
        let sum = 0
        for (const r in rounded) {
          sum += rounded[r].percent
        }
        if (sum === 100) break
        // Add extra if sum is not 100
        const index = remaindersUnsorted.indexOf(remainders[i])
        rounded[index].percent += 1
      }

      return rounded
    },
  },
  methods: {
    colorStyle(index: number): string {
      if (!this.sortedData) return ''
      const count = this.sortedData.length
      const percent = count - 1 ? index / (count - 1) : 0
      const rgb = mixColorsRGB(SiteGraphColorA, SiteGraphColorB, percent)
      return `background-color: ${rgb};`
    },
    widthStyle(data: PercentData): string {
      return `width:${data.percent}%;`
    },
    borderClass(index: number): string {
      if (!this.sortedData) return ''
      return index !== this.sortedData.length - 1
        ? 'border-r-2 border-bg-theme'
        : ''
    },
  },
})
