import { ApiClient } from '~/apiclient/apiclient'

interface MapPath {
  url: string
  internal: boolean
  status: 301 | 302
}

const newSiteHost = 'https://www.sermonaudio.com'

export async function mapPath(
  this: ApiClient,
  url: string
): Promise<MapPath | undefined> {
  try {
    const { data } = await this.$axios.post('map-path', {
      url,
    })
    const newUrl = data as string
    const internal = newUrl.includes(newSiteHost)
    return {
      // turn this into a relative url so that it works in all subdomains
      url: newUrl.replace(newSiteHost, ''),
      internal,
      // internal urls get a 301, everything else gets a 302
      status: internal ? 301 : 302,
    }
  } catch (_e) {
    return undefined
  }
}

export async function healthz(this: ApiClient): Promise<string> {
  const { data } = await this.$axios.get('healthz', {
    params: {
      noCache: true,
    },
  })
  return data
}
