
import Vue from 'vue'

export default Vue.extend({
  name: 'SiteLayoutDefault',
  props: {
    topPadding: {
      type: Boolean,
      default: true,
    },
    extraTopPadding: {
      type: Boolean,
      default: true,
    },
    bottomPadding: {
      type: Boolean,
      default: true,
    },
    smallBottomPadding: {
      type: Boolean,
    },
    maxWidth: {
      type: String,
      default: 'max-w-7xl',
      validator(value: string) {
        return value.includes('max-w-')
      },
    },
  },
})
