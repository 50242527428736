import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import {
  GetLocalStorage,
  SetLocalStorage,
} from '~/assets/ts/utils/localstorage'

const SettingsKey = 'video-wall.settings'

interface VideoWallSettings {
  sfxVolume: number
  previewVolume: number
  resetFrequency: number
  markerResetFrequency: number
  muted: boolean
  debug: boolean
  editor: boolean
  performanceMode: boolean
  clock: boolean
  baseZoom: number
  displayDuration: number
  webcastPerformanceThreshold: number
  persistentMarkersMax: number
  temporaryMarkersMax: number
  individualSfxVolume: Record<string, number>
}

const VideoWallDefaults = {
  sfxVolume: 100,
  previewVolume: 7,
  resetFrequency: 60,
  markerResetFrequency: 10,
  performanceMode: false,
  muted: false,
  debug: false,
  editor: false,
  clock: true,
  baseZoom: 3,
  displayDuration: 9,
  webcastPerformanceThreshold: 35,
  persistentMarkersMax: 1000,
  temporaryMarkersMax: 600,
  individualSfxVolume: {
    keyMetadataOpen: 0.5,
    keyMetadataClose: 0.5,
    keyPinOpen: 0.2,
    keySpeakerOpen: 0.5,
    sweepLong: 0.65,
    sweepShort: 0.65,
    whoosh: 1,
  } as Record<string, number>,
} as VideoWallSettings

export const state = () => ({
  settings: VideoWallDefaults,
  time: new Date(),
  /** The time at which the key item was last shown */
  keyChange: new Date(),
})

export type VideoWallState = ReturnType<typeof state>
function Set(state: VideoWallState) {
  const settings = { ...state.settings } as VideoWallSettings
  Vue.set(state, 'settings', settings)
  SetLocalStorage(SettingsKey, settings)
}

export const mutations: MutationTree<VideoWallState> = {
  SET_TIME: (state, time: Date) => {
    state.time = time
  },
  SET_KEY_CHANGE: (state, keyChange: Date) => {
    state.keyChange = keyChange
  },
  SET_SFX_VOLUME: (state, volume: number) => {
    state.settings.sfxVolume = volume
    Set(state)
  },
  SET_PREVIEW_VOLUME: (state, volume: number) => {
    state.settings.previewVolume = volume
    Set(state)
  },
  SET_MUTED: (state, muted: boolean) => {
    state.settings.muted = muted
    Set(state)
  },
  SET_DEBUG: (state, debug: boolean) => {
    state.settings.debug = debug
    Set(state)
  },
  SET_CLOCK: (state, clock: boolean) => {
    state.settings.clock = clock
    Set(state)
  },
  SET_EDITOR: (state, editor: boolean) => {
    state.settings.editor = editor
    Set(state)
  },
  SET_PERFORMANCE_MODE: (state, performanceMode: boolean) => {
    state.settings.performanceMode = performanceMode
    Set(state)
  },
  SET_BASE_ZOOM: (state, zoom: number) => {
    state.settings.baseZoom = zoom
    Set(state)
  },
  SET_WEBCAST_PERFORMANCE_THRESHOLD: (state, count: number) => {
    state.settings.webcastPerformanceThreshold = count
    Set(state)
  },
  SET_PERSISTENT_MARKERS_MAX: (state, max: number) => {
    state.settings.persistentMarkersMax = max
    Set(state)
  },
  SET_TEMPORARY_MARKERS_MAX: (state, max: number) => {
    state.settings.temporaryMarkersMax = max
    Set(state)
  },
  SET_DISPLAY_DURATION: (state, duration: number) => {
    state.settings.displayDuration = duration
    Set(state)
  },
  SET_RESET_FREQUENCY: (state, frequency: number) => {
    state.settings.resetFrequency = frequency
    Set(state)
  },
  SET_MARKER_RESET_FREQUENCY: (state, frequency: number) => {
    state.settings.markerResetFrequency = frequency
    Set(state)
  },
  SET_INDIVIDUAL_SFX_VOLUME: (
    state,
    options: Record<'sfx' | 'volume', string | number>
  ) => {
    Vue.set(state.settings.individualSfxVolume, options.sfx, options.volume)
    Set(state)
  },
  INITIALIZE: (state) => {
    state.settings = GetLocalStorage(SettingsKey, VideoWallDefaults)
  },
}

export const getters: GetterTree<VideoWallState, VideoWallState> = {
  sfxVolume: (state) => state.settings.sfxVolume,
  previewVolume: (state) => state.settings.previewVolume,
  muted: (state) => state.settings.muted,
  debug: (state) => state.settings.debug,
  clock: (state) => state.settings.clock,
  baseZoom: (state) => state.settings.baseZoom,
  editor: (state) => state.settings.editor,
  performanceMode: (state) => state.settings.performanceMode,
  displayDuration: (state) => state.settings.displayDuration,
  resetFrequency: (state) => state.settings.resetFrequency,
  markerResetFrequency: (state) => state.settings.markerResetFrequency,
  individualSfxVolume: (state) => (sfx: string) =>
    state.settings.individualSfxVolume[sfx],
  individualSfxList: (state) => Object.keys(state.settings.individualSfxVolume),
  webcastPerformanceThreshold: (state) =>
    state.settings.webcastPerformanceThreshold,
  persistentMarkersMax: (state) => state.settings.persistentMarkersMax,
  temporaryMarkersMax: (state) => state.settings.temporaryMarkersMax,
  time: (state) => state.time,
  keyChange: (state) => state.keyChange,
}

export const actions: ActionTree<VideoWallState, VideoWallState> = {
  nuxtClientInit({ commit }) {
    commit('INITIALIZE')
  },
}
