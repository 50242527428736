
import Vue, { PropType } from 'vue'
import { GetIconClass } from '~/assets/ts/icons'
import { SaIconsType } from '~/assets/ts/types/icons'

export default Vue.extend({
  name: 'SaIcon',
  props: {
    name: {
      type: String as PropType<SaIconsType>,
      default: 'error',
    },
    useWidth: {
      type: Boolean,
    },
  },
  computed: {
    filename(): string {
      const base = GetIconClass(this.name as SaIconsType)
      return `${base}${this.useWidth ? ' scale-width' : ''}`
    },
  },
})
