// From https://typescript.nuxtjs.org/cookbook/plugins/

import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { ApiClient } from '~/apiclient/apiclient'

declare module 'vue/types/vue' {
  // this.$apiClient inside Vue components
  interface Vue {
    $apiClient: ApiClient
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$apiClient inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $apiClient: ApiClient
  }
  // nuxtContext.$apiclient
  interface Context {
    $apiClient: ApiClient
  }
}

declare module 'vuex/types/index' {
  // this.$apiClient inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $apiClient: ApiClient
  }
}

export default (context: Context, inject: Inject) => {
  inject('apiClient', new ApiClient({ $context: context }))
}
