import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { SermonApi } from '~/apiclient/apisermons'

export interface HymnResultsApi {
  hymnID: string
  title: string
  lyricist: string
  favorite: boolean
  composer: string
  hasAudio: boolean
  hasHistory: boolean
  psalter: boolean
}

export interface TuneApi {
  composer: string
  description: string
  image: string
  meter: string
  sound: string
  tuneID: string
  metricID: string
}

export interface HymnApi {
  bibleReference: string
  bibleVerse: string
  composer: string
  displayCopyrightHolder?: string
  displayCopyrightLink?: string
  favorite: boolean
  history: string
  hymnID: string
  lyricist: string
  metricalPsalm?: string
  psalter: boolean
  stanzas: number
  title: string
  topics: string
  tunes: TuneApi[]
  verses: string
  hasAudio: boolean
  hasHistory: boolean
  readingSermon: SermonApi
}

export class HymnOptions {
  page?: number
  pageSize?: number
  excludePsalter?: boolean
  psalterOnly?: boolean
  favoriteOnly?: boolean
  tuneID?: string
  author?: string
  searchKeywords?: string
  exactSearchMatch?: boolean
  bibleReferenceKeywords?: string
  titleStartsWithLetter?: string
}

export async function getHymn(
  this: ApiClient,
  hymnID: string
): Promise<HymnApi> {
  const { data } = await this.$axios.get(`node/discover/hymns/${hymnID}`)
  return data
}

export async function getHymns(
  this: ApiClient,
  options: Partial<HymnOptions> = {}
): Promise<ApiPaginationResult<HymnResultsApi>> {
  const { data } = await this.$axios.get('node/discover/hymns', {
    params: options,
  })

  return data
}

export async function getTunesByMetric(
  this: ApiClient,
  metricID: string,
  psalterOnly = false
): Promise<ApiPaginationResult<TuneApi>> {
  const { data } = await this.$axios.get(
    `node/discover/hymns/metric/${metricID}/tunes`,
    {
      params: {
        psalter: psalterOnly,
        audioOnly: true,
      },
    }
  )

  return data
}

export async function getPrimaryPsalterTunes(
  this: ApiClient
): Promise<ApiPaginationResult<TuneApi>> {
  const { data } = await this.$axios.get(`node/discover/hymns/psalter/tunes`)

  return data
}

export async function getPsalmsFromTune(
  this: ApiClient,
  tuneID: string
): Promise<ApiPaginationResult<HymnResultsApi>> {
  const { data } = await this.$axios.get(
    `node/discover/hymns/tune/${tuneID}/psalms`
  )

  return data
}
