
import Vue, { PropType } from 'vue'
import { Speaker } from '~/models/speaker'
import SaImage, { LoadingTypes } from '~/components/_general/SaImage.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SpeakerImage',
  components: { SaIcon, SaImage },
  props: {
    size: {
      type: Number,
      default: 64,
    },
    loading: {
      type: String as PropType<LoadingTypes>,
      default: 'lazy',
    },
    speaker: {
      type: Object as PropType<Speaker>,
      default: undefined,
    },
  },
  computed: {
    generic(): boolean {
      return !this.speaker || this.speaker.genericThumbnail
    },
    url(): string {
      if (!this.speaker) return ''
      return this.speaker.resizableImage(this.size) ?? ''
    },
  },
  mounted() {
    this.checkGeneric()
  },
  methods: {
    checkGeneric() {
      if (this.generic) {
        this.loaded()
      }
    },
    loaded() {
      this.$emit('load')
    },
  },
})
