
import Vue from 'vue'
import { AdTrackerUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'SiteAdTrackerImage',
  props: {
    campaignID: {
      type: String,
      default: '',
    },
  },
  computed: {
    adTrackerView(): string {
      return AdTrackerUrl(this.campaignID)
    },
  },
})
