
import Vue, { PropType } from 'vue'
import { BatchActionListItem } from '~/assets/ts/utils/site'
import SaIcon from '~/components/_general/SaIcon.vue'
import CustomScroll from '~/components/_general/CustomScroll.vue'
import Spinner from '~/components/_general/Spinner.vue'

export default Vue.extend({
  name: 'DashboardBatchModal',
  components: { Spinner, CustomScroll, SaIcon },
  props: {
    list: {
      type: Array as PropType<BatchActionListItem[]>,
      required: true,
    },
    running: {
      type: Boolean,
    },
    statuses: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  computed: {
    completed(): number {
      return this.statuses?.filter((i) => i > 1)?.length ?? 0
    },
    percentage(): number {
      return (this.completed / this.statuses?.length ?? 0) * 100
    },
  },
  watch: {
    confirmed() {
      alert('Confirmed!')
    },
  },
  methods: {
    isRunning(index: number) {
      return this.statuses[index] === 0
    },
    isError(index: number) {
      return this.statuses[index] === 1
    },
    isSuccess(index: number) {
      return this.statuses[index] === 2
    },
    classColor(index: number) {
      if (this.isSuccess(index)) return 'text-theme-green'
      if (this.isError(index)) return 'text-theme-red'
      return ''
    },
  },
})
