
import Vue from 'vue'

export default Vue.extend({
  name: 'BackgroundImage',
  props: {
    src: {
      type: String,
      default: undefined,
    },
    internal: {
      type: Boolean,
    },
    repeat: {
      type: Boolean,
    },
    aspectRatio: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    aspectRatioPadding(): string {
      if (!this.aspectRatio) return ''
      return `padding-bottom: ${this.aspectRatio * 100}%;`
    },
    url(): string {
      if (!this.internal) return this.src
      return require(`@/assets/images/${this.src}`)
    },
  },
})
