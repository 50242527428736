import { waitOneFrame } from '~/assets/ts/utils/misc'

/**
 * These are pages that seemingly aren't related, but are actually shared tabs
 * @example Hymnal & Psalter or News & Release Notes
 * */
const pageTabSet = [
  ['/hymnal', '/psalter'],
  ['/news', '/release-notes'],
]

function pagesAreInSet(to, from) {
  return !!pageTabSet.find(
    (set) =>
      set.some((item) => to.path.endsWith(item)) &&
      set.some((item) => from.path.endsWith(item))
  )
}

function getParent(route) {
  if (route.matched.length < 2) return undefined
  return route.matched.find((m) => m.parent !== undefined)?.parent
}

/** These are pages with a shared parent like the broadcaster home page */
function sameParent(to, from) {
  const toParent = getParent(to)?.path
  if (!toParent) return false
  const fromParent = getParent(from)?.path
  return toParent === fromParent
}

/** These are tabs on the same page */
function samePage(to, from) {
  if (to.name !== from.name) return false
  // This is the same route, so we need to check for parameters
  const params = Object.keys(to.params)
  // Identical pages, just with a query string change or something
  if (!params.length) return true

  const match = params.every((p) => to.params[p] === from.params[p])
  return match
}

function skipScroll(to, from) {
  if (samePage(to, from)) return true
  if (sameParent(to, from)) return true
  return pagesAreInSet(to, from)
}

// https://v3.router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
export default async function (to, from, savedPosition) {
  await waitOneFrame()

  // we're going back, so restore the saved position
  if (savedPosition) {
    return savedPosition
  }
  // smooth scroll to our hash
  if (to.hash) {
    const el = document.querySelector(to.hash)
    if (el) {
      const offset = el.dataset.hashOffset ? parseInt(el.dataset.hashOffset) : 0
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: { x: 0, y: offset },
      }
    }
  }

  // TODO: look into if there's a meta condition we can provide instead
  // https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
  // skip scroll top when clicking on a page tab
  if (skipScroll(to, from)) return false

  // iOS safari has a bug in Single Tab mode that causes the page to frequently
  // not scroll completely to the top
  // Adding a short delay and a negative offset fixes it, but is a subpar solution
  // if (window.$nuxt.$device.isIos) {
  //   await wait(150)
  //   return { x: 0, y: -50 }
  // }

  return { x: 0, y: 0 } // Go to the top of the page
}
