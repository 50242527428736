import {
  apiBroadcasterRoles,
  BroadcasterRoles,
  Roles,
} from '~/models/users/roles'
import { apiTimestampToJsDate } from '~/assets/ts/utils/date'

/** Message and Tutorial IDs */
export enum UserMessages {
  Welcome = 'welcome',
  LegacyDropdown = 'legacy',
}

export interface WebPreferences {
  /** Timestamp the preferences were last updated */
  t: number
  /** Array of message and tutorial IDs that user has seen/dismissed */
  shown?: UserMessages[]
}

export function GetDefaultWebPreferences(): WebPreferences {
  return {
    t: new Date().getTime(),
    shown: [],
  }
}

export class User {
  birthYear: number
  churchLeader: boolean
  disabled: boolean
  displayName?: string
  displayLocation?: string
  email: string
  gender?: string
  id: number
  lastLogin: Date
  newsletter: boolean
  notifyEmail: boolean
  notifyMobile: boolean
  password?: string
  profilePictureUrl?: string
  roles?: Roles
  signupDate: Date
  username: string
  verified: boolean
  broadcasterRoles: apiBroadcasterRoles[]
  myChurchBroadcasterID?: string
  webPreferences: string | null

  constructor(user: any) {
    this.birthYear = user.birth_year ? user.birth_year : 0
    this.churchLeader = user.church_leader
    this.newsletter = user.newsletter_opt_in
    this.signupDate = new Date(parseInt(user.date_added) * 1000)
    this.disabled = user.disabled
    this.displayName = user.display_name || undefined
    this.displayLocation = user.display_location || undefined
    this.email = user.email
    this.gender = user.gender || undefined
    this.lastLogin = apiTimestampToJsDate(user.last_login)
    this.notifyEmail = user.notify_email
    this.notifyMobile = user.notify_mobile
    this.profilePictureUrl = user.profile_picture || undefined
    this.id = user.user_id
    this.username = user.username
    this.verified = user.verified
    this.webPreferences = user.web_preferences
    this.roles =
      user.roles && user.roles.length ? new Roles(user.roles) : undefined
    this.broadcasterRoles = user.broadcasterRoles || []
    this.myChurchBroadcasterID = user.myChurchBroadcasterID
  }

  get admin(): boolean {
    return this.roles ? this.roles.Admin : false
  }

  get isBroadcaster(): boolean {
    return !!this.broadcasterRoles.length
  }

  broadcasterRolesForID(broadcasterID: string): BroadcasterRoles[] {
    if (!this.isBroadcaster) return []
    return this.broadcasterRoles
      .filter((b) => b.broadcasterID === broadcasterID)
      .map((b) => b.broadcasterRoleID)
  }

  canEditBroadcaster(broadcasterID: string, role: BroadcasterRoles): boolean {
    const roles = this.broadcasterRolesForID(broadcasterID)
    if (roles.includes(BroadcasterRoles.Admin)) return true
    return roles.includes(role)
  }

  get isBroadcasterAdmin(): boolean {
    return !!this.broadcasterAdminIds.length
  }

  get broadcasterAdminIds(): string[] {
    return this.getBroadcasterRoles([BroadcasterRoles.Admin]).map(
      (r) => r.broadcasterID
    )
  }

  getBroadcasterRoles(roles: BroadcasterRoles[]): apiBroadcasterRoles[] {
    return this.broadcasterRoles.filter((r) =>
      roles.includes(r.broadcasterRoleID)
    )
  }

  get broadcasterID(): string {
    if (this.broadcasterRoles.length !== 1) return ''
    return this.broadcasterRoles[0].broadcasterID
  }

  get videoWall(): boolean {
    return this.roles ? this.roles.VideoWall : false
  }

  get translator(): boolean {
    return this.roles ? this.roles.Translator : false
  }

  get DisplayName(): string {
    return this.displayName || this.username
  }

  get raw(): Record<any, any> {
    return {
      username: this.username,
      password: this.password,
      email: this.email,
      display_name: this.displayName,
      display_location: this.displayLocation,
      profile_picture: this.profilePictureUrl,
      church_leader: this.churchLeader,
      gender: this.gender,
      newsletter_opt_in: this.newsletter,
      birth_year: this.birthYear || 0,
      notify_email: this.notifyEmail,
      notify_mobile: this.notifyMobile,
      my_church_broadcaster_id: this.myChurchBroadcasterID,
      web_preferences: this.preferences
        ? JSON.stringify(this.preferences)
        : null,
    }
  }

  get preferences(): WebPreferences | undefined {
    if (!this.webPreferences) return undefined
    return JSON.parse(this.webPreferences)
  }

  ProfilePicture(size: number): string | undefined {
    if (!this.profilePictureUrl) return undefined
    return `https://vps.sermonaudio.com/resize_image/images/user/${size}/${size}/${this.profilePictureUrl}?webp=true`
  }
}
