import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'

export interface TopicApi {
  type: 'topic'
  topicID: string
  displayTopic: string
  choice: boolean
  collectionSlug: string | null
}

export async function getTopics(
  this: ApiClient
): Promise<ApiPaginationResult<TopicApi>> {
  const { data } = await this.$axios.get('node/discover/topics')
  return data
}
