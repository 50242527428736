import { TranslateResult } from 'vue-i18n'

export enum DateFormat {
  Time,
  Date,
  DateTime,
  ShortDate,
  ShortDateTime,
  TinyDate,
  CompressedDate,
  MonthDayDate,
  DetailedTime,
  Year,
  Month,
  YearMonth,
  DateWithWeekday,
}

export enum SermonDisplay {
  /** Standard 2-line sermon listing */
  Standard,
  /** Standard 2-line sermon listing, but wraps content instead of truncating it */
  StandardWrap,
  /** Standard sermon listing, but adds a 3rd line for broadcaster and series info */
  /** Standard sermon listing, but specifically for two-column locations */
  Standard2Col,
  /** Standard sermon listing, but adds a 3rd line for broadcaster and series info */
  Extended,
  /** Standard wrapping sermon listing, but adds a 3rd line for broadcaster and series info */
  ExtendedWrap,
  /** Extended sermon, but with a large video thumbnail */
  ExtendedVideo,
  /** Featured sermon meant to be displayed larger, with more buttons, and more information */
  Featured,
  /** Video sermon meant to display prominent thumbnail with appropriate properties set */
  Video,
  /** Video sermon meant to display prominent thumbnail with appropriate properties set that allows wrapping */
  VideoWrap,
}

export enum SermonMetadataDisplay {
  /** Standard metadata shown on typical sermon listings */
  Standard,
  /** Standard metadata shown on typical sermon listings, but wraps content instead of truncating it */
  StandardWrap,
  /** Standard metadata shown on typical sermon listings plus added 3rd line for broadcaster/series/verse/event info */
  Extended,
  /** Standard wrapping metadata shown on typical sermon listings plus added 3rd line for broadcaster/series/verse/event info */
  ExtendedWrap,
  /** Like the extended wrap, but instead of only one value, it shows two values */
  Featured,
  /** Minimal info used for things like videos */
  Minimal,
  /** Minimal info plus added 3rd line for broadcaster and series info */
  MinimalExtended,
  /** Metadata to show for video thumbnail formats */
  Video,
  /** Metadata to show for video thumbnail formats and allows wrapping */
  VideoWrap,
}

export enum SermonMetadataExtendedInfo {
  Broadcaster,
  Series,
  Verse,
  Category,
  None,
}

export enum SeriesDisplay {
  /** Standard 2-line series listing */
  Standard,
  /** Standard series listing, but adds a 3rd line for broadcaster info */
  Extended,
  /** Featured series meant to be displayed larger, and more information */
  Featured,
  /** Minimal info designed to be listed in a column of other core elements */
  Info,
  /** Series meant to be displayed with large album art */
  Album,
  /** Series meant to be displayed with large album art with wrapping info */
  AlbumWrap,
}

export enum ArticleDisplay {
  News,
  Standard,
  Dashboard,
  DjangoDashboard,
  ReleaseNotes,
}

export enum WebcastDisplay {
  LiveVideo,
  LiveExtended,
  HistoryStandard,
  HistoryStandardWrap,
  Featured,
  Embed,
  Standard,
}

export enum PlayerMediaType {
  Audio,
  Video,
}

export enum AutoplayNextState {
  Off,
  Forward,
  Reverse,
}

export enum PlayerTheme {
  Site,
  Light,
  Dark,
}

export enum PlayerSermonEmbedCategory {
  Sermon,
  Broadcaster,
  Speaker,
}

export enum PlayerSermonEmbedFilter {
  None,
  Speaker,
  Series,
  Event,
  Year,
  Language,
  Scripture,
}

export enum PlayerStatus {
  Static,
  Loading,
  Loaded,
  Buffering,
  Paused,
  Playing,
  Ended,
  FetchingNext,
}

export enum PlayerCastPlatform {
  None,
  Airplay,
  Chromecast,
}

export enum PlayerResumeState {
  Ask,
  Resume,
  Restart,
}

export enum StadiaMapStyle {
  AlidadeSmoothLight,
  AlidadeSmoothDark,
  Outdoors,
  OSMBright,
}

export enum SpeakerDisplay {
  /** Standard 2-line speaker listing */
  Standard,
  /** Standard 2-line speaker, but wraps content instead of truncating it */
  StandardWrap,
  /** Larger speaker listing that includes about information */
  Expanded,
  /** Minimal info designed to be listed in a column of other core elements like BroadcasterElements */
  Info,
  /** Tiny listing intended to fit as small a location as possible */
  Micro,
}

export enum BroadcasterDisplay {
  /** Standard 2-line broadcaster listing */
  Standard,
  /** Standard 2-line broadcaster, but wraps content instead of truncating it */
  StandardWrap,
  /** Larger broadcaster listing that includes about information */
  Expanded,
  /** Minimal info designed to be listed in a column of other core elements like SpeakerElements */
  Info,
  Simple,
}

// If changing these values, be sure to change the values in tailwind.config.js
export enum TailwindBreakpoint {
  xxs = 320,
  xs = 480,
  sm = 640,
  md = 768,
  xm = 896,
  lg = 1024,
  xl = 1280,
  '2xl' = 1536,
}

export enum AutocompleteInputType {
  Name,
  FirstName,
  LastName,
  DisplayName,
  Username,
  Honorific,
  Email,
  Phone,
  Company,
  Street,
  City,
  State,
  Zipcode,
  Country,
  Password,
  NewPassword,
}

export enum DevotionalTab {
  Morning = 'Morning',
  Evening = 'Evening',
  Checkbook = 'Checkbook',
}

export interface NavigationTabItem {
  title: TranslateResult
  slug: string
  /** For when a very specific url is needed. Otherwise, just use the baseUrl property */
  url?: string
}

export type NavigationTabs = NavigationTabItem[]

export enum FormStatus {
  Incomplete,
  Focused,
  Invalid,
  Valid,
}

export enum PublishState {
  Draft,
  Scheduled,
  Published,
}

export function enumStrToNum(
  e: Record<string | number, string | number>,
  s: string
): number {
  return getValFromEnum(e, s) as number
}

export function enumNumToStr(
  e: Record<string | number, string | number>,
  n: number
): string {
  return getValFromEnum(e, n) as string
}

export function getValFromEnum(
  e: Record<string | number, string | number>,
  v: string | number
): string | number {
  return e[v]
}

export function getKeysFromEnum(e: Record<string | number, string | number>) {
  return Object.keys(e)
}

export function getStrValuesFromEnum(e: Record<string, string>) {
  const values = [] as string[]
  getKeysFromEnum(e).forEach((key: string) => {
    values.push(e[key])
  })
  return values
}
