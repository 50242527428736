import { Context } from '@nuxt/types'
import { redirectDisabledBroadcaster } from '~/assets/ts/utils/urls'

export default async function ({
  params,
  store,
  error,
  redirect,
  route,
}: Context) {
  const seriesID = params.series || params.seriesID
  let valid = !!seriesID
  if (seriesID) {
    await store.dispatch('series/fetchSeries', { seriesID })
    const series = store.getters['series/series'](seriesID)
    valid = !!series
    if (valid) {
      redirectDisabledBroadcaster(redirect, route, series.broadcaster)
    }
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: 'Series not found',
    })
  }
}
