var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative flex items-center outline-none",class:[
    _vm.static
      ? 'pointer-events-none'
      : 'transitions transition-height cursor-pointer',
    _vm.mediaTypeChanging ? 'type-changing' : '',
  ],style:(`height: ${_vm.waveHeight}px;`),attrs:{"aria-hidden":"true","tabindex":"-1"},on:{"mousemove":_vm.scrubTo,"mouseup":_vm.scrubTo,"mouseenter":function($event){_vm.hovered = true},"mouseleave":_vm.exit,"mousedown":function($event){_vm.scrubbing = true}}},[(_vm.simple)?_c('div',{staticClass:"h-full w-full pointer-events-none"},[_c('PlayerSimpleWaveformBar',{attrs:{"bar-colors":_vm.barColors.background,"scrub":_vm.scrubbedPercentage,"percent":100}}),_vm._v(" "),_c('PlayerSimpleWaveformBar',{attrs:{"bar-colors":_vm.barColors.buffered,"scrub":_vm.scrubbedPercentage,"percent":_vm.bufferedPercentage}}),_vm._v(" "),_c('PlayerSimpleWaveformBar',{attrs:{"bar-colors":_vm.barColors.played,"scrub":_vm.scrubbedPercentage,"percent":_vm.playedPercentage}})],1):_c('div',{staticClass:"absolute overflow-hidden pointer-events-none",style:(`width: ${_vm.width}px`)},[_c('div',{staticClass:"flex justify-between",class:_vm.static ? '' : 'transitions transition-height',style:(`width: ${_vm.width}px; height: ${_vm.waveHeight}px;`)},_vm._l((_vm.peaks),function(peak,index){return _c('div',{key:`waveform${index}`,staticClass:"shrink-0 relative",class:_vm.static ? '' : 'transitions bar-transitions',style:(`height: ${
          _vm.displayCollapsed ? _vm.waveHeight : _vm.barHeight(peak)
        }px; width: ${_vm.barX}px; padding-right: ${_vm.barPadding}px; top: ${_vm.topHeight(
          peak
        )}px`)},[_c('div',{staticClass:"transitions transition-height shrink-0",style:(`height: ${
            _vm.video || _vm.displayCollapsed ? '100' : '50'
          }%; background-color: ${_vm.getBarColor(index, false)}`)}),_vm._v(" "),(!_vm.static)?_c('div',{staticClass:"transitions transition-height",style:(`height: ${
            _vm.video || _vm.displayCollapsed ? '0' : '35'
          }%; background-color: ${_vm.getBarColor(index, true)}`)}):_vm._e()])}),0)]),_vm._v(" "),(!_vm.simple)?_c('ResizeObserver',{on:{"notify":_vm.resize}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }