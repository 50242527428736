const middleware = {}

middleware['auth/admin'] = require('../middleware/auth/admin.ts')
middleware['auth/admin'] = middleware['auth/admin'].default || middleware['auth/admin']

middleware['auth/broadcaster-sign-in'] = require('../middleware/auth/broadcaster-sign-in.ts')
middleware['auth/broadcaster-sign-in'] = middleware['auth/broadcaster-sign-in'].default || middleware['auth/broadcaster-sign-in']

middleware['auth/dashboard'] = require('../middleware/auth/dashboard.ts')
middleware['auth/dashboard'] = middleware['auth/dashboard'].default || middleware['auth/dashboard']

middleware['auth/guest'] = require('../middleware/auth/guest.ts')
middleware['auth/guest'] = middleware['auth/guest'].default || middleware['auth/guest']

middleware['auth/user'] = require('../middleware/auth/user.ts')
middleware['auth/user'] = middleware['auth/user'].default || middleware['auth/user']

middleware['fetch/ad'] = require('../middleware/fetch/ad.ts')
middleware['fetch/ad'] = middleware['fetch/ad'].default || middleware['fetch/ad']

middleware['fetch/adAggregate'] = require('../middleware/fetch/adAggregate.ts')
middleware['fetch/adAggregate'] = middleware['fetch/adAggregate'].default || middleware['fetch/adAggregate']

middleware['fetch/adConfig'] = require('../middleware/fetch/adConfig.ts')
middleware['fetch/adConfig'] = middleware['fetch/adConfig'].default || middleware['fetch/adConfig']

middleware['fetch/article'] = require('../middleware/fetch/article.ts')
middleware['fetch/article'] = middleware['fetch/article'].default || middleware['fetch/article']

middleware['fetch/collection'] = require('../middleware/fetch/collection.ts')
middleware['fetch/collection'] = middleware['fetch/collection'].default || middleware['fetch/collection']

middleware['fetch/displayAds'] = require('../middleware/fetch/displayAds.ts')
middleware['fetch/displayAds'] = middleware['fetch/displayAds'].default || middleware['fetch/displayAds']

middleware['fetch/promoBroadcaster'] = require('../middleware/fetch/promoBroadcaster.ts')
middleware['fetch/promoBroadcaster'] = middleware['fetch/promoBroadcaster'].default || middleware['fetch/promoBroadcaster']

middleware['fetch/specialArticle'] = require('../middleware/fetch/specialArticle.ts')
middleware['fetch/specialArticle'] = middleware['fetch/specialArticle'].default || middleware['fetch/specialArticle']

middleware['fetchBoostedWebcasts'] = require('../middleware/fetchBoostedWebcasts.ts')
middleware['fetchBoostedWebcasts'] = middleware['fetchBoostedWebcasts'].default || middleware['fetchBoostedWebcasts']

middleware['fetchBroadcaster'] = require('../middleware/fetchBroadcaster.ts')
middleware['fetchBroadcaster'] = middleware['fetchBroadcaster'].default || middleware['fetchBroadcaster']

middleware['fetchBroadcasterSignup'] = require('../middleware/fetchBroadcasterSignup.ts')
middleware['fetchBroadcasterSignup'] = middleware['fetchBroadcasterSignup'].default || middleware['fetchBroadcasterSignup']

middleware['fetchCategory'] = require('../middleware/fetchCategory.ts')
middleware['fetchCategory'] = middleware['fetchCategory'].default || middleware['fetchCategory']

middleware['fetchDashboardPrivateData'] = require('../middleware/fetchDashboardPrivateData.ts')
middleware['fetchDashboardPrivateData'] = middleware['fetchDashboardPrivateData'].default || middleware['fetchDashboardPrivateData']

middleware['fetchEvent'] = require('../middleware/fetchEvent.ts')
middleware['fetchEvent'] = middleware['fetchEvent'].default || middleware['fetchEvent']

middleware['fetchHymn'] = require('../middleware/fetchHymn.ts')
middleware['fetchHymn'] = middleware['fetchHymn'].default || middleware['fetchHymn']

middleware['fetchLanguage'] = require('../middleware/fetchLanguage.ts')
middleware['fetchLanguage'] = middleware['fetchLanguage'].default || middleware['fetchLanguage']

middleware['fetchSeries'] = require('../middleware/fetchSeries.ts')
middleware['fetchSeries'] = middleware['fetchSeries'].default || middleware['fetchSeries']

middleware['fetchSermon'] = require('../middleware/fetchSermon.ts')
middleware['fetchSermon'] = middleware['fetchSermon'].default || middleware['fetchSermon']

middleware['fetchSpeaker'] = require('../middleware/fetchSpeaker.ts')
middleware['fetchSpeaker'] = middleware['fetchSpeaker'].default || middleware['fetchSpeaker']

middleware['fetchTopic'] = require('../middleware/fetchTopic.ts')
middleware['fetchTopic'] = middleware['fetchTopic'].default || middleware['fetchTopic']

middleware['fetchWebcast'] = require('../middleware/fetchWebcast.ts')
middleware['fetchWebcast'] = middleware['fetchWebcast'].default || middleware['fetchWebcast']

middleware['fetchWebcasts'] = require('../middleware/fetchWebcasts.ts')
middleware['fetchWebcasts'] = middleware['fetchWebcasts'].default || middleware['fetchWebcasts']

middleware['healthz'] = require('../middleware/healthz.ts')
middleware['healthz'] = middleware['healthz'].default || middleware['healthz']

middleware['redirect/catch-all'] = require('../middleware/redirect/catch-all.ts')
middleware['redirect/catch-all'] = middleware['redirect/catch-all'].default || middleware['redirect/catch-all']

middleware['requireParam/adType'] = require('../middleware/requireParam/adType.ts')
middleware['requireParam/adType'] = middleware['requireParam/adType'].default || middleware['requireParam/adType']

middleware['requireParam/helpPage'] = require('../middleware/requireParam/helpPage.ts')
middleware['requireParam/helpPage'] = middleware['requireParam/helpPage'].default || middleware['requireParam/helpPage']

middleware['valid-hashtag'] = require('../middleware/valid-hashtag.ts')
middleware['valid-hashtag'] = middleware['valid-hashtag'].default || middleware['valid-hashtag']

export default middleware
