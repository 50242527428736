function ConfirmDirtyLeave() {
  return window.confirm(
    'Do you really want to leave? You have unsaved changes!'
  )
}

export function AreYouSureCheck(
  dirty: boolean,
  e: BeforeUnloadEvent | undefined = undefined
) {
  if (dirty && !ConfirmDirtyLeave()) {
    if (e) {
      // Cancel the event
      e.preventDefault()
      // Chrome requires returnValue to be set
      e.returnValue = ''
    }
    return true
  }
  return false
}
