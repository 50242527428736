
import Vue from 'vue'

export default Vue.extend({
  name: 'FocusVisibleElement',
  props: {
    element: {
      type: String,
      default: 'button',
    },
  },
  data() {
    return {
      hovering: false,
      focusing: false,
    }
  },
  watch: {
    focusing() {
      this.$emit('focusing', this.focusing)
    },
  },
  methods: {
    focus(isFocusing: boolean) {
      this.focusing = !this.hovering && isFocusing
    },
    hover(isHovering: boolean) {
      this.hovering = isHovering
      this.focusing = false
    },
  },
})
