import emojiRegex from 'emoji-regex'

export function isEmoji(str: string) {
  return emojiRegex().test(str)
}

export function tooManyCaps(str: string, allowedPercentage = 0.5) {
  const capCount = (str.match(/[A-Z]/g) || []).length
  return capCount / str.length > allowedPercentage
}

export function convertLineBreaks(str: string, convertTo = '<br>'): string {
  return str.replace(/(\r\n|\r|\n)/g, convertTo)
}

export function capitalize(str: string): string {
  str = str.toString()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function camelCaseToSpaces(str: string) {
  const result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function truncateString(
  str: string,
  maxLength = 150,
  preserveWords = false
) {
  if (str.length <= maxLength) return str
  const truncated = str.substring(0, maxLength - 3)
  if (preserveWords) {
    const lastSpace = truncated.lastIndexOf(' ')
    if (lastSpace > 0) {
      return `${truncated.substring(0, lastSpace)}...`
    }
  }

  return `${truncated}...`
}

export function stringIsNumber(str: string) {
  return !isNaN(parseFloat(str))
}

export function slugify(str: string, delimiter = '-') {
  str = str.replace(/\s+/g, delimiter) // spaces to hyphens
  str = str.replace(/[^\w-]/g, '') // remove non-word characters except hyphens
  str = str.replace(/-+/g, delimiter) // remove consecutive hyphens
  return str.toLowerCase()
}
