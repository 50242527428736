
import Vue from 'vue'

export default Vue.extend({
  name: 'SiteTextFeatureBorder',
  props: {
    borderTailwind: {
      type: String,
      required: true,
      validator(value: string) {
        return value.includes('border-')
      },
    },
  },
})
