
import Vue, { PropType } from 'vue'
import { UserAdApi } from '~/apiclient/apiads'
import {
  AdStatus,
  GetAdDisplayDate,
  GetAdStatus,
} from '~/assets/ts/utils/promotions'
import LoadingElement from '~/components/_general/LoadingElement.vue'

export default Vue.extend({
  name: 'SitePromotionalAdStatus',
  components: { LoadingElement },
  props: {
    ad: {
      type: Object as PropType<UserAdApi | undefined>,
      default: undefined,
    },
  },
  computed: {
    date(): string {
      return this.ad ? GetAdDisplayDate(this.ad) : ''
    },
    adStatus(): AdStatus {
      return GetAdStatus(this.ad)
    },
    scheduled(): boolean {
      return this.adStatus === 'scheduled'
    },
    active(): boolean {
      return this.adStatus === 'active'
    },
    completed(): boolean {
      return this.adStatus === 'completed'
    },
    dateText(): string {
      const label = this.scheduled
        ? this.$t('Scheduled')
        : this.active
        ? this.$t('Active')
        : this.completed
        ? this.$t('Completed')
        : ''
      return `${label}: ${this.date}`
    },
    textClasses(): string {
      switch (this.adStatus) {
        case 'active':
          return 'bg-green-light dark:bg-green-sale'
        case 'scheduled':
          return 'bg-blue-light dark:bg-blue-dark'
        case 'completed':
          return 'bg-gray-200 dark:bg-gray-600'
        default:
          return ''
      }
    },
  },
})
