
import Vue from 'vue'

export default Vue.extend({
  name: 'Poller',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    /** Will add debug logs if title is included **/
    debugTitle: {
      type: String,
      default: '',
    },
    /** Update every x seconds **/
    updateSeconds: {
      type: Number,
      default: 60,
    },
    /** Update every x milliseconds **/
    updateMs: {
      type: Number,
      default: undefined,
    },
    /** Should poll be emitted when the poller is set up **/
    pollOnActivate: {
      type: Boolean,
      default: true,
    },
    /** Indicates that we should poll on mount IF pollOnActivate is also set **/
    pollOnMount: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timeout: undefined as number | undefined,
    }
  },
  watch: {
    active() {
      this.setup(this.pollOnActivate)
    },
  },
  mounted() {
    if (!this.active) return
    this.setup(this.pollOnActivate && this.pollOnMount)
  },
  destroyed() {
    clearTimeout(this.timeout)
  },
  methods: {
    setup(immediate: boolean) {
      if (!this.$isClient) return

      clearTimeout(this.timeout)
      if (!this.active) {
        if (this.debugTitle) {
          console.log(`${this.debugTitle} cleared`)
        }
        return
      }
      if (this.debugTitle) {
        console.log(`${this.debugTitle} set up`)
      }
      if (immediate) {
        this.poll()
      } else {
        this.setTimeout()
      }
    },
    setTimeout() {
      const time = this.updateMs ?? this.updateSeconds * 1000
      this.timeout = window.setTimeout(() => {
        this.poll()
      }, time)
    },
    poll() {
      if (!this.active) return
      if (this.debugTitle) {
        console.log(`${this.debugTitle} polling`)
      }
      this.$emit('poll')
      this.setTimeout()
    },
  },
})
