import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  BroadcasterApi,
  BroadcasterBannerApi,
  BroadcasterInclude,
  BroadcasterPrivateDataApi,
} from '~/apiclient/apibroadcasters'
import { logError } from '~/assets/ts/utils/misc'
import { BroadcastersState } from '~/store/broadcasters'
import { ApiPaginationResult } from '~/apiclient/apiclient'

export const state = () => ({
  broadcaster: undefined as BroadcasterApi | undefined,
  broadcasterPrivateData: undefined as BroadcasterPrivateDataApi | undefined,
  bannerGallery: {
    next: undefined,
    nodeType: '',
    nodeDisplayName: '',
    totalCount: 0,
    results: [],
  } as ApiPaginationResult<BroadcasterBannerApi>,
})

export type DashboardState = ReturnType<typeof state>

export const mutations: MutationTree<DashboardState> = {
  ADD_BROADCASTER: (state, broadcaster: BroadcasterApi) => {
    state.broadcaster = broadcaster
  },
  ADD_PRIVATE_DATA: (state, privateData: BroadcasterPrivateDataApi) => {
    state.broadcasterPrivateData = privateData
  },
  ADD_BANNERS: (state, banners: ApiPaginationResult<BroadcasterBannerApi>) => {
    banners.results = [...state.bannerGallery.results, ...banners.results]
    state.bannerGallery = banners
  },
}

export const getters: GetterTree<DashboardState, DashboardState> = {
  broadcaster: (state) => state.broadcaster,
  broadcasterPrivateData: (state) => state.broadcasterPrivateData,
  bannerGallery: (state) => state.bannerGallery,
}

export const actions: ActionTree<BroadcastersState, BroadcastersState> = {
  async fetchBroadcaster({ commit }, { broadcasterID }) {
    try {
      /** TODO: remove bypassCache once cacheTTL is fully supported via the API */
      const data = await this.$apiClient.getBroadcaster(broadcasterID, {
        lite: false,
        bypassCache: true,
        cacheTTL: 0,
        include: BroadcasterInclude.All,
      })
      commit('ADD_BROADCASTER', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchBroadcasterPrivateData({ commit }, { broadcasterID }) {
    try {
      const data = await this.$apiClient.broadcasterPrivateData(broadcasterID)
      commit('ADD_PRIVATE_DATA', data)
    } catch (e) {
      logError(e)
    }
  },
}
