import { Context } from '@nuxt/types'
import { ArticleApi } from '~/apiclient/apiarticles'
import {
  GetServerCookie,
  LinkedBroadcasterCookie,
} from '~/assets/ts/utils/cookies'

export default async function (context: Context) {
  const params = context.params
  const store = context.store
  const route = context.route
  const articleID = params.articleID
  let broadcasterID = params.broadcasterID
  const slug = params.slug

  await store.dispatch('articles/fetchArticle', {
    articleID,
    broadcasterID,
    slug,
  })
  const article = store.getters['articles/article'] as ArticleApi | undefined
  if (article) {
    if (!broadcasterID) {
      broadcasterID = article.broadcaster.broadcasterID
    }
    if (
      route.path.includes('dashboard') &&
      broadcasterID !== GetServerCookie(LinkedBroadcasterCookie, context)
    ) {
      context.error({
        statusCode: 503,
        message: `This article is unavailable to the currently selected broadcaster`,
      })
    }
  } else {
    context.error({
      statusCode: 404,
      message: 'Article not found',
    })
  }
}
