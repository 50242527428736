import { Context } from '@nuxt/types'
import Vue from 'vue'
import { Route } from 'vue-router/types/router'
import { RemoveTrailingSlash } from '~/assets/ts/utils/urls'
import { NavigationTabs } from '~/assets/ts/enums'

export type AnyContext = Vue | Context

export function GetContextRoute(context: AnyContext) {
  const c = context as any
  return (c.$route ?? c.route) as Route | undefined
}

export function GetNavigationSlug<T>(
  vue: AnyContext,
  tabs: NavigationTabs,
  defaultTab: T
) {
  const route = GetContextRoute(vue) as Route
  let tab = defaultTab
  let path = RemoveTrailingSlash(route.path)
  const params = route.params
  Object.keys(params).forEach((param) => {
    const p = params[param]
    if (p) {
      path = path.replace(`/${p}`, '')
    }
  })
  const slugs = tabs.map((t) => t.slug) as T[]
  for (let i = 0; i < slugs.length; i++) {
    if (path.endsWith(`/${slugs[i]}`)) {
      tab = slugs[i]
    }
  }
  return tab
}
