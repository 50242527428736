import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { DisplayUserApi } from '~/models/usercomment'

export interface UserCommentApi {
  type: 'comment' | string
  commentID: number
  timestamp: number
  commentType: 'sermon' | string
  commentTitle: string
  commenterName?: string
  commenterLocation?: string
  commenterURL?: string
  ranking: number
  text?: string
  sermon: Record<string, any>
  displayUser?: DisplayUserApi
  pendingReview: boolean
}

export enum CommentSortOptions {
  Random = 'random',
  Date = 'date',
  Reverse = 'reverse-date',
}

export class CommentRequestOptions {
  broadcasterID?: string
  seriesID?: number
  speakerName?: string
  startTime?: number
  endTime?: number
  sortBy = CommentSortOptions.Date as CommentSortOptions
  min_rank?: number
  page = 1
  pageSize = 25
  recent = false
}

export async function getFilteredComments(
  this: ApiClient,
  options: Partial<CommentRequestOptions>
): Promise<ApiPaginationResult<UserCommentApi>> {
  const { data } = await this.$axios.get(`sermon_comments/legacy`, {
    params: { ...options },
  })
  return data
}

export interface CreateCommentParameters {
  title: string
  body_text: string
}

export async function createComment(
  this: ApiClient,
  sermonID: string,
  params: CreateCommentParameters
) {
  return await this.$axios.post(`sermon_comments/sermon/${sermonID}`, params)
}

export async function deleteComment(this: ApiClient, commentID: number) {
  return await this.$axios.delete(`sermon_comments/comment/${commentID}`)
}

export class GetUserCommentsOptions {
  page = 1
  pageSize = 10
  includePending = false
}

export async function getUserComments(
  this: ApiClient,
  params: GetUserCommentsOptions
): Promise<ApiPaginationResult<UserCommentApi>> {
  const { data } = await this.$axios.get('sermon_comments/user', {
    params,
  })
  return data
}
