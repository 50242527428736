import { Context } from '@nuxt/types'

export default ({ store, app }: Context) => {
  let triggered = false
  const stores = (store as any)._modulesNamespaceMap as Record<
    string,
    Record<string, any>
  >
  const storeList = Object.keys(stores).map((s) => stores[s])
  const dispatchMountStore = () => {
    storeList.forEach((s) => {
      const actions = s._rawModule.actions
      if (actions && Object.keys(actions).includes('nuxtClientInit')) {
        s.context.dispatch('nuxtClientInit', s)
        triggered = true
      }
    })
    if (!triggered) {
      console.warn('NO ACTIONS REGISTERED FOR "nuxtClientInit"')
    }
  }

  // Ensure it triggers when the app is mounted
  app.beforeMount = () => {
    dispatchMountStore()
  }
}
