
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import CustomDropdown from '~/components/_general/CustomDropdown.vue'

export default Vue.extend({
  name: 'TooltipElement',
  components: { CustomDropdown },
  props: {
    tooltip: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    classes: {
      type: String,
      default: 'inline-block',
    },
    tooltipClasses: {
      type: String,
      default: 'whitespace-nowrap',
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    hoverable(): boolean {
      return this.mounted && !!this.tooltip
    },
  },
  mounted() {
    this.mounted = true
  },
})
