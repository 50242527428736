import { ApiClient } from '~/apiclient/apiclient'

export type CustomSiteDomain =
  | 'vault.sermonaudio.com'
  | 'foundationspsalter.com'
  | 'www.sermonaudio.com'

export interface CustomSiteText {
  body: string
  customTextID: number
  domain: CustomSiteDomain
  tag: string
  updateTimestamp: number
}

export async function getCustomText(
  this: ApiClient,
  domain: CustomSiteDomain
): Promise<Record<string, CustomSiteText>> {
  const { data } = await this.$axios.get('site/custom/text', {
    params: { domain },
  })
  const text = {} as Record<string, CustomSiteText>
  data.results.forEach((t: CustomSiteText) => {
    text[t.tag] = t
  })
  return text
}
