import { TranslateResult } from 'vue-i18n'
import Vue from 'vue'
import {
  AdGlobalConfigApi,
  AdType,
  AdTypeApi,
  AdTypeConfigApi,
  DateParams,
  DisplayAdApi,
  UserAdApi,
} from '~/apiclient/apiads'
import { toCurrency } from '~/assets/ts/utils/math'
import { AdCreateMetadata } from '~/components/site/promotional/ScheduleCheckout.vue'
import {
  addDaysToDate,
  apiDateToJsDate,
  daysBetweenDates,
  localizeDatePickerDate,
  localizeDateRange,
  localToEst,
} from '~/assets/ts/utils/date'
import { DateFormat, NavigationTabs } from '~/assets/ts/enums'
import { SermonApi } from '~/apiclient/apisermons'
import { ValidSermonObject } from '~/store/promo'
import { ToastError } from '~/assets/ts/utils/toast'
import { Broadcaster } from '~/models/broadcaster'
import {
  SermonFilterCategories,
  SermonFilterSelection,
} from '~/assets/ts/utils/params'
import { isEmoji, tooManyCaps } from '~/assets/ts/utils/strings'
import {
  AnyContext,
  GetContextRoute,
  GetNavigationSlug,
} from '~/assets/ts/utils/route'

export type AdStatus = 'active' | 'scheduled' | 'completed'
export enum HelpPage {
  DesignGuidelines = 'design-guidelines',
  FAQ = 'faq',
  Policies = 'policies',
}

export function GetAdStatus(ad: UserAdApi | undefined): AdStatus {
  if (ad === undefined) return 'completed'
  let dateNow = new Date()
  dateNow = localToEst(dateNow)
  const adBeginDate = new Date(ad.dateBegin + ' 00:00:00 EST')

  if (ad.dateEnd == null) {
    dateNow.setHours(0, 0, 0, 0)
    if (adBeginDate > dateNow) {
      return 'scheduled'
    } else if (adBeginDate < dateNow) {
      return 'completed'
    } else {
      return 'active'
    }
  } else {
    const adEndDate = new Date(ad.dateEnd + ' 00:00:00 EST')
    if (adBeginDate > dateNow) {
      return 'scheduled'
    } else if (adEndDate < dateNow) {
      return 'completed'
    } else {
      return 'active'
    }
  }
}

export function ValidateAdMetadata(
  adType: AdType,
  metadata: AdCreateMetadata
): boolean {
  if (metadata.sermonID) {
    const sermonTypes = [AdType.FeaturedSermon, AdType.Text] as AdType[]
    return sermonTypes.includes(adType)
  }
  if (adType === AdType.Graphic && !metadata.image) return false
  return !!metadata.url && !!metadata.title && !!metadata.description
}

export function GetAdTypeStatRangeTitle(
  vue: Vue,
  adType: AdType | undefined,
  dates: DateParams
) {
  const dateString = localizeDateRange(
    apiDateToJsDate(dates.dateBegin) as Date,
    apiDateToJsDate(dates.dateEnd) as Date
  )
  return `${GetAdTypeTitle(vue, adType, true)} (${dateString})`
}

export function GetHelpPageTitle(vue: Vue, page: HelpPage): TranslateResult {
  switch (page) {
    case HelpPage.DesignGuidelines:
      return vue.$t('Ad Design Guidelines')
    case HelpPage.FAQ:
      return vue.$t('Ad FAQ')
    case HelpPage.Policies:
      return vue.$t('Ad Policies')
  }
}

export function GetAdTypeTitle(
  vue: Vue,
  adType?: AdType,
  plural = false
): TranslateResult {
  const n = plural ? 2 : 1
  switch (adType) {
    case AdType.FeaturedSermon:
      return vue.$tc('Featured Sermon | Featured Sermons', n)
    case AdType.Graphic:
      return vue.$tc('Graphical Ad | Graphical Ads', n)
    case AdType.Text:
      return vue.$tc('Text Ad | Text Ads', n)
    default:
      return vue.$tc('Ad | Ads', n)
  }
}

export function GetAdNavigationTabs(vue: Vue | undefined) {
  const list = [undefined, AdType.FeaturedSermon, AdType.Text, AdType.Graphic]
  return list.map((adType) => {
    return {
      title: vue
        ? adType
          ? GetAdTypeTitle(vue, adType, true)
          : vue.$t('All')
        : '',
      slug: adType ?? '',
    }
  }) as NavigationTabs
}

export function GetAdTypeTitleWithCount(
  vue: Vue,
  adType: AdType | undefined,
  n: number
): TranslateResult {
  switch (adType) {
    case AdType.FeaturedSermon:
      return vue.$tc('{n} Featured Sermon | {n} Featured Sermons', n, { n })
    case AdType.Graphic:
      return vue.$tc('{n} Graphical Ad | {n} Graphical Ads', n, { n })
    case AdType.Text:
      return vue.$tc('{n} Text Ad | {n} Text Ads', n, { n })
    default:
      return vue.$tc('{n} Ad | {n} Ads', n, { n })
  }
}

export function GetAdType(ad: DisplayAdApi): AdType {
  switch (ad.adType) {
    case 'ad':
    case 'sermon':
      return AdType.Text
    case 'ad-sponsor':
      return AdType.Graphic
    case 'sermon-featured':
      return AdType.FeaturedSermon
    case 'spotlight':
      return AdType.Spotlight
    case 'hero':
      return AdType.Hero
  }
}

export function GetPageAdTypeSlug(context: AnyContext): AdType | undefined {
  return GetNavigationSlug<AdType | undefined>(
    context,
    GetAdNavigationTabs(undefined),
    undefined
  )
}

export function GetPageAdType(context: AnyContext): AdType | undefined {
  /** This lets us handle it both ways */
  const slug = GetPageAdTypeSlug(context)
  if (slug) return slug
  const route = GetContextRoute(context)
  const adType = route?.params.adType as string | undefined
  switch (adType) {
    case AdType.FeaturedSermon:
      return AdType.FeaturedSermon
    case AdType.Text:
      return AdType.Text
    case AdType.Graphic:
      return AdType.Graphic
    default:
      return undefined
  }
}

export function GetAdTypeApi(adType: AdType, sermon = false): AdTypeApi {
  if (adType === AdType.Graphic) return 'ad-sponsor'
  if (adType === AdType.FeaturedSermon) return 'sermon-featured'
  if (adType === AdType.Spotlight) return 'spotlight'
  if (adType === AdType.Hero) return 'hero'
  return sermon ? 'sermon' : 'ad'
}

export function GetAdTypeApiList(
  adType: AdType | undefined
): AdTypeApi[] | undefined {
  if (!adType) return undefined
  if (adType === AdType.Text) {
    return ['ad', 'sermon'] as AdTypeApi[]
  }
  return [GetAdTypeApi(adType)]
}

export function CreateDisplayAd(
  vue: Vue,
  adType: AdTypeApi,
  url?: string,
  title?: string,
  description?: string,
  sermon?: SermonApi,
  imageURL?: string
): DisplayAdApi {
  if (!sermon && (adType === 'sermon' || adType === 'sermon-featured')) {
    sermon = {
      displayTitle: vue.$t('Sermon Title'),
      broadcaster: {
        displayName: vue.$t('Broadcaster Name'),
      },
      speaker: {
        displayName: vue.$t('Speaker Name'),
      },
      hasVideo: false,
      hasAudio: false,
    } as SermonApi
  }
  const images = []
  if (imageURL) {
    images.push({
      imageURL,
      imageURLWebp: imageURL,
    })
  }
  return {
    adType,
    campaignID: '',
    url: url || 'https://example.com',
    title: title || vue.$t('Ad Title').toString(),
    description:
      description || vue.$t('Your ad description will go here.').toString(),
    sermon,
    images,
  }
}

export function GetAdDisplayDate(ad: UserAdApi) {
  const startDate = apiDateToJsDate(ad.dateBegin) as Date
  let endDate = apiDateToJsDate(ad.dateEnd) as Date
  endDate = addDaysToDate(endDate, -1)
  const range = daysBetweenDates(startDate, endDate) > 1
  return localizeDatePickerDate(
    range ? [startDate, endDate] : startDate,
    DateFormat.ShortDate
  )
}

export function GetAdCreateText(vue: Vue, adType: AdType): TranslateResult {
  switch (adType) {
    case AdType.FeaturedSermon:
      return vue.$t('Feature a Sermon')
    default:
      return vue.$t('Create a {adType}', {
        adType: GetAdTypeTitle(vue, adType),
      })
  }
}

export interface AdPricingInfo {
  perDayDisplay: string
  dollarsDisplay: string
  summaryDisplay: string
  totalDisplay: string
  total: number
  invalid: boolean
}

export function GetAdPricingInfo(
  vue: Vue,
  adInfo: AdTypeConfigApi | undefined,
  days: number,
  coupon = false
): AdPricingInfo {
  const locale = vue.$i18n.locale
  const dailyDollars = adInfo ? parseInt(adInfo.cost) : 0
  let total = dailyDollars * days
  if (coupon) {
    total -= dailyDollars
  }
  const dailyDollarsDisplay = toCurrency(dailyDollars, locale)
  const totalDollarsDisplay = toCurrency(total, locale)
  const invalidDisplay = '--'
  const invalid = !adInfo
  return {
    dollarsDisplay: invalid ? invalidDisplay : dailyDollarsDisplay,
    perDayDisplay: invalid
      ? invalidDisplay
      : vue.$t('{price} / day', { price: dailyDollarsDisplay }).toString(),
    summaryDisplay: invalid
      ? invalidDisplay
      : `${dailyDollarsDisplay} x ${days}`,
    totalDisplay: invalid || days < 1 ? invalidDisplay : totalDollarsDisplay,
    invalid,
    total,
  }
}

export function GetAdInfo(
  adInfo: AdGlobalConfigApi | undefined,
  adType: AdType
): AdTypeConfigApi | undefined {
  if (!adInfo) return undefined
  switch (adType) {
    case AdType.FeaturedSermon:
      return adInfo.featuredsermon
    case AdType.Graphic:
      return adInfo.sponsorfeature
    case AdType.Text:
    default:
      return adInfo.minifeature
  }
}

export async function checkAd(
  vue: Vue,
  sermonIDs: string[],
  adType: AdTypeApi
): Promise<boolean> {
  let validObject
  for (let i = 0; i < sermonIDs.length; i++) {
    validObject = (await vue.$store.dispatch('promo/fetchAdConfig', {
      sermonID: sermonIDs[i],
      adType,
    })) as ValidSermonObject

    if (!validObject.valid && !validObject.error) {
      ToastError(
        vue.$t(
          'Sermon cannot be featured. Please review ad policies for more information.',
          {
            id: sermonIDs[i],
          }
        )
      )
      return false
    }
  }

  return true
}

export function isValidAdTitleAndDesc(
  vue: Vue,
  metadata?: AdCreateMetadata
): boolean {
  if (!validAdTitleOrDesc(metadata?.title)) {
    ToastError(
      vue.$t(
        'Please modify your title to comply with the Advertising Guidelines.'
      )
    )
    return false
  }
  if (!validAdTitleOrDesc(metadata?.description)) {
    ToastError(
      vue.$t(
        'Please modify your description to comply with the Advertising Guidelines.'
      )
    )
    return false
  }
  return true
}

export function validAdTitleOrDesc(str: string | undefined) {
  if (!str) return true
  return !isEmoji(str) && !tooManyCaps(str)
}

export function DefaultPromoSermonOptions(
  broadcaster: Broadcaster | undefined
): SermonFilterSelection[] {
  if (!broadcaster) return []
  return [
    {
      category: SermonFilterCategories.Broadcaster,
      value: broadcaster.id,
      display: broadcaster.displayName,
    },
  ]
}

export const SponsorMinimumDimension = 150
export const SponsorMinimumReadableDimension = 96
