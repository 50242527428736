import axios from 'axios'

export function displayUrl(fullUrl: string, domainOnly = false) {
  if (domainOnly) {
    try {
      const hostname = new URL(fullUrl).hostname
      const split = hostname.split('.')
      return split.length > 1 ? split.splice(-2).join('.') : hostname
    } catch (_e) {}
  }
  const url = fullUrl.replace(/https:\/\/|http:\/\//, '')
  return url.endsWith('/') ? url.slice(0, -1) : url
}

export function resizableUrl(
  url: string | undefined,
  width: number | undefined = undefined,
  height: number | undefined = undefined
): string | undefined {
  if (!url) return undefined
  if (!width || !height) {
    console.error('A width and height are both required')
    return undefined
  }
  return `${url
    .replace('{width}', width.toString())
    .replace('{height}', height.toString())}?webp=true`
}

/** Just checks if the url includes https or http */
export function isExternalUrl(url: string) {
  return /^https?:\/\//.test(url)
}

export interface PopoutOptions {
  width: number
  height: number
  query?: string
}

export const DefaultPopoutOptions = {
  width: 766,
  height: 720,
  query: 'autoplay=true&popout=true',
} as PopoutOptions

export function openPopoutWindow(
  url: string,
  options: PopoutOptions = DefaultPopoutOptions
) {
  if (options.query) {
    url += `?${options.query}`
  }
  return window.open(
    url,
    '_blank',
    `toolbar=no,menubar=no,titlebar=no,status=no,scrollbars=no,resizable=yes,left=0,top=0,width=${options.width},height=${options.height}`
  )
}

export const Alphabet = 'abcdefghijklmnopqrstuvwxyz'

export function clone<T>(item: T): T {
  return JSON.parse(JSON.stringify(item))
}

export async function PromiseAll(
  promises: Promise<any>[],
  timeoutMs = 0
): Promise<any> {
  const promiseAll = Promise.all(promises)
  if (timeoutMs > 0) {
    return await Promise.race([promiseAll, timeout(timeoutMs)])
  } else {
    return await promiseAll
  }
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function waitSeconds(seconds: number) {
  return wait(seconds * 1000)
}

export function waitOneFrame() {
  return wait(0)
}

export function timeout(ms = 3000, message = 'Timeout') {
  return new Promise((_resolve, reject) =>
    setTimeout(() => reject(new Error(message)), ms)
  )
}

// Logs <error> to console without duplicating error messages unnecessarily
export function logError(error: any) {
  if (!axios.isAxiosError(error)) {
    // Log error only if it is not an Axios error
    // Axios errors are logged separately in the axios-ext.ts error interceptor
    console.error(error)
  }
}

export function returnUndefinedOnError(_reason: any) {
  return undefined
}

export function scrollTo(id: string): void {
  const el = document.getElementById(id)
  if (el) {
    const offsetStr = el.dataset.hashOffset
    const offset = offsetStr ? parseInt(offsetStr) : 0
    window.scrollTo({ top: el.offsetTop - offset, behavior: 'smooth' })
  }
}

export function isBool(value: any): boolean {
  return typeof value === 'boolean'
}

export function boolOrUndefined(value: any): boolean | undefined {
  return isBool(value) ? value : undefined
}

export function sendAdClick(clickUrl: string) {
  if (!clickUrl) return
  const http = new XMLHttpRequest()
  http.open('GET', clickUrl, true)
  http.send(null)
}

export function autoFocusForm(form: HTMLFormElement) {
  Object.keys(form.elements).forEach((key: any) => {
    const el = form.elements[key] as HTMLElement
    if (el.autofocus) {
      el.focus()
    }
  })
}

export function removeNullValues(object: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(object).filter(
      (item) => item[1] !== undefined && item[1] !== null
    )
  )
}

export function toModel<T, K>(
  Model: new (record: T) => K,
  record: T | undefined
): K | undefined {
  if (record) return new Model(record)
  return undefined
}

function CompressString(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash.toString(36)
}

export function SimpleHash(input: string) {
  input = CompressString(input)
  if (typeof btoa !== 'undefined') {
    // Browser environment
    return btoa(input)
  } else if (typeof Buffer !== 'undefined') {
    // Node.js environment
    return Buffer.from(input).toString('base64')
  } else {
    console.error('Hash could not be completed on this environment')
    return input
  }
}

export function LoadScript(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.defer = true
    script.onload = () => {
      console.log(`Script Loaded: ${src}`)
      resolve(src)
    }
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`))
    document.head.appendChild(script)
  })
}
