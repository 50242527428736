import { Context } from '@nuxt/types'

export default function ({ params, error }: Context) {
  // TODO: when API endpoint speed increases, check that hashtag is valid
  const hashtag = params.hashtag
  const valid = !!hashtag
  if (!valid) {
    error({
      statusCode: 404,
      message: 'No Hashtag Provided',
    })
  }
}
