import { Context } from '@nuxt/types'
import { StringByEnv } from '~/assets/ts/utils/environment'

export const PromoBroadcasterCookie = 'sa.promo_broadcaster'
/** This ID is used to differentiate using promos as a user vs as a broadcaster */
export const PromoCookieUserId = 'sa.promo_user'

export const LinkedBroadcasterCookie = 'sa.linked_broadcaster'
export const ApiAccessCookie = 'sa_access_token'
export const ApiRefreshCookie = 'sa_refresh_token'
export const VueAccessCookie = 'sa2._token.local'
export const VueRefreshCookie = 'sa2._refresh_token.local'

export const BroadcasterSignupCookie = 'sa.broadcaster_signup'
export const BroadcasterLinkingCookie = 'sa.broadcaster_linking'
export const BroadcasterSignupCouponCookie = 'sa.broadcaster_signup_coupon'

export const UseVueDashboardCookie = 'sa.new_dashboard'

const YearSeconds = 31536000 // 1 year
const TempCookiesSeconds = 7200 // 2 hours

function CookieDomain() {
  return StringByEnv('localhost', 'sermonaudio.com')
}

export function DeleteCookie(cookieName: string) {
  document.cookie = `${cookieName}=;path=/;domain=${CookieDomain()};expires=Thu, 01 Jan 1970 00:00:01 GMT`
}

export function SetCookie(cookieName: string, value: any, age = YearSeconds) {
  document.cookie = AssembleCookie(cookieName, value, age)
}

export function GetCookie(cookieName: string): string {
  return get(cookieName, document.cookie)
}

export function GetServerCookie(cookieName: string, context?: Context): string {
  return get(cookieName, context?.req?.headers?.cookie ?? document.cookie)
}

export function SetTempCookie(cookieName: string, value: any) {
  SetCookie(cookieName, value, TempCookiesSeconds)
}

export function AssembleCookie(
  cookieName: string,
  value: any,
  age: number = YearSeconds
) {
  const cookie = `${cookieName}=${value.toString()}`
  return `${cookie};path=/;domain=${CookieDomain()};Max-Age=${age};`
}

function get(cookieName: string, cookies: string | undefined) {
  if (!cookies) return ''
  const cookie =
    cookies.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)')?.pop() || ''
  return cookie
}
