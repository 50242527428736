import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'

export interface HashtagApi {
  count: number
  featured: boolean
  hashtag: string
}

export type HashtagSortOption = 'sermon-count' | 'recent'

export interface HashtagRequestOptions {
  size: number
  sermonID: number
  searchKeyword: string
  useStem: boolean
  autocomplete: boolean
  minimumCount: number
  featured: boolean
  trending: boolean
  startTrendingTimestamp: number
  endTrendingTimestamp: number
  sortBy: HashtagSortOption
}

export async function getHashtags(
  this: ApiClient,
  options: Partial<HashtagRequestOptions> = {}
): Promise<ApiPaginationResult<HashtagApi>> {
  const { data } = await this.$axios.get('node/discover/hashtags', {
    params: options,
  })
  return data
}
