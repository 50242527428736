import { Context } from '@nuxt/types'
import {
  GetServerCookie,
  LinkedBroadcasterCookie,
} from '~/assets/ts/utils/cookies'

export default async function (context: Context) {
  if (context.store.getters['dashboard/broadcasterPrivateData']) return
  const broadcasterID = GetServerCookie(LinkedBroadcasterCookie, context)
  await context.store.dispatch('dashboard/fetchBroadcasterPrivateData', {
    broadcasterID,
  })
}
