
import Vue from 'vue'
import { siteUserPageUrl } from '~/assets/ts/utils/urls'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'SiteUserLearnMore',
  components: { SaLink },
  computed: {
    learnMoreUrl(): string {
      return siteUserPageUrl('about')
    },
  },
  methods: {
    closeModals() {
      this.$modal.hideAll()
    },
  },
})
