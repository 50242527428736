import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { default as Scheme$040e } from '/opt/sermonaudio/web/schemes/customRefresh.ts'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": false,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/user/login/",
    "logout": false,
    "home": false,
    "callback": "/user/login/"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "sa2.",
    "options": {
      "path": "/",
      "maxAge": 31536000,
      "secure": true,
      "domain": "sermonaudio.com"
    }
  },
  "localStorage": false,
  "defaultStrategy": "local"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new Scheme$040e($auth, {
  "token": {
    "global": true,
    "maxAge": 900,
    "property": "access_token"
  },
  "refreshToken": {
    "global": true,
    "property": "refresh_token",
    "maxAge": 31536000
  },
  "user": {
    "property": false
  },
  "endpoints": {
    "refresh": {
      "url": "site/users/refresh",
      "method": "post",
      "timeout": 5000
    },
    "login": {
      "url": "site/users/{username}/login",
      "method": "post"
    },
    "logout": {
      "url": "site/users/logout",
      "method": "post"
    },
    "user": {
      "url": "site/users",
      "method": "get"
    }
  },
  "name": "local"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
