import { ApiClient } from '~/apiclient/apiclient'
import {
  DonationCampaignApi,
  DonationCampaignDonorApi,
  DonationCampaignSupportApi,
} from '~/models/donation-campaign'

export async function getDonationCampaign(
  this: ApiClient,
  campaignID: string
): Promise<DonationCampaignApi> {
  const { data } = await this.$axios.get(`node/campaigns/${campaignID}`)
  return data
}

interface DonationCampaignSupportApiClient {
  results: DonationCampaignSupportApi[]
  totalCount: number
}

export async function getDonationCampaignSupport(
  this: ApiClient,
  campaignID: string,
  sortBy: 'highest' | 'latest' = 'latest',
  page = 1,
  pageSize = 25
): Promise<DonationCampaignSupportApiClient> {
  const { data } = await this.$axios.get(
    `node/campaigns/${campaignID}/support`,
    {
      params: {
        sortBy,
        pageSize,
        page,
      },
    }
  )
  return {
    results: data.results,
    totalCount: data.totalCount,
  }
}

interface DonationCampaignDonorApiClient {
  results: DonationCampaignDonorApi[]
  totalCount: number
}

export async function getDonationCampaignDonors(
  this: ApiClient,
  campaignID: string,
  page = 1,
  pageSize = 25
): Promise<DonationCampaignDonorApiClient> {
  const { data } = await this.$axios.get(
    `node/campaigns/${campaignID}/donors`,
    {
      params: {
        pageSize,
        page,
      },
    }
  )
  return {
    results: data.results,
    totalCount: data.totalCount,
  }
}
