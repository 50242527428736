// This plugin handles client-side URL rewriting for custom domains

import { Context } from '@nuxt/types'
import { RouteConfig } from 'vue-router'
import { matchingRoutes } from '~/router/rewriteutil'
import { RewriteKey } from '~/router/rewritetypes'

export default function ({ app: { store, router }, ssrContext }: Context) {
  if (!store || !router) return

  if (ssrContext) {
    // Server side: store rewrite info for client to retrieve
    const configType = ssrContext.req.headers['x-sa-config-type'] as RewriteKey
    const paramID = ssrContext.req.headers['x-sa-config-param-id'] as string
    if (configType) {
      // Put rewrite config into store for client to retrieve
      store.commit('REWRITE_PARAM', {
        configType,
        id: paramID,
      })
    }
  } else {
    // Client side: filter routes for custom domains
    let routes = (router.options as Record<string, any>)._saorigroutes

    const configType = store.getters.rewriteParams.configType
    if (configType) {
      routes = matchingRoutes(configType, routes)
    }

    routes.forEach((route: RouteConfig) => router.addRoute(route))
  }
}
