
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SiteUserLabel from '~/components/site/user/Label.vue'
import { User } from '~/models/users/user'
import { UserAdApi } from '~/apiclient/apiads'
import TooltipElement from '~/components/_general/TooltipElement.vue'

export default Vue.extend({
  name: 'SitePromotionalPurchasedBy',
  components: { TooltipElement, SiteUserLabel },
  props: {
    iconOnly: {
      type: Boolean,
    },
    ad: {
      type: Object as PropType<UserAdApi>,
      default: undefined,
    },
  },
  computed: {
    visible(): boolean {
      return !!this.$store.getters['broadcasters/promoBroadcaster']
    },
    purchasedBy(): TranslateResult {
      return this.$t('Purchased by')
    },
    purchasedByTooltip(): string | undefined {
      if (!this.user || !this.iconOnly) return undefined
      return `${this.purchasedBy} ${this.user.DisplayName}`
    },
    user(): User | undefined {
      return this.ad?.user ? new User(this.ad?.user) : undefined
    },
  },
})
