import { Context } from '@nuxt/types'

export default async function ({ params, store, error }: Context) {
  const hymnID = params.hymnID
  const psalmID = params.psalmID
  const id = hymnID || psalmID
  let valid = !!hymnID
  if (id) {
    await store.dispatch('hymns/fetchHymn', { hymnID: id })
    valid = !!store.getters['hymns/hymn'](id)
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: psalmID ? 'Psalm not found' : 'Hymn not found',
    })
  }
}
