import { capitalize } from '~/assets/ts/utils/strings'

interface ApiError {
  message: string
  httpStatus: number // HTTP status code
  // apiCode is an additional optional code the API provides in some scenarios
  // See python-json-apis/apicommon/apierror.py for code values
  apiCode: number | undefined
}

export function getApiError(data: any, context: Record<any, any>): ApiError {
  if (data.response) {
    const status = data.response.status
    const errors = data.response.data.errors
    if (errors) {
      const keys = Object.keys(errors)
      const message = keys
        .map((key) => `${capitalize(key)}: ${capitalize(errors[key])}`)
        .join(', ')
      return {
        message,
        httpStatus: status,
        apiCode: data.response.data.errors.code,
      }
    } else {
      const message = context.$t('error.with-status', { status }).toString()
      return { message, httpStatus: status, apiCode: undefined }
    }
  } else {
    console.error(data)
    return {
      message: data,
      httpStatus: 405,
      apiCode: undefined,
    }
  }
}
