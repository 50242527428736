
import Vue from 'vue'

export default Vue.extend({
  name: 'NavigationTab',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    inactiveColor: {
      type: String,
      default: 'text-current',
    },
    activeColor: {
      type: String,
      default: 'text-theme-blue',
    },
    hoverBackgrounds: {
      type: String,
      default: 'hover:bg-gray-100 dark:hover:bg-gray-750',
      validator: (value: string) => {
        return value.includes('hover:bg-')
      },
    },
  },
  computed: {
    activeClasses(): string {
      if (!this.active) return `${this.inactiveColor} py-2`
      return `border-b-current py-1 border-y-4 pointer-events-none ${this.activeColor}`
    },
  },
})
