import { Context } from '@nuxt/types'
import { redirectDisabledBroadcaster } from '~/assets/ts/utils/urls'

export default async function ({
  params,
  query,
  store,
  error,
  route,
  redirect,
}: Context) {
  const sermonID = params.sermon || params.sermonID
  const includeWaveform = true
  let valid = true
  if (sermonID) {
    await store.dispatch('sermons/fetchSermon', {
      sermonID,
      includeWaveform,
      lite: false,
    })
    const sermon = store.getters['sermons/sermon'](sermonID)
    valid = !!sermon
    if (valid) {
      redirectDisabledBroadcaster(redirect, route, sermon.broadcaster)
    }
  } else {
    const values = {} as Record<string, any>
    if (params.category) values[params.category] = params.categoryValue
    if (params.filter) values[params.filter] = params.filterValue
    if (params.mediaType) {
      values.cache = false
      if (params.mediaType === 'a') {
        values.type = 'audio'
      } else if (params.mediaType === 'v') {
        values.type = 'video'
      } else {
        values.type = params.mediaType
      }
    }
    await store.dispatch('sermons/fetchFilteredSermonList', {
      ...params,
      ...values,
      ...query,
      includeWaveform,
    })
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: 'Sermon not found',
    })
  }
}
