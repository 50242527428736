import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'

type DiscoverDurationType = {
  english: string
  seconds: number
  sermonCount: number
}

export const state = () => ({
  discoverDuration: {
    english: '',
    seconds: 0,
    sermonCount: 2100000,
  } as DiscoverDurationType,
})

export type LocalState = ReturnType<typeof state>

export const mutations: MutationTree<LocalState> = {
  SET_DISCOVER_DURATIONS: (state, data: DiscoverDurationType) => {
    state.discoverDuration = data
  },
}

export const getters: GetterTree<LocalState, LocalState> = {
  totalSaSermonCount: (state) => state.discoverDuration.sermonCount,
  totalSaSermonSeconds: (state) => state.discoverDuration.seconds,
}

export const actions: ActionTree<LocalState, LocalState> = {
  async getTotalSermonCount({ commit }) {
    try {
      const { data } = await this.$axios.get('/node/discover/duration')
      commit('SET_DISCOVER_DURATIONS', data as DiscoverDurationType)
    } catch (e) {
      logError(e)
    }
  },
}
