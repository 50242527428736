import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { TopicApi } from '~/apiclient/apitopics'
import { logError } from '~/assets/ts/utils/misc'

export const state = () => ({
  allTopics: [] as TopicApi[],
  topics: {} as Record<any, any>,
})

export type TopicsState = ReturnType<typeof state>

export const mutations: MutationTree<TopicsState> = {
  SET_TOPICS: (state, data: TopicApi[]) => {
    state.allTopics = data
  },
  ADD_TOPIC: (state, topic: TopicApi) => {
    Vue.set(state.topics, topic.topicID, topic)
  },
}

export const getters: GetterTree<TopicsState, TopicsState> = {
  topics: (state) => state.allTopics,
  topic: (state) => (topicID: string) => state.topics[topicID],
}

export const actions: ActionTree<TopicsState, TopicsState> = {
  async getTopics({ commit, state }) {
    if (state.allTopics.length) return
    const { results } = await this.$apiClient.getTopics()
    commit('SET_TOPICS', results)
  },
  async fetchTopic({ commit }, { topicID }) {
    try {
      const { results } = await this.$apiClient.getTopics()
      const topic = results.find((t: TopicApi) => t.topicID === topicID)
      if (!topic) return
      commit('ADD_TOPIC', topic)
    } catch (e) {
      logError(e)
    }
  },
}
