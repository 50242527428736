import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'

export interface LegacyGroupApi {
  broadcasterCount: number
  name: string
}

export async function getLegacyGroups(
  this: ApiClient
): Promise<ApiPaginationResult<LegacyGroupApi>> {
  const { data } = await this.$axios.get('node/discover/legacy_groups')
  return data
}

export interface GroupApi {
  alternateURL?: string
  broadcasterCount: number
  description?: string
  domain?: string
  domainSubtitle?: string
  domainTitle?: string
  groupID: string
  shortTitle: string
  title: string
}

export async function getGroups(this: ApiClient): Promise<GroupApi[]> {
  const { data } = await this.$axios.get('groups')
  return data
}
