import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { logError } from '~/assets/ts/utils/misc'
import { HymnApi, HymnOptions, HymnResultsApi } from '~/apiclient/apihymns'
import { ApiPaginationResult } from '~/apiclient/apiclient'

export const state = () => ({
  hymns: {} as Record<string, HymnApi>,
  popHymnList: [] as HymnResultsApi[],
  alphaHymnList: [] as HymnResultsApi[],
  psalterHymnList: [] as HymnResultsApi[],
  popularCount: 999999,
  alphaCount: 999999,
  psalterCount: 999999,
  popularPage: 1,
  psalterPage: 1,
})

export type HymnsState = ReturnType<typeof state>

export const mutations: MutationTree<HymnsState> = {
  ADD_HYMN: (state, hymn: HymnApi) => {
    Vue.set(state.hymns, hymn.hymnID, hymn)
  },
  UPDATE_POP_HYMN_LIST: (
    state,
    result: ApiPaginationResult<HymnResultsApi>
  ) => {
    result.results.forEach((hymn) => state.popHymnList.push(hymn))
    state.popularCount = result.totalCount
    state.popularPage++
  },
  UPDATE_ALPHA_HYMN_LIST: (
    state,
    result: ApiPaginationResult<HymnResultsApi>
  ) => {
    result.results.forEach((hymn) => state.alphaHymnList.push(hymn))
    state.alphaCount = result.totalCount
  },
  UPDATE_PSALTER_HYMN_LIST: (
    state,
    result: ApiPaginationResult<HymnResultsApi>
  ) => {
    result.results.forEach((hymn) => state.psalterHymnList.push(hymn))
    state.psalterCount = result.totalCount
    state.psalterPage++
  },
}

export const getters: GetterTree<HymnsState, HymnsState> = {
  hymn: (state) => (hymnID: string) => state.hymns[hymnID],
  popHymnList: (state) => state.popHymnList,
  alphaHymnList: (state) => state.alphaHymnList,
  psalterHymnList: (state) => state.psalterHymnList,
  maxPopReached: (state) => state.popHymnList.length >= state.popularCount,
  maxAlphaReached: (state) => state.alphaHymnList.length >= state.alphaCount,
  maxPsalterReached: (state) =>
    state.psalterHymnList.length >= state.psalterCount,
}

export const actions: ActionTree<HymnsState, HymnsState> = {
  async fetchHymn({ commit, state }, options) {
    try {
      let hymn = state.hymns[options.hymnID]
      if (hymn) return hymn

      hymn = await this.$apiClient.getHymn(options.hymnID)
      commit('ADD_HYMN', hymn)
    } catch (e) {
      logError(e)
    }
  },
  async fetchPopularHymnList(
    { commit, getters, state },
    options: Partial<HymnOptions> = {}
  ) {
    if (getters.maxPopReached) return
    try {
      const data = await this.$apiClient.getHymns({
        page: state.popularPage,
        ...options,
        favoriteOnly: true,
      })

      commit('UPDATE_POP_HYMN_LIST', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchAlphaHymnList({ commit, getters }): Promise<void> {
    if (getters.maxAlphaReached) return
    try {
      const data = await this.$apiClient.getHymns({
        excludePsalter: true,
      })
      commit('UPDATE_ALPHA_HYMN_LIST', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchPsalterList(
    { commit, getters, state },
    options: Partial<HymnOptions> = {}
  ) {
    if (getters.maxPsalterReached) return
    try {
      const data = await this.$apiClient.getHymns({
        page: state.psalterPage,
        ...options,
        psalterOnly: true,
      })
      commit('UPDATE_PSALTER_HYMN_LIST', data)
    } catch (e) {
      logError(e)
    }
  },
}
