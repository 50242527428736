
import Vue from 'vue'
import Hls from 'hls.js'
import { qsBool } from '~/assets/ts/utils/params'
import { openPopoutWindow } from '~/assets/ts/utils/misc'
import PlayerMuteIcon from '~/components/player/MuteIcon.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import Poller from '~/components/_general/Poller.vue'

export const VideoPreviewProps = {
  videoStreamUrl: {
    type: String,
    required: true,
  },
  popoutUrl: {
    type: String,
    default: '',
  },
  poster: {
    type: String,
    required: true,
  },
  delay: {
    type: Boolean,
    default: true,
  },
  delayMs: {
    type: Number,
    default: 5,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  controls: {
    type: Boolean,
    default: true,
  },
  volumeOverride: {
    type: Number,
    default: 0,
    validator(value: number) {
      return value >= 0 && value <= 100
    },
  },
}

export default Vue.extend({
  name: 'VideoPreview',
  components: { Poller, SaIcon, PlayerMuteIcon },
  props: VideoPreviewProps,
  data() {
    return {
      hoverTime: 0,
      now: 0,
      hls: undefined as Hls | undefined,
      muted: true,
    }
  },
  computed: {
    volume(): number {
      if (this.volumeOverride > 0) {
        return this.volumeOverride
      }
      return this.$store.getters['player/volume']
    },
    autoplay(): boolean {
      return !this.delay
    },
    debug(): boolean {
      return qsBool(this, 'debug')
    },
    show(): boolean {
      return this.autoplay || this.hoverDuration >= this.delayMs
    },
    hoverDuration(): number {
      if (this.hoverTime === 0) return 0
      return this.now - this.hoverTime
    },
    isHovering(): boolean {
      return this.hoverTime !== 0
    },
    videoMedia(): HTMLVideoElement {
      return this.$refs.video as HTMLVideoElement
    },
    nativeHls(): boolean {
      if (!this.$isClient) return false
      if (Hls.isSupported()) return false
      if (!this.videoMedia) return false
      return !!this.videoMedia.canPlayType('application/vnd.apple.mpegurl')
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.setupHls()
      } else {
        this.hls?.destroy()
      }
    },
    volume() {
      this.updateVolume()
    },
  },
  mounted() {
    if (!this.autoplay) return
    this.setupHls()
  },
  destroyed() {
    this.hls?.destroy()
  },
  methods: {
    openPopoutWindow,
    updateVolume() {
      this.videoMedia.volume = this.volume / 100
    },
    setupHls() {
      if (this.nativeHls) {
        this.videoMedia.src = this.videoStreamUrl
        if (this.debug) {
          console.debug('Video Preview using Native Player')
        }
        return
      }
      this.hls = new Hls({
        debug: this.debug,
      })
      this.hls.loadSource(`${this.videoStreamUrl}?stats=false`)
      this.hls.attachMedia(this.videoMedia)
      this.updateVolume()
    },
    updateNow() {
      this.now = new Date().getTime()
    },
    hover(isHovering: boolean) {
      if (!this.delay) return
      this.updateNow()
      this.hoverTime = isHovering ? this.now : 0
    },
  },
})
