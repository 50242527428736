import { SocialAccountApi } from '~/apiclient/apibroadcasters'
import { SaIconsFolder, SaIconsType } from '~/assets/ts/types/icons'

export interface SaIconInfo {
  folder: string
  name: string
  colored: boolean
}

export function GetIconClass(icon: SaIconsType) {
  const info = GetIconInfo(icon)
  return `${info.name} i-${info.name}-${info.folder}${
    info.colored ? ' colored' : ''
  }`
}

export function GetIconInfo(icon: SaIconsType): SaIconInfo {
  const folder = SaIconsFolder.find((f) => icon.endsWith(`-${f}`)) ?? 'light'
  const name = icon.replace(`-${folder}`, '')
  return {
    folder,
    name,
    colored: folder === 'colored',
  }
}

export interface SocialLinkInfo {
  icon: SaIconsType
  /** string that represents a tailwind text color class */
  textColor: string
}

export function GetSocialLinkInfo(account: SocialAccountApi): SocialLinkInfo {
  let icon = 'link' as SaIconsType
  let textColor = ''
  switch (account.label) {
    case 'Facebook':
      icon = 'facebook-brands'
      textColor = 'text-brand-facebook'
      break
    case 'X':
      icon = 'x-twitter-brands'
      textColor = 'text-brand-x'
      break
    // case 'Twitter':
    //   icon = 'twitter-brands'
    //   textColor = 'text-brand-twitter'
    //   break
    case 'YouTube':
      icon = 'youtube-brands'
      textColor = 'text-brand-youtube'
      break
  }
  return {
    icon,
    textColor,
  }
}
