import { LatLngLiteral } from 'leaflet'
import { ApiClient } from '~/apiclient/apiclient'

/**
 * Uses the OpenStreetMap reverse geocode API "Nominatim" to retrieve a location
 * from a given Latitude and Longitude and returns a display string
 *
 * https://nominatim.org/release-docs/develop/api/Reverse/
 *
 * @param latLng
 * @param zoom
 */
export async function getLocation(
  this: ApiClient,
  latLng: LatLngLiteral,
  zoom = 10
): Promise<string> {
  const url = 'https://nominatim.openstreetmap.org/reverse'
  const config = {
    params: {
      lat: latLng.lat,
      lon: latLng.lng,
      format: 'jsonv2',
      zoom,
    },
  }
  return await this.$axios.get(url, config).then(({ data }) => {
    const name = data.name
    const state = data.address.state
    if (state && state !== name) return `${name}, ${state}`
    const country = data.address.country
    if (country && country !== name) return `${name}, ${country}`
    const display = data.display_name
    if (display && display.length <= 32) return display
    return name
  })
}
