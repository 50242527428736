import { ActionTree, GetterTree, MutationTree } from 'vuex'

export type FollowType = 'sermon' | 'broadcaster' | 'series' | 'speaker'
export interface FollowParams {
  type: FollowType
  id: string | number
}
export interface FollowedState extends FollowParams {
  followed: boolean
}

export const state = () => ({
  followed: [] as FollowedState[],
})

export type UserState = ReturnType<typeof state>

function isFollowed(
  state: UserState,
  params: FollowParams
): boolean | undefined {
  const match = state.followed.find(
    (f) => f.id.toString() === params.id.toString() && f.type === params.type
  )
  return match ? match.followed : undefined
}

function getFollowedIndex(state: UserState, params: FollowParams): number {
  return state.followed.findIndex(
    (f) => f.id.toString() === params.id.toString() && f.type === params.type
  )
}

export const mutations: MutationTree<UserState> = {
  SET_FOLLOWED: (state, followed: FollowedState) => {
    const index = getFollowedIndex(state, {
      id: followed.id,
      type: followed.type,
    })
    if (index === -1) {
      state.followed.push(followed)
    } else {
      state.followed[index].followed = followed.followed
    }
  },
}

export const getters: GetterTree<UserState, UserState> = {
  followed: (state) => (params: FollowParams) => isFollowed(state, params),
}

export const actions: ActionTree<UserState, UserState> = {
  async setFollowed({ commit }, params: FollowedState) {
    const followed = params.followed
    const id = params.id
    const type = params.type
    if (type === 'sermon') {
      if (followed) {
        await this.$apiClient.setFavoriteSermon(id as string)
      } else {
        await this.$apiClient.removeFavoriteSermon(id as string)
      }
    } else if (type === 'broadcaster') {
      if (followed) {
        await this.$apiClient.setFollowedBroadcaster(id as string)
      } else {
        await this.$apiClient.removeFollowedBroadcaster(id as string)
      }
    } else if (type === 'speaker') {
      if (followed) {
        await this.$apiClient.setFollowedSpeaker(id as number)
      } else {
        await this.$apiClient.removeFollowedSpeaker(id as number)
      }
    } else if (type === 'series') {
      if (followed) {
        await this.$apiClient.setFollowedSeries(id as number)
      } else {
        await this.$apiClient.removeFollowedSeries(id as number)
      }
    }
    commit('SET_FOLLOWED', params)
  },
  async getFollowed({ commit }, params: FollowParams) {
    const id = params.id
    const type = params.type
    let followed = false
    if (type === 'sermon') {
      followed = await this.$apiClient.isFavoriteSermon(id as string)
    } else if (type === 'broadcaster') {
      followed = await this.$apiClient.isFollowedBroadcaster(id as string)
    } else if (type === 'series') {
      followed = await this.$apiClient.isFollowedSeries(id as number)
    } else if (type === 'speaker') {
      followed = await this.$apiClient.isFollowedSpeaker(id as number)
    }
    commit('SET_FOLLOWED', { ...params, followed })
  },

  async getAllFollowed({ commit }, type: FollowType) {
    function setFollowed(id: string | number) {
      commit('SET_FOLLOWED', {
        id,
        type,
        followed: true,
      })
    }
    if (type === 'sermon') {
      const { results } = await this.$apiClient.getFavoriteSermons()
      results.forEach((s) => {
        setFollowed(s.sermonID)
      })
    } else if (type === 'broadcaster') {
      const { results } = await this.$apiClient.getFollowedBroadcasters()
      results.forEach((b) => {
        setFollowed(b.broadcasterID)
      })
    } else if (type === 'series') {
      const { results } = await this.$apiClient.getFollowedSeries()
      results.forEach((s) => {
        setFollowed(s.seriesID)
      })
    } else if (type === 'speaker') {
      const { results } = await this.$apiClient.getFollowedSpeakers()
      results.forEach((s) => {
        setFollowed(s.displayName)
      })
    }
  },
}
