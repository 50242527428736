export interface RewriteConfig {
  routeName: string // matches left-hand part of route name
  path: string // portion of path to be removed from route path
}

export type RewriteKey = 'event' | 'solo' | 'vault' | 'psalter'

// to verify that a routeName matches or discover the route's name, use $nuxt.$route.name
export const RewriteTypes: Record<RewriteKey, RewriteConfig> = {
  event: { routeName: 'events-id', path: '/events/:id' },
  solo: { routeName: 'solo-id', path: '/solo/:id' },
  vault: { routeName: 'vault', path: '/vault' },
  psalter: { routeName: 'rewrite-psalter', path: '/rewrite/psalter' },
}
