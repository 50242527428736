var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.type,_vm._b({tag:"component",class:!_vm.object
      ? [
          'loading-placeholder',
          _vm.loadingClasses,
          _vm.textHeight ? 'text-height' : '',
        ]
      : '',style:(!_vm.object ? _vm.loadingStyles : ''),attrs:{"to":_vm.to}},'component',_vm.componentProps,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }