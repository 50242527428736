import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { HashtagApi } from '~/apiclient/apihashtags'

export const state = () => ({
  hashtags: [] as HashtagApi[],
  trending: [] as HashtagApi[],
  recent: [] as HashtagApi[],
})

export type FiltersState = ReturnType<typeof state>

export const mutations: MutationTree<FiltersState> = {
  SET_HASHTAGS: (state, data: HashtagApi[]) => {
    state.hashtags = data
  },
  SET_TRENDING: (state, data: HashtagApi[]) => {
    state.trending = data
  },
  SET_RECENT: (state, data: HashtagApi[]) => {
    state.recent = data
  },
}

export const getters: GetterTree<FiltersState, FiltersState> = {
  hashtags: (state) => state.hashtags,
  trending: (state) => state.trending,
  recent: (state) => state.recent,
}

export const actions: ActionTree<FiltersState, FiltersState> = {
  async getHashtags({ commit, state }) {
    if (state.hashtags.length) return
    const { results } = await this.$apiClient.getHashtags()
    commit('SET_HASHTAGS', results)
  },
  async getTrending({ commit, state }) {
    if (state.trending.length) return
    const { results } = await this.$apiClient.getHashtags({
      trending: true,
      size: 14,
    })
    commit('SET_TRENDING', results)
  },
  async getRecent({ commit, state }) {
    if (state.recent.length) return
    const { results } = await this.$apiClient.getHashtags({
      sortBy: 'recent',
      size: 14,
    })
    commit('SET_RECENT', results)
  },
}
