import Vue from 'vue'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

const eventBus = new Vue()

declare module 'vue/types/vue' {
  interface Vue {
    $eventBus: Vue
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $eventBus: Vue
  }
  interface Context {
    $eventBus: Vue
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $eventBus: Vue
  }
}

export default (_context: Context, inject: Inject) => {
  inject('eventBus', eventBus)
}
