import Vue from 'vue'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'

export const state = () => ({
  series: {} as any,
})

export type SeriesState = ReturnType<typeof state>

export const mutations: MutationTree<SeriesState> = {
  ADD_SERIES: (state, series: any) => {
    Vue.set(state.series, series.seriesID, series)
    Vue.set(state.series, series.title, series)
  },
}

export const getters: GetterTree<SeriesState, SeriesState> = {
  series: (state) => (seriesID: string) => state.series[seriesID],
}

export const actions: ActionTree<SeriesState, SeriesState> = {
  async fetchSeries({ commit }, { seriesID }) {
    try {
      const series = await this.$apiClient.getSeries(seriesID)
      commit('ADD_SERIES', series)
    } catch (e) {
      logError(e)
    }
  },
}
