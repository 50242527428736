import { Context } from '@nuxt/types'
import { customSiteParams } from '~/assets/ts/utils/params'

export default async function (context: Context) {
  const eventID = customSiteParams(context).id
  await context.store.dispatch('events/fetchEvent', eventID)

  const valid = !!context.store.getters['events/event'](eventID)
  if (!valid) {
    context.error({
      statusCode: 404,
      message: 'Event not found',
    })
  }
}
