import { TokenizerAndRendererExtension } from 'marked'
import { ParseMarkdownComment } from '~/assets/ts/markdown/sermonaudio'

interface ImageParams {
  href?: string | null
  title?: string | null
  text?: string | null
  style?: string
}

export function CustomImageRenderer(params: ImageParams): string {
  const { href, title, text, style } = params
  const styleTag = style ? `style="${style}"` : ''
  if (title) {
    return `<span class="captioned"><img src="${href}" alt="${text}" title="${title}"${styleTag}><em>${title}</em></span>`
  } else {
    return `<img src="${href}" alt="${text}"${styleTag}>`
  }
}

const MarkdownImageRegex = /!\[([^\]]*)\]\(([^"]*)(?:\s*"([^"]*)")?\)/
export const MarkdownImageTokenizer = {
  name: 'image',
  level: 'inline',
  tokenizer(src: string, _tokens) {
    const match = src.match(MarkdownImageRegex)
    if (!match) return false
    return {
      type: 'image',
      raw: src,
      text: match[1]?.trim(),
      href: match[2].trim(),
      title: match[3]?.trim(),
      style: ParseMarkdownComment(src).style,
    }
  },
  renderer(token: any) {
    return CustomImageRenderer({ ...token })
  },
} as TokenizerAndRendererExtension
