
import Vue from 'vue'

export default Vue.extend({
  name: 'LoadingElement',
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {}
      },
    },
    object: {
      type: [Object, Number, String, Array],
      default: undefined,
    },
    /**
     * This could either be a string representing a standard HTML element like
     * "a", "div", "button", OR it could be an imported component like SaLink.
     */
    type: {
      type: [Function, String],
      default: 'div',
    },
    loadingClasses: {
      type: String,
      default: '',
    },
    loadingStyles: {
      type: String,
      default: '',
    },
    textHeight: {
      type: Boolean,
      default: true,
    },
    to: {
      type: String,
      default: undefined,
    },
  },
})
