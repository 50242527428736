import { FixPlaintextEntities } from '~/assets/ts/markdown/renderer'

export type SaMarkdownPlayerType = 'video' | 'audio'
export type SaMarkdownType = SaMarkdownPlayerType | 'string'

export function ParseMarkdownComment(comment: string) {
  const regex = /<!--\s*(\w+)=["']([^"']*)["']\s*-->/g
  const properties = {} as Record<string, any>
  const match = regex.exec(FixPlaintextEntities(comment))
  if (match) {
    properties[match[1].trim()] = match[2].trim()
  }
  return properties
}

export interface SaMarkdown extends PartialRecord<string, any> {
  type: SaMarkdownType
  raw: string
  index: number
  sermon?: string
}

export const SaMarkdownRegex = /^<sermonaudio\s*([^>]*)>(.*?)<\/sermonaudio>/i
export function ParseSaMarkdown(html: string): SaMarkdown | undefined {
  const matchGroup = html.match(SaMarkdownRegex)
  if (!matchGroup) return undefined

  const attributesString = matchGroup[1]
  const attributesPattern = /(\w+)="([^"]*)"/g
  const attributes: { [key: string]: string } = {}

  let match
  while ((match = attributesPattern.exec(attributesString)) !== null) {
    attributes[match[1]] = match[2]
  }

  return {
    ...attributes,
    raw: matchGroup[0],
    index: matchGroup.index,
  } as SaMarkdown
}

export function CreateSaMarkdown(
  type: 'audio' | 'video',
  fields: Record<string, any> = {}
) {
  const str = Object.keys(fields)
    .map((key) => `${key}="${fields[key]}"`)
    .join(' ')
  return `<sermonaudio type="${type}" ${str}></sermonaudio>`
}
