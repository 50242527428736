function unavailable() {
  return typeof window === 'undefined'
}

export function DeleteLocalStorage(key: string) {
  if (unavailable()) {
    console.error(`[${key}] Local Storage cannot be deleted at this time.`)
    return
  }
  window.localStorage.removeItem(key)
}

export function SetLocalStorage<T>(key: string, value: T) {
  if (unavailable()) {
    console.error(`[${key}] Local Storage cannot be set at this time.`)
  } else {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
  return value
}

export function GetLocalStorage<T>(key: string, defaultValue: T): T {
  if (unavailable()) return defaultValue
  const value = window.localStorage.getItem(key)
  return value !== null ? JSON.parse(value) : defaultValue
}
