import { GetterTree, MutationTree } from 'vuex'
import { apiSignupInfo } from '~/apiclient/apibroadcastersignup'
import { apiCouponInfo } from '~/apiclient/apicoupons'

export const state = () => ({
  signupInfo: {} as apiSignupInfo,
  couponInfo: {} as apiCouponInfo,
})

export type BroadcastersSignupState = ReturnType<typeof state>

export const mutations: MutationTree<BroadcastersSignupState> = {
  ADD_BROADCASTER_SIGNUP_INFO: (state, signupInfo: apiSignupInfo) => {
    state.signupInfo = signupInfo
  },
  ADD_SIGNUP_COUPON_INFO: (state, couponInfo: apiCouponInfo) => {
    state.couponInfo = couponInfo
  },
}

export const getters: GetterTree<
  BroadcastersSignupState,
  BroadcastersSignupState
> = {
  signupInfo: (state) => state.signupInfo,
  couponInfo: (state) => state.couponInfo,
}
