import { Context } from '@nuxt/types'

export default async function ({ params, store, error }: Context) {
  const broadcasterID = params.broadcaster || params.broadcasterID
  const valid = !!broadcasterID
  if (broadcasterID) {
    await store.dispatch('webcasts/fetchWebcastsInProgress', {
      broadcasterID,
    })
  }
  if (!valid) {
    error({
      statusCode: 404,
      message: 'Broadcaster not found',
    })
  }
}
