import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'
import { SpeakerApi } from '~/apiclient/apispeakers'

export const state = () => ({
  speakers: {} as Record<any, SpeakerApi>,
})

export type SpeakersState = ReturnType<typeof state>

export const mutations: MutationTree<SpeakersState> = {
  ADD_SPEAKER: (state, speaker: SpeakerApi) => {
    Vue.set(state.speakers, speaker.speakerID, speaker)
  },
}

export const getters: GetterTree<SpeakersState, SpeakersState> = {
  speaker: (state) => (speakerID: number) => state.speakers[speakerID],
  speakerByName: (state) => (speakerName: string) => {
    return Object.keys(state.speakers)
      .map((id) => state.speakers[id])
      .find((speaker) => speaker.displayName === speakerName)
  },
}

export const actions: ActionTree<SpeakersState, SpeakersState> = {
  async fetchSpeaker({ commit, state }, { speakerID }) {
    if (state.speakers[speakerID]) return
    try {
      const speaker = await this.$apiClient.getSpeaker(speakerID)
      commit('ADD_SPEAKER', speaker)
    } catch (e) {
      logError(e)
    }
  },
}
