
import Vue, { PropType } from 'vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'InlineIcon',
  components: { SaIcon },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    slotClasses: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
    smallGap: {
      type: Boolean,
    },
    flipSide: {
      type: Boolean,
    },
    hideIcon: {
      type: Boolean,
    },
  },
  computed: {
    spanClass(): string {
      if (this.hideIcon) return ''
      if (this.flipSide) {
        return this.smallGap ? 'mr-1' : 'mr-2'
      } else {
        return this.smallGap ? 'ml-1' : 'ml-2'
      }
    },
  },
})
