import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'

export interface AudioBibleSermon {
  chapter: number
  sermonID: string
}

export interface BibleBookApi {
  chapters: number
  displayTitle: string
  osisAbbrev: string
  blbAbbrev: string
  title: string
  verses: number[]
  audioBibleSermonIDs: AudioBibleSermon[]
}

export async function getBibleStructure(
  this: ApiClient
): Promise<ApiPaginationResult<BibleBookApi>> {
  const { data } = await this.$axios.get('node/discover/bible_structure')
  return data
}
