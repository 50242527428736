import { Context } from '@nuxt/types'
import Vue from 'vue'
import {
  ApiAccessCookie,
  ApiRefreshCookie,
  DeleteCookie,
  GetCookie,
  LinkedBroadcasterCookie,
  SetCookie,
  UseVueDashboardCookie,
  VueAccessCookie,
  VueRefreshCookie,
} from '~/assets/ts/utils/cookies'
import { StringByEnv } from '~/assets/ts/utils/environment'
import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { SpeakerApi, SpeakerIsGeneric } from '~/apiclient/apispeakers'
import { PromiseAll } from '~/assets/ts/utils/misc'
import { SermonCountsApi } from '~/apiclient/apibroadcasters'
import { clampArray } from '~/assets/ts/utils/lists'
import { modifyQs, qsValue } from '~/assets/ts/utils/params'
import { sendMessageToIframeParent } from '~/assets/ts/utils/iframe'
import { dashboardUrl } from '~/assets/ts/utils/urls'

export function DjangoHostUrl(): string {
  return StringByEnv(
    'http://localhost:3001',
    'https://embed.sermonaudio.com',
    'https://sermonaudio.com',
    'https://embed.sermonaudio.com' // 'https://django-rename-dashboard.review.sermonaudio.com'
  )
}

export function DjangoUrl(path = '/') {
  return `${DjangoHostUrl()}${path}`
}

export function DashboardQs(context: Context | Record<string, any>) {
  return qsValue(context, 'dashboard')
}

export function AddDashboardQs(
  context: Context | Record<string, any>,
  path: string,
  qs: 'next' | 'dashboard'
) {
  const value = DashboardQs(context)
  if (!value) return path
  return `${path}?${qs}=${value}`
}

export function SetUseVueDashboard(use: boolean) {
  if (use) {
    SetCookie(UseVueDashboardCookie, '1')
  } else {
    DeleteCookie(UseVueDashboardCookie)
  }
}

export function GetUseVueDashboard() {
  return !!GetCookie(UseVueDashboardCookie)
}

export function SelectBroadcaster(
  id: string,
  path = undefined as string | undefined,
  vue?: Vue
) {
  // Set the broadcaster ID cookie so django knows which one to use
  SetCookie(LinkedBroadcasterCookie, id)

  // Set global token cookies equal to the value of the vue tokens
  // to deal with potential inconsistencies with vue branch logins
  SetCookie(ApiAccessCookie, GetCookie(VueAccessCookie))
  SetCookie(ApiRefreshCookie, GetCookie(VueRefreshCookie))

  if (path === undefined) return
  const useVue = GetUseVueDashboard()
  path = dashboardUrl(path, !useVue)
  if (vue && useVue) {
    vue.$navigateTo(path)
  } else {
    location.href = DjangoUrl(path)
  }
}

export interface SpeakersForBroadcasters {
  /** Speakers sorted by recency */
  recent: SpeakerApi[]
  /** Speakers sorted by sermon count that are not in recent list */
  frequent: SpeakerApi[]
}

export async function SpeakersForBroadcaster(
  apiClient: ApiClient,
  broadcasterID: string,
  listsLength = 5
): Promise<SpeakersForBroadcasters> {
  // get counts and speakers
  const [speakers, counts]: [
    ApiPaginationResult<SpeakerApi>,
    ApiPaginationResult<SermonCountsApi>,
  ] = await PromiseAll([
    apiClient.speakersForBroadcasters(broadcasterID),
    apiClient.speakerSermonCountsForBroadcaster(broadcasterID),
  ])

  // remove generic and update counts
  const updatedSpeakers = [] as SpeakerApi[]
  speakers.results
    .filter((s: SpeakerApi) => !SpeakerIsGeneric(s))
    .forEach((speaker: SpeakerApi) => {
      const speakerCounts = counts.results.find(
        (c: SermonCountsApi) => c.speaker === speaker.displayName
      ) as SermonCountsApi | undefined
      if (speakerCounts) {
        speaker.sermonCount = speakerCounts.count
      }
      updatedSpeakers.push(speaker)
    })

  // split into lists
  let recent = [...updatedSpeakers].sort((a, b) => {
    const lastA = a.mostRecentPreachDate as string
    const lastB = b.mostRecentPreachDate as string
    return new Date(lastA).getTime() > new Date(lastB).getTime() ? -1 : 1
  })
  let frequent = [...updatedSpeakers].sort((a, b) =>
    a.sermonCount > b.sermonCount ? -1 : 1
  )

  // clamp to max listLength
  recent = clampArray(recent, listsLength)
  frequent = clampArray(
    frequent.filter((s) => !recent.includes(s)),
    listsLength
  )
  return {
    frequent,
    recent,
  }
}

export async function navigateToDashboardPage(
  context: Vue,
  url: string,
  django: boolean
) {
  url = dashboardUrl(url, django)
  if (django) {
    sendMessageToIframeParent('navigate', url)
  } else {
    await context.$navigateTo({
      path: url,
    })
  }
}

/** Update current route query string with the specified value(s) */
export function modifyDashboardQs(
  context: Vue,
  qs: Record<string, string | undefined>
) {
  const str = modifyQs(context, qs)
  sendMessageToIframeParent('qs', str)
  return str
}
