
import Vue, { PropType } from 'vue'
import { Broadcaster, BroadcasterImageAspectRatio } from '~/models/broadcaster'
import BackgroundImage from '~/components/_general/BackgroundImage.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import { siteBroadcasterUrl } from '~/assets/ts/utils/urls'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'BroadcasterImage',
  components: { LoadingElement, BackgroundImage },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    width: {
      type: Number,
      default: 64,
    },
    useLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      BroadcasterImageAspectRatio,
    }
  },
  computed: {
    SaLink() {
      return SaLink
    },
    imageUrl(): string {
      return (
        this.broadcaster?.imageResizable(this.width) ??
        this.broadcaster?.imageURL ??
        ''
      )
    },
    isLink(): boolean {
      return !!this.broadcaster && this.useLink
    },
    broadcasterUrl(): string {
      return siteBroadcasterUrl(this.broadcaster)
    },
  },
})
