var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"rounded-full flex items-center justify-center overflow-hidden shrink-0",class:[
    _vm.fontSize,
    _vm.widthClass,
    _vm.user && !_vm.profilePicture
      ? `text-white`
      : _vm.forceDark
        ? 'bg-gray-600 text-gray-400'
        : 'bg-gray-300 dark:bg-gray-600 text-gray-400',
  ],style:(_vm.user && !_vm.profilePicture ? `background-color:  ${_vm.userColor}` : '')},[(_vm.profilePicture)?_c('SaImage',{attrs:{"src":_vm.profilePicture,"loading":"eager"}}):_c('span',{staticClass:"mt-full"}),_vm._v(" "),(!_vm.user)?_c('SaIcon',{staticClass:"mb-0.5",attrs:{"name":"user-solid"}}):_vm._e(),_vm._v(" "),(_vm.user && !_vm.profilePicture)?_c('span',{staticClass:"font-bold uppercase"},[_vm._v(_vm._s(_vm.userLetter))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }