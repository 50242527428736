import Vue from 'vue'
import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import {
  SermonApiEventType,
  SermonRequestOptions,
} from '~/apiclient/apisermons'
import { LanguageCode2 } from '~/assets/ts/utils/i18n'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'
import { secondsToRoundMinutes } from '~/assets/ts/utils/math'

export interface LanguageApi {
  languageCode: LanguageCode2
  languageCode3: string
  /**
   * Language name in English
   * @example Spanish
   *  */
  languageName: string
  /**
   * Language name in user's language
   * @example Spanish or Español
   * */
  localizedName: string
  /**
   * Language name in its own language
   * @example Español
   * */
  localizedNameInLanguage: string
  noCaptions: boolean
  popular: boolean
  sermonCount: number
  type: 'language'
}

export async function getLanguages(this: ApiClient): Promise<LanguageApi[]> {
  const { data } = await this.$axios.get('node/languages')
  const { results } = data as ApiPaginationResult<LanguageApi>
  return results
}

export interface EventTypeApi {
  eventID: number
  description: SermonApiEventType
  displayEventType: string
  numberOfSermons: number
  eventImage: string
  popular: false
  isServiceTimeOption: boolean
}

export async function getSermonEventTypes(
  this: ApiClient
): Promise<ApiPaginationResult<EventTypeApi>> {
  const { data } = await this.$axios.get(
    'node/filter_options/sermon_event_types'
  )
  return data
}

export interface BibleVersionsApi {
  code: string
  title: string
}

export async function getBibleVersions(
  this: ApiClient
): Promise<ApiPaginationResult<BibleVersionsApi>> {
  const { data } = await this.$axios.get('node/bible_versions')
  return data
}

export type SocialAccountLabel = 'Facebook' | 'Twitter' | string

export interface SocialAccountTypeApi {
  label: SocialAccountLabel
  urlPrefix: string
}

export async function getSocialAccountTypes(
  this: ApiClient
): Promise<ApiPaginationResult<SocialAccountTypeApi>> {
  const { data } = await this.$axios.get('node/social_account_types')
  return data
}

export interface GenericFilter {
  id: string
  label: string
  count: number
}

export interface BibleBookFilter {
  type: 'bible_book_filter_option'
  bookName: string
  displayBookName: string
  osisPA: string
  chapters: number[]
  count: number
  chapterCounts: number[][]
}

export interface SeriesFilter extends GenericFilter {
  bookName: string
  displayBookName: string
  broadcaster: BroadcasterApi
  image: string | null
  imageResizable: string | null
}

export interface DurationFilterSeconds {
  minSeconds: number
  maxSeconds: number | null
}

export interface DurationFilter extends GenericFilter, DurationFilterSeconds {}

export function DurationFilterTitle(vue: Vue, filter: DurationFilterSeconds) {
  const min = secondsToRoundMinutes(filter.minSeconds)
  if (!filter.maxSeconds) return vue.$t('Sermons over {n} minutes', { n: min })
  const max = secondsToRoundMinutes(filter.maxSeconds)
  if (!min) return vue.$t('Sermons under {n} minutes', { n: max })
  return vue.$t('Sermons {min}-{max} minutes', { min, max })
}

export function CreateDurationFilterValue(filter: DurationFilter) {
  if (!filter.maxSeconds) return filter.minSeconds.toString()
  return `${filter.minSeconds}-${filter.maxSeconds}`
}

export function ParseDurationFilter(value: string): DurationFilterSeconds {
  const [min, max] = value.split('-')
  return {
    minSeconds: parseInt(min),
    maxSeconds: max ? parseInt(max) : null,
  }
}

export function DurationFilterToSermonOptions(
  value: string
): SermonRequestOptions {
  const parsed = ParseDurationFilter(value)
  return {
    audioMinDurationSeconds: parsed.minSeconds,
    audioMaxDurationSeconds: parsed.maxSeconds || undefined,
  }
}

export interface MediaFilterRequires {
  audio: boolean | null
  video: boolean | null
  pdf: boolean | null
}

export function MediaFilterTitle(vue: Vue, filter: MediaFilterRequires) {
  if (filter.audio) return vue.$t('Audio')
  if (filter.video) return vue.$t('Video')
  return vue.$t('PDF')
}

export function ParseMediaFilter(value: string): MediaFilterRequires {
  return {
    audio: value === 'audio' ? true : null,
    video: value === 'video' ? true : null,
    pdf: value === 'pdf' ? true : null,
  }
}

export function MediaFilterToSermonOptions(
  value: string
): SermonRequestOptions {
  const parsed = ParseMediaFilter(value)
  return {
    requireAudio: parsed.audio || undefined,
    requireVideo: parsed.video || undefined,
    requirePDF: parsed.pdf || undefined,
  }
}

export interface FilterOptions {
  books: BibleBookFilter[]
  broadcasters: BroadcasterApi[]
  categories: GenericFilter[]
  denominations: GenericFilter[]
  durations: DurationFilter[]
  languages: LanguageApi[]
  series: SeriesFilter[]
  speakers: SpeakerApi[]
  years: GenericFilter[]
  mediaCounts: GenericFilter[]
}

export interface FilterOptionParams extends SermonRequestOptions {
  broadcasterNameFilter?: string
  speakerNameFilter?: string
  seriesNameFilter?: string
}

export async function getFilterOptions(
  this: ApiClient,
  params: FilterOptionParams
): Promise<FilterOptions> {
  const { data } = await this.$axios.get('node/filter_options', {
    params,
  })
  delete data.type
  return data
}
