import { ApiClient } from '~/apiclient/apiclient'

export enum CouponTypes {
  Sermon = 'sermon',
  Text = 'text',
  ReducedRate = 'billing-reduce',
  FreeSignup = 'free-signup',
  Signup = 'signup',
  SignupReferralSaleRep = 'signup-refer-rep', // not in active use
  SignupReferral = 'signup-referral',
  SignupReferralRepeat = 'signup-referral-repeat',
  SignupRepeat = 'signup-repeat',
  SignupRepeat3MonthsFree = 'signup-repeat-3m',
  SignupRepeat6MonthsFree = 'signup-repeat-6m',
  SignupRepeat1YearFree = 'signup-repeat-1y',
  SignupLowerMonthly1Y10USD = 'signup-repeat-10USD-1y',
  SignupLowerMonthly1Y1USD = 'signup-repeat-1USD-1y',
  EventFreeRegistration = 'event-reg-free',
}

export interface apiCouponInfo {
  couponID: string
  couponType: CouponTypes
  broadcasterID?: string
  sermonID?: string
  /** Timestamp */
  dateIssued: number
  /** Timestamp */
  expirationDate?: number
  /** Timestamp */
  dateUsed?: number
  eventID?: string
  monthlyFeeAdjustment?: number
  adjustmentDurationMonths?: number
  monthlyFeeReducedByAmount?: number
  referralLink?: string
  referringBroadcasterID?: string
  referredToName?: string
  referredToEmail?: string
  comments?: string
}

export async function getSignupCouponInfo(
  this: ApiClient,
  couponCode: string | undefined
): Promise<apiCouponInfo | undefined> {
  if (!couponCode) return undefined
  try {
    const { data } = await this.$axios.get(`signup_params/coupon/${couponCode}`)
    return data
  } catch (e) {
    return undefined
  }
}

export async function getEventCouponInfo(
  this: ApiClient,
  eventID: string,
  couponCode: string | undefined
): Promise<apiCouponInfo | undefined> {
  if (!couponCode) return undefined
  try {
    const { data } = await this.$axios.get(
      `node/events/${eventID}/coupon/${couponCode}`
    )
    return data
  } catch (e) {
    return undefined
  }
}
