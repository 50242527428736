import { render, staticRenderFns } from "./SaLinkOrHref.vue?vue&type=template&id=3359d301&scoped=true"
import script from "./SaLinkOrHref.vue?vue&type=script&lang=ts"
export * from "./SaLinkOrHref.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3359d301",
  null
  
)

export default component.exports