import { Context } from '@nuxt/types'
import {
  AddDashboardQs,
  DashboardQs,
  DjangoUrl,
} from '~/assets/ts/utils/dashboard'
import {
  broadcasterSelectUrl,
  broadcasterSignInUrl,
} from '~/assets/ts/utils/urls'

export enum BroadcasterSignIn {
  Unknown,
  Dashboard,
  Picker,
  LegacySignIn,
}

export interface BroadcasterSignInInfo {
  url: string
  external?: boolean
  broadcasterID?: string
  signIn: BroadcasterSignIn
}

export function GetBroadcasterSignIn(context: Context): BroadcasterSignInInfo {
  const user = context.$users.user
  if (!user) {
    return {
      url: AddDashboardQs(context, broadcasterSignInUrl, 'dashboard'),
      signIn: BroadcasterSignIn.Unknown,
    }
  }

  const admin = user.admin
  const roles = user.broadcasterRoles ?? []
  if (!admin && !roles.length) {
    return {
      url: AddDashboardQs(
        context,
        DjangoUrl('/dashboard/legacy-sign-in'),
        'next'
      ),
      external: true,
      signIn: BroadcasterSignIn.LegacySignIn,
    }
  }

  if (admin || roles.length > 1) {
    return {
      url: AddDashboardQs(context, broadcasterSelectUrl(), 'dashboard'),
      signIn: BroadcasterSignIn.Picker,
    }
  }

  return {
    url: DjangoUrl(DashboardQs(context) || '/dashboard'),
    external: true,
    signIn: BroadcasterSignIn.Dashboard,
    broadcasterID: user.broadcasterID,
  }
}
