
import Vue from 'vue'

export default Vue.extend({
  name: 'CustomScroll',
  props: {
    autoHide: {
      type: Boolean,
    },
    hide: {
      type: Boolean,
    },
    hideTrack: {
      type: Boolean,
    },
    thin: {
      type: Boolean,
      default: true,
    },
    useTheme: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    appearance(): string {
      if (this.hide) return 'scrollbar-hidden'
      if (this.thin) return 'scrollbar-thin'
      return 'scrollbar-regular'
    },
    colors(): string {
      let hide = ''
      if (this.autoHide && !this.$device.isMobileOrTablet) {
        hide =
          'not-hover:!scrollbar-thumb-transparent not-hover:!scrollbar-track-transparent'
      }
      if (!this.useTheme) return hide
      const thumbs = `scrollbar-thumb-gray-400 dark:scrollbar-thumb-gray-600 ${hide}`
      if (this.hideTrack) return thumbs
      return `scrollbar-track-gray-300 dark:scrollbar-track-gray-800 ${thumbs}`
    },
  },
})
